<template>
  <div class="about" id="quotaReviewList">
    <div style="text-align: left">
      <el-form :inline="true" :model="queryForm" class="demo-form-inline">
        <el-form-item label="员工姓名：">
          <el-input v-model="queryForm.name" placeholder="请输入公司名称查询" :clearable="true"></el-input>
        </el-form-item>
        <el-form-item label="员工身份证：">
          <el-input v-model="queryForm.idno" placeholder="请输入子账号查询" :clearable="true"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onQuery" icon="el-icon-search">查询</el-button>
          <el-button type="primary" @click="onReset" icon="el-icon-refresh-right">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- <div style="text-align: left;margin-bottom: 20px;">
      <el-button type="primary" icon="el-icon-download" @click="exportTable">导出</el-button>
    </div> -->
    
    <div>
        <el-table
        class="tabless"
        :data="tableData"
        :header-cell-style="tableHeader"
        :cell-style="tableCell"
        border
        fit
        >
        <el-table-column  type="index"  label="序号" />
        <el-table-column align="center" prop="name"  label="姓名" /> 
        <el-table-column prop="idno" label="身份证件号码" />
        <el-table-column prop="identificationNumber" label="纳税人识别号" />
        <el-table-column prop="flag" label="是否为非居民个人" />
        <el-table-column prop="incomeItem" label="所得项目" />
        <el-table-column label="本月（次）情况">
          <el-table-column label="收入额计算">
            <el-table-column prop="income" label="收入" />
            <el-table-column prop="cost" label="费用" />
            <el-table-column prop="exemptIncome" label="免税收入" />
          </el-table-column>
          <el-table-column prop="deductionExpense" label="减除费用" />
           <el-table-column label="专项扣除">
            <el-table-column prop="basicPensionPremium" label="基本养老保险费" />
            <el-table-column prop="basicMedicalPremium" label="基本医疗保险费" />
            <el-table-column prop="unemploymentPremium" label="失业保险费" />
            <el-table-column prop="housingFund" label="住房公积金" />
          </el-table-column>
          <el-table-column label="其他扣除">
            <el-table-column prop="annuity" label="年金" />
            <el-table-column prop="commercialHealthInsurance" label="商业健康保险" />
            <el-table-column prop="taxEndowmentInsurance" label="税延养老保险" />
            <el-table-column prop="originalValue" label="财产原值" />
            <el-table-column prop="taxAllowedDeducted" label="允许扣除的税费" />
            <el-table-column prop="other" label="其他" />
          </el-table-column>
        </el-table-column>
        <el-table-column label="累计情况">
          <el-table-column prop="accumulatedIncome" label="累计收入额" />
          <el-table-column prop="accumulatedDeductionExpense" label="累计减除费用" />
          <el-table-column prop="accumulatedSpecialDeduction" label="累计专项扣除" />
          <el-table-column label="累计专项附加扣除">
            <el-table-column prop="childrenEducation" label="子女教育" />
            <el-table-column prop="supportingElderly" label="赡养老人" />
            <el-table-column prop="housingLoanInterest" label="住房贷款利息" />
            <el-table-column prop="housingRent" label="住房租金" />
            <el-table-column prop="continuingEducation" label="继续教育" />
            <el-table-column prop="infantsUnderThree" label="3岁以下婴幼儿照护" />
          </el-table-column>
          <el-table-column prop="privatePension" label="累计个人养老金" />
          <el-table-column prop="accumulatedOtherDeduction" label="累计其他扣除" />
        </el-table-column>
        <el-table-column prop="lessTaxRate" label="减按计税比例" />
        <el-table-column prop="donationsAllowedDeducted" label="准予扣除的捐赠额" />
          <el-table-column label="税款计算">
            <el-table-column prop="taxableIncome" label="应纳税所得额" />
            <el-table-column prop="taxRate" label="税率/预扣率" />
            <el-table-column prop="quickDeductionRate" label="速算扣除数" />
            <el-table-column prop="taxPayable" label="应纳税额" />
            <el-table-column prop="taxSaving" label="减免税额" />
            <el-table-column prop="taxPaid" label="已缴税额" />
            <el-table-column prop="refundAmount" label="应补/退税额" />
          </el-table-column>
        <el-table-column prop="remarks" label="备注" />
        <el-table-column prop="createTime" label="创建时间" />
        
      </el-table>
      <el-pagination
      
      id="page"
        background
        @size-change="sizeChange"
        @current-change="currentChange"
        @prev-click="currentChange"
        @next-click="currentChange"
        :page-sizes="[5, 10, 20]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    
  </div>
</template>

<script>

export default {
    data() {
      return {
        summaryStateList: null,
        tableLoading: true,
        loadDate:'',
        tableData: [],
        logTableData: null,
        input: '',
        queryForm: {
          id: '',
          idno: '',
          name: '',
          account:'',
          pageNo: 1,
          pageSize: 10,
          sessionId: this.$getCookie("sessionId"),
        },
        total:0,
        dialogFormVisible: false,
        updateForm: {
          id: '',
          newSalaryAmount: undefined,
          remark: '',
          sessionId: ''
        },
        rules: {
          
        }
      }
    },
    async created(){
      // 获取数组字典
     
      await this.loadInfo();
      // this.loadDate = this.$route.params.date;
      await this.loadTable();
    },
    methods: {
      // 加载当前表格头
      async loadInfo(){
        
      },
      // 加载表格
      async loadTable(){
        // .bind(this);
        let flag = this;
        await this.api.salaryApi.PayrollDeclarationList(this.queryForm).then((res) => {
				res = res.data
				console.log("----申报数据列表-----",res);
				// flag.loading = false;
				if(res.code == 200){
					flag.tableData = res.data.records;
          flag.tableData.forEach(item => {
            item.income = this.accDiv(item.income,100);
            item.cost = this.accDiv(item.cost,100);
            item.exemptIncome = this.accDiv(item.exemptIncome,100);
            item.deductionExpense = this.accDiv(item.deductionExpense,100);
            item.basicPensionPremium = this.accDiv(item.basicPensionPremium,100);
            item.basicMedicalPremium = this.accDiv(item.basicMedicalPremium,100);
            item.unemploymentPremium = this.accDiv(item.unemploymentPremium,100);
            item.housingFund = this.accDiv(item.housingFund,100);
            item.annuity = this.accDiv(item.annuity,100);
            item.commercialHealthInsurance = this.accDiv(item.commercialHealthInsurance,100);
            item.taxEndowmentInsurance = this.accDiv(item.taxEndowmentInsurance,100);
            item.originalValue = this.accDiv(item.originalValue,100);
            item.taxAllowedDeducted = this.accDiv(item.taxAllowedDeducted,100);
            item.other = this.accDiv(item.other,100);
            item.accumulatedIncome = this.accDiv(item.accumulatedIncome,100);
            item.accumulatedDeductionExpense = this.accDiv(item.accumulatedDeductionExpense,100);
            item.accumulatedSpecialDeduction = this.accDiv(item.accumulatedSpecialDeduction,100);
            item.childrenEducation = this.accDiv(item.childrenEducation,100);
            item.supportingElderly = this.accDiv(item.supportingElderly,100);
            item.housingLoanInterest = this.accDiv(item.housingLoanInterest,100);
            item.housingRent = this.accDiv(item.housingRent,100);
            item.continuingEducation = this.accDiv(item.continuingEducation,100);
            item.infantsUnderThree = this.accDiv(item.infantsUnderThree,100);
            item.privatePension = this.accDiv(item.privatePension,100);
            item.accumulatedOtherDeduction = this.accDiv(item.accumulatedOtherDeduction,100);
            item.lessTaxRate = this.accDiv(item.lessTaxRate,100);
            item.donationsAllowedDeducted = this.accDiv(item.donationsAllowedDeducted,100);
            item.taxableIncome = this.accDiv(item.taxableIncome,100);
            item.taxRate = this.accDiv(item.taxRate,1000000);
            item.quickDeductionRate = this.accDiv(item.quickDeductionRate,100);
            item.taxPayable = this.accDiv(item.taxPayable,100);
            item.taxSaving = this.accDiv(item.taxSaving,100);
            item.taxPaid = this.accDiv(item.taxPaid,100);
            item.refundAmount = this.accDiv(item.refundAmount,100);
          });
					flag.total = res.data.total
				}else{
            this.tableData =  null;
            this.total = 0;
            this.$message.error(res.msg);
          }
			})
    },

      // 条件查询
      onQuery() {
        this.loadTable();
      },
      
      // 重置表单
      resetForm(form){
        this.$refs[form].resetFields();
      },
      // 重置
      onReset(){
        this.queryForm.name='';
        this.queryForm.idno='';
      },
      //current-change
      currentChange(page){
        this.queryForm.pageNo = page;
        this.loadTable();
      },

      // size-change
      sizeChange(size){
        this.queryForm.pageSize = size;
        this.loadTable();
      },
      tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0;text-align: center;";
      },
      tableCell(column) {
        return "height:40px;background-color:#fafafa;color:#000000;padding:0;text-align: center;";
      },
      isEntity(val){
        if(val == null || val == undefined || val === ''){
            return true;
        }else{
            return false;
        }
      },
    }
}
</script>

<style scoped>

.tabless{
  text-align: center;
}

#page {
  float: right;
  margin-top: 15px;
}

#quotaReviewList {
  overflow: hidden;
  background-color: white;
  padding: 15px 20px 100px;
}

>>> .el-dialog__body {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgb(228, 228, 228, 1);
  padding-left: 0;
  padding-right: 0;
}

>>> .el-drawer.rtl {
  overflow: scroll;
}

.wageadjustment{
  margin: 0 50px;
}

</style>

