<template>
  <div id="addSysRoleList">
    <el-row type="flex" justify="center">
      <el-col :xs="12" :sm="16" :md="12">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="角色编码:" prop="code">
            <el-input v-model="ruleForm.code"></el-input>
          </el-form-item>
          <el-form-item label="角色名称:" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="所属部门:" prop="gname">
            <!-- <el-select v-model="ruleForm.gid">
            <el-option
              v-for="item in depss"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select> -->
            <el-cascader
              v-model="ruleForm.gids"
              :options="groupTree"
              :props="{ checkStrictly: true }"
              clearable
            ></el-cascader>
          </el-form-item>
          <el-form-item label="是否启用:" prop="status">
            <el-radio v-model="ruleForm.status" label="0">是</el-radio>
            <el-radio v-model="ruleForm.status" label="1">否</el-radio>
          </el-form-item>
          <el-form-item label="*角色描述:" prop="details">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="ruleForm.details"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>  
<script>
export default {
  created() {
    this.updateCode(this.total);
  },
  mounted() {
    this.getDep();
    this.getGroupTree();
  },
  watch: {
    total(val) {
      this.updateCode(val);
    },
  },
  props: ["addrole", "total"],
  data() {
    return {
      ruleForm: {
        id: "",
        name: "",
        code: "",
        status: "",
        details: "",
        timestamp: "",
        gids: [],
      },
      unitLength: 4,
      depss: [],
      depatments: [],
      groupTree: [],
      rules: {
        name: [
          { required: true, message: "角色名称不能为空", trigger: "blur" },
        ],
        code: [
          { required: true, message: "角色编码不能为空", trigger: "blur" },
        ],
        details: [
          { required: true, message: "角色描述不能为空", trigger: "blur" },
        ],
      },
    };
  },

  methods: {
    updateCode(val) {
      this.ruleForm.code = "JS";
      for (var i = (val + "").length; i < this.unitLength; i++) {
        this.ruleForm.code += "0";
      }
      this.ruleForm.code += val + 1;
    },

    //查询全部的部门树
    async getGroupTree() {
      await this.api.platformApi.getGroupTree().then((response) => {
        console.log(response);
        if (response.data.code == 200) {
          this.groupTree = response.data.data;
        } else {
          this.$message.error(response.data.msg);
        }
      });
    },

    async submitForm(formName) {
      await this.$refs[formName].validate((valid) => {
        if (valid) {
          var params = {
            id: this.ruleForm.id,
            name: this.ruleForm.name,
            code: this.ruleForm.code,
            status: this.ruleForm.status,
            timestamp: this.ruleForm.timestamp,
            details: this.ruleForm.details,
            gids: this.ruleForm.gids,
            sessionId: this.$getCookie("sessionId"),
          };
          this.addrole(params);
        } else {
          console.log("error submit!!");

          return false;
        }
      });
    },

    async addrole(params) {
      let response = await this.api.platformApi.CreateRole(params);
      if ((response.data.data = true)) {
        this.$message({
          message: "添加成功",
          type: "success",
        });
      } else {
        this.$message({
          message: "添加失败",
          type: "success",
        });
      }
      this.$emit("closeDialog");
    },

    async getDep() {
      let data = {};
      await this.api.platformApi
        .getGroup()
        .then((response) => {
          this.depss = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped>
#addSysRoleList .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
#addSysRoleList .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
#addSysRoleList .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  text-align: center;
}
#addSysRoleList .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
#addSysRoleList .el-select {
  width: 100%;
}
#addSysRoleList .el-cascader {
  width: 100%;
}
</style>