<template>
  <el-dialog
    :title="iframeTitle"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="handleClose"
    fullscreen
  >
    <iframe
      v-if="dialogVisible"
      :src="iframeUrl"
      frameborder="0"
      class="iframe-style"
    ></iframe>
  </el-dialog>
</template>

<script>
export default {
  props: {
    iframeTitle: {
      type: String,
      default: '',
    },
    iframeUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialogVisible: false,
    }
  },
  created() {
    window.addEventListener('message', this.messageFn)
  },
  beforeDestroy(){
    window.removeEventListener('message', this.messageFn)
  },
  methods: {
    messageFn(e) {
      if (e.origin === window.location.origin&&e.data==='CLOSE_IFRAME') {
        this.dialogVisible = false
      }
    },
    open() {
      this.dialogVisible = true
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done()
        })
        .catch((_) => {})
    },
  },
  watch:{
    dialogVisible(value){
      if(!value){
        this.$emit('iframeClose')
      }
    }
  }
}
</script>

<style scoped>
.iframe-style {
  width: 100%;
  height: calc(100vh - 60px);
}
>>> .el-dialog__body {
  padding: 0;
}
</style>

