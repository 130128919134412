<template>
  <div class="sysPermission">
    <el-row>
      <el-col :span="10" class="bdBox" id="per1">
        <p id="bd" style="text-align: left">
          <img src="@/static/system/u977.svg" />
          <span style="color: #565c5f">当前选择:</span>
          <span class="currentTarget">{{ currentSelection }}</span>
          <a v-if="!isHidden" class="cancelCheckout" @click="deselect"
            >取消选择
          </a>
        </p>
        <el-tree
          class="filter-tree"
          ref="tree"
          default-expand-all
          :data="data"
          show-checkbox
          check-strictly
          :expand-on-click-node="false"
          node-key="index"
          @current-change="info"
          @check="changeCheckedState"
          :props="defaultProps"
        >
        </el-tree>
      </el-col>
      <el-col :span="12" :offset="1">
        <el-tabs v-model="activeName">
          <el-tab-pane label="权限信息" :model="permissionlist" class="showBox">
            <el-row
              id="per"
              type="flex"
              justify="center"
              :class="{ hiddenBox: isHidden }"
            >
              <el-col :xs="24" :sm="18" :md="14">
                <el-form
                  :model="ruleForm"
                  ref="ruleForm"
                  label-width="100px"
                  class="demo-ruleForm"
                >
                  <el-form-item label="权限名称:" prop="name">
                    <el-input v-model="permissionlist.name" disabled></el-input>
                  </el-form-item>

                  <el-form-item label="权限模块:" prop="Module">
                    <el-input
                      v-model="permissionlist.Module"
                      disabled
                    ></el-input>
                  </el-form-item>

                  <el-form-item label="权限界面:" prop="Html">
                    <el-input v-model="permissionlist.Html" disabled></el-input>
                  </el-form-item>

                  <el-form-item label="权限操作:" prop="operation">
                    <el-input
                      v-model="permissionlist.operation"
                      disabled
                    ></el-input>
                  </el-form-item>

                  <el-form-item label="权限数据:" prop="pdata">
                    <el-input
                      v-model="permissionlist.pdata"
                      disabled
                    ></el-input>
                  </el-form-item>

                  <el-form-item label="是否记录日志:">
                    <el-radio v-model="flagtype" :label="1">是</el-radio>
                    <el-radio v-model="flagtype" :label="0">否</el-radio>
                  </el-form-item>

                  <el-form-item label="权限详述:" prop="details">
                    <el-input
                      type="textarea"
                      :rows="2"
                      v-model="permissionlist.details"
                      disabled
                    >
                    </el-input>
                  </el-form-item>

                  <el-form-item>
                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                    <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
                  </el-form-item>
                </el-form>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  ruleForm: {
    name: "",
    Module: "",
    Html: "",
    operation: "",
    pdata: "",
    flag: "",
    details: "",
  },
  mounted() {
    this.loadData();
  },

  data() {
    return {
      currentSelection: "",
      permissiondata: [],
      activeName: "",
      flagtype: 1,
      ruleForm: {},
      permissionlist: {
        name: "",
        Module: "",
        Html: "",
        operation: "",
        pdata: "",
        flag: "",
        details: "",
        parent: "",
        permissionUrl: "",
        permissionUrl1: "",
        permissionUrl2: "",
        permissionUrl3: "",
      },
      isHidden: true,
      data: [],

      defaultProps: {
        children: "childModule",
        label: "currentModule",
      },
    };
  },

  methods: {
    handleCheckChange(data, checked1, checked2) {
      console.log(data, checked1, checked2);
    },
    deselect() {
      this.currentSelection = "";
      this.isHidden = true;
      this.$refs.tree.setCheckedKeys([]);
    },
    changeCheckedState(data){
      let node = this.$refs.tree.getNode(data)
      this.info(data, node);
    },
    info(data, node) {
      if(data.childModule){
        return;
      }
      // console.log(this.$refs.tree.getCheckedKeys())
      this.$refs.tree.setCheckedKeys([data.index]);
      this.permissionlist.name = node.parent.data.currentModule;
      this.permissionlist.Module = node.parent.parent.data.currentModule;
      this.permissionlist.Html = node.parent.data.currentModule + ".html";
      this.permissionlist.operation = data.currentModule;
      this.permissionlist.details = data.currentModule;
      this.currentSelection = data.currentModule;
      this.isHidden = false;
    },

    loadTreeData(data, i) {
      for (var index in data) {
        i++;
        data[index].index = i;
        if (data[index].childModule) {
          data[index].disabled = true;
          i = this.loadTreeData(data[index].childModule, i);
        }
      }
      return i;
    },
    async loadData() {
      var me = this;
      var params = {
        sessionId: this.getCookie("sessionId"),
      };
      let response = await me.api.platformApi.PermissionList(params);
      console.log("--------------------",response);
      me.data = response.data.data;
      this.loadTreeData(me.data, 0);
    },

    async submitForm(formName) {
      var me = this;
      me.permissionUrl1 = me.permissionlist.Module + "/";
      me.permissionUrl2 = me.permissionlist.name + "/";
      me.permissionUrl3 = me.permissionlist.operation;
      if(me.permissionlist.operation == ''){
         this.$message({
          message: '请选择操作对象',
          type: 'warning'
        });
        return;
      }
      if (me.permissionlist.Module == null) {
        me.permissionUrl1 = "";
      }
      if (me.permissionlist.name == null) {
        me.permissionUrl2 = "";
      }
      if (me.permissionlist.operation == null) {
        me.permissionUrl3 = "";
      }
      me.permissionUrl =
        me.permissionUrl1 + me.permissionUrl2 + me.permissionUrl3;
      var params = {
        falg: me.flagtype,
        permission: me.permissionUrl,
      };
      await me.api.platformApi
        .Editflag(params)
        .then(function (response) {

          me.$message({
          message: '修改成功',
          type: 'success'
        });
        })
        .catch(function (error) {
          console.log(error);
          this.$message({
            message: "读取失败",
            type: "warning",
          });
        });
    },

    resetForm() {
      this.permissionlist.name = "";
      this.permissionlist.Module = "";
      this.permissionlist.Html = "";
      this.permissionlist.operation = "";
      this.permissionlist.details = "";
    },

    setCookie: function (cname, cvalue, exdays) {
      let d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + "; " + expires;
    },
    getCookie: function (cname) {
      let name = cname + "=";
      let ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") c = c.substring(1);
        if (c.indexOf(name) !== -1) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    clearCookie: function (cname) {
      this.setCookie(cname, "", -1);
    },
  },
};
</script>
<style scoped>
#sysPermission .bdBox {
  background-color: #ffffff;
  padding: 0 20px;
}

#bd {
  line-height: 40px;
  height: 40px;
  background-color: #e6f7ff;
  border-radius: 5px;
  border: 1px solid #1d90ff;
  font-size: 16px;
  margin: 20px 0 10px 0;
}

#bd > span {
  vertical-align: middle;
  margin-right: 20px;
}

#bd > img {
  vertical-align: middle;
  margin: 0 5px 0 10px;
}

#sysPermission .currentTarget {
  display: inline-block;
  width: 100px;
}

.cancelCheckout {
  /* margin-right: 200px; */
  color: #409eff;
}

#per {
  background-color: white;
  padding: 15px 0 100px;
}

#per1 {
  background-color: white;
  padding: 0 20px 100px;
}

#sysPermission .el-button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}

#sysPermission .hiddenBox {
  visibility: hidden;
}

#sysPermission .showBox {
  background-color: white;
}

/*  隐藏叶子 */
>>> .el-tree-node__content>.el-tree-node__expand-icon{
  display: none;
}
/*  隐藏禁用选项 */
>>> .is-disabled>.el-checkbox__input{
  display: none;
}
</style>
