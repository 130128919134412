<template>
  <div id="sysuserDetails">
    <div>
      <el-form label-width="150px">
        <div>
           <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
                <el-form-item label="户名:">
                  <el-input
                    disabled
                    v-model="name"
                  ></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"
              ><div class="main_class"></div></el-col
            >
          </el-row>
           <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
                <el-form-item label="电子账户:">
                  <el-input
                    disabled
                    v-model="bankNum"
                  ></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"
              ><div class="main_class"></div></el-col
            >
          </el-row>
           <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
                <el-form-item label="开户行:">
                  <el-input
                    disabled
                    v-model="branchName"
                  ></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"
              ><div class="main_class"></div></el-col
            >
          </el-row>
           <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
                <el-form-item label="可用余额:">
                  <el-input
                    disabled
                    v-model="useamt"
                  ></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"
              ><div class="main_class"></div></el-col
            >
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
                <el-form-item label="发票总金额:">
                  <el-input
                    disabled
                    v-model="this.paymentDia.amountWithTax"
                  ></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"
              ><div class="main_class">（币种：CNY；单位：元）</div></el-col
            >
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
                <el-form-item label="发票税率:">
                  <el-input
                    disabled
                    v-model="this.paymentDia.taxRate"
                  ></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"><div class="main_class"></div></el-col>
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
                <el-form-item label="发票不含税金额:">
                  <el-input
                    disabled
                    v-model="this.paymentDia.amountNotWithTax"
                  ></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"
              ><div class="main_class">（币种：CNY；单位：元）</div></el-col
            >
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
                <el-form-item label="发票授信额度:">
                  <el-input
                    disabled
                    v-model="this.paymentDia.creditAmount"
                  ></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"
              ><div class="main_class">（币种：CNY；单位：元）</div></el-col
            >
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
                <el-form-item label="发票已使用额度:">
                  <el-input
                    disabled
                    v-model="this.paymentDia.totalUseAmount"
                  ></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"
              ><div class="main_class">（币种：CNY；单位：元）</div></el-col
            >
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
                <el-form-item label="发票剩余额度:">
                  <el-input
                    disabled
                    v-model="this.paymentDia.availableCreditAmount"
                  ></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"
              ><div class="main_class">（币种：CNY；单位：元）</div></el-col
            >
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
                <el-form-item label="发票待支付金额:">
                  <el-input
                    disabled
                    v-model="this.paymentDia.amount"
                  ></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"
              ><div class="main_class">（币种：CNY；单位：元）</div></el-col
            >
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="8"
              ><div>
                <el-form-item label="本次支付金额:">
                  <el-input v-model="amount"></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="4" id="anniu">
              <el-button type="primary" @click="showInput()"
                >结清剩余余额</el-button
              ></el-col
            >
            <el-col :span="5"
              ><div class="main_class">（币种：CNY；单位：元）</div></el-col
            >
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
                <el-form-item label="支付通道:">
                  <el-select placeholder="请选择" v-model="channelType">
                    <el-option v-for="channelType in channelTypes"  :label="channelType.name" :value="channelType.datavalue" :key="channelType.datavaluex"/>
                  </el-select>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"/>
          </el-row>
           <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
                <el-form-item label="手机号:">
                  <el-input
                   
                    v-model="mobile"
                  ></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"
              ><div class="main_class"></div></el-col
            >
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :span="12"
              ><div>
                <el-form-item label="验证码:">
                  <el-input v-model="verificationCode"></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"
              ><div >
                <div class="main_buttom_class">
                  <div>
                    <el-button type="primary" @click="sendSms">{{
                      timeStr
                    }}</el-button>
                  </div>
                </div>
              </div></el-col
            >
          </el-row>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import platformConstant from '@/util/PlatformConstant.js'
export default {
  props: ["payment"],

  data() {
    return {
      paymentDia: {
        //发票含税金额
        amountWithTax: "",
        //发票税率
        taxRate: "",
        //发票不含税金额
        amountNotWithTax: "",
        //授信额度
        creditAmount: "",
        //发票已使用额度
        totalUseAmount: "",
        //发票剩余额度
        invoiceSurplusAmount: "",
        //发票未支付总额
        totalNotPaidAmount: "",
        //税额
        taxAmount: "",
        //本次支付金额=剩余额度-税额(已除100)
        amount: "",
        //本次支付金额=剩余额度-税额(未除100)
        amountA: "",
        //可用额度
        availableCreditAmount: "",
        //已支付金额
        paidAmount: "",
        proofId: "",
      },
      mobile: "15816121608",
      verificationCode: "",
      name: "",
      bankNum: "",
      amuontSMS: "",
      branchName: "",
      useamt: "",
      timeStr: "获取短信验证码",
      channelType:'',
      channelTypes:[],
      //验证码
      verificationCode: "",
      //金额
      amount: 0,
      show: false,
      show1: true,
      channelDiction:{
        code:"channel"
      },
    };
  },

  mounted: function () {
    this.findDictionariesEnableItemList(this.channelDiction).then(result=>this.channelTypes = result);
    this.paymentDia.amountWithTax = this.payment.amountWithTax / 100;
    this.paymentDia.taxRate = this.payment.taxRate / 10000 + "%";
    this.paymentDia.amountNotWithTax = this.payment.amountNotWithTax / 100;
    this.paymentDia.creditAmount = this.payment.creditAmount / 100;
    this.paymentDia.totalUseAmount = this.payment.totalUseAmount / 100;
    this.paymentDia.invoiceSurplusAmount =
      this.payment.invoiceSurplusAmount / 100;
    this.paymentDia.totalNotPaidAmount = this.payment.totalNotPaidAmount / 100;
    this.paymentDia.availableCreditAmount =
      this.payment.availableCreditAmount / 100;
    this.paymentDia.amount =
      (this.payment.amountWithTax - this.payment.paidAmount) / 100;
    this.paymentDia.amountA =
      this.payment.amountWithTax - this.payment.paidAmount;
    this.payment.paidAmount - this.payment.paidAmount;
    this.paymentDia.proofId = this.payment.proofId;
    //获取验证码参数
    this.findAccountBalance("", "");
    this.amuontSMS = this.amount / 100;
  },
  methods: {
    showInput() {
      this.amount = this.paymentDia.amount;
    },
    async findDictionariesEnableItemList(param){
      let response = await this.api.platformApi.findDictionariesEnableItemList(param);
      console.log("===数据字典返回结果", response);
      console.log("数据字典集合", response.data.data);
      return response.data.data;
    },
    async findAccountBalance() {
       var param = {
        subNo: platformConstant.yzw_subNo,
        cltNm: platformConstant.yzw_cltNm,
        channel: platformConstant.channel
      };
      console.log("查询可用余额参数", this.param);
      let response = await this.api.platformApi.findYzwWallet(param);
      console.log("可用金额返回结果", response.data);
      this.useamt = response.data.data.findAccountBalance.data.useamt / 100;
      this.name = response.data.data.result.name;
      this.bankNum = JSON.parse(response.data.data.result.walletComment).rel_acct_no;
      this.branchName = response.data.data.bankName;
      this.mobile = platformConstant.mobile;
    },
    //短信取消
    cancel() {
      this.messageBox = false;
    },
    //短信确认放款
    confirm() {
      this.verifySMS();
    },
    //验证短信
    async verifySMS() {
      var smsParam = {
        mobile: this.mobile,
        verificationCode: this.verificationCode,
      };
      let smsResponse = await this.api.platformApi.authSendSms(smsParam);
      console.log("验证验证码结果", smsResponse.data);
      if (smsResponse.data.code != 200) {
        this.$message.error("验证码错误");
        this.fullscreenLoading = false;
        this.verificationCodeFlag = false;
      } else {
        this.verificationCodeFlag = true;
        this.$message.success("验证码成功");
      }
    },
    //发送验证码
    async sendSms() {
      if (this.timeStr !== "获取短信验证码" && this.timeStr !== "重新获取") {
        return;
      }
      var params = {
        mobile: this.mobile,
        smsType: 1,
      };
      console.log("sendsms====", params);
      let response = await this.api.platformApi.sendSms(params);
      console.log("验证码结果:", response);
      if (response.data.code != 200) {
        this.$message.error("验证码发送失败");
        return;
      }
      this.$message.success("验证码已发送");
      this.send_code();
    },
    send_code: function () {
      this.show = true;
      var _this = this;
      var i = 60;
      var timer = setInterval(function () {
        i--;
        _this.timeStr = i + "秒";
        if (i == 0) {
          clearInterval(timer);
          _this.timeStr = "重新获取";
        }
      }, 1000);
    },
  },
};
</script>
<style scoped>
.main_buttom_class {
  padding-left: 10px;
}
.main_class {
  /* border: solid;
  margin: 0 auto; */
  padding-top: 5px;
}
#sysuserDetails .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
#sysuserDetails .avatar-uploader .el-upload:hover {
  border-color: #1d90ff;
}
#sysuserDetails .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
#sysuserDetails .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
#sysuserDetails .el-input > input {
  height: 36px;
  border-radius: 5px;
}
#sysuserDetails .el-select {
  width: 100%;
}

#anniu {
  border: 0;
}

#anniu button {
  margin-left: 15px;
  padding: 0px;
  width: 120px;
  height: 40px;
  border-radius: 5px;
}
</style>