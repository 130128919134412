<template>
  <div class="login-container">
    <el-form
      :model="ruleForm2"
      :rules="rules"
      status-icon
      ref="ruleForm2"
      label-position="left"
      label-width="0px"
      class="demo-ruleForm login-page"
    >
      <img
        class="login-logo"
        src="@/static/images/zhongcai-logo.png"
        alt="logo"
      />
      <h3 class="title">系统登录</h3>
      <el-form-item prop="account">
        <el-input
          prefix-icon="el-icon-user"
          class="login-input"
          type="text"
          v-model="ruleForm2.account"
          auto-complete="off"
          placeholder="请输入账号/邮箱/手机号/身份证"
        >
        </el-input>
      </el-form-item>
      <el-form-item prop="salted">
        <el-input
          prefix-icon="el-icon-lock"
          class="login-input"
          type="password"
          v-model="ruleForm2.salted"
          auto-complete="off"
          placeholder="密码"
        ></el-input>
      </el-form-item>
      <div class="tk">
        <el-checkbox v-model="checked"
          ><span class="he">我已阅读并同意</span></el-checkbox
        ><span class="detail" @click="dialogVisible = true"
          >平台用户隐私政策协议</span
        ><span class="he">和</span>
        <span class="detail" @click="dialogVisibles = true">平台注册协议</span>
      </div>
      <el-form-item>
        <el-button
          class="btn"
          type="primary"
          style="width: 100%"
          @click="submitForm('ruleForm2')"
          round
          :loading="loading"
          >登录</el-button
        >
      </el-form-item>
    </el-form>
    <div>
			<p class="zss">四川中才智慧信息技术服务有限公司</p>
		</div>
		<div>
			<a href="https://beian.miit.gov.cn/"  class="zs" target="_blank">蜀ICP备2022027672号-1</a>
		</div>

    <el-dialog
      title="平台用户隐私政策协议"
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
    >
      <ptDetail></ptDetail>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="平台注册协议"
      :visible.sync="dialogVisibles"
      :modal-append-to-body="false"
    >
      <zcDetails></zcDetails>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisibles = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ptDetail from './ptDetail'
import zcDetails from './zcDetails'

export default {
  name: 'login',
  data() {
    return {
      checked: false,
      checkeds: false,
      dialogVisible: false,
      dialogVisibles: false,
      url: '',
      loading: false,
      ruleForm2: {
        account: '',
        salted: '',
      },
      rules: {
        account: [
          {
            required: true,
            message: '请输入账号/邮箱/手机号/身份证',
            trigger: 'blur',
          },
        ],
        salted: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'change',
          },
        ],
      },
    }
  },
  created() {
    this.clearCookie('sessionId')
    this.clearCookie('name')

    var that = this
    document.onkeydown = function(e) {
      var key = window.event.keyCode
      if (key === 13) {
        that.submitForm('ruleForm2') // 触发事件
      }
    }
  },
  methods: {
    async submitForm(formName) {
      await this.$refs[formName].validate((valid) => {
        if (valid) {
          this.userloginApi()
        } else {
          return false
        }
      })
    },
    async userloginApi() {
      try {
        if (!this.checked) {
          this.$message.warning('请勾选服务条款')
          return
        }
				this.loading = true
        let response = await this.api.platformApi.userlogin(this.ruleForm2)

        console.log(response)
        var s = JSON.stringify(response.data.data)
        if (response.data.data[0] == 'false') {
          this.$message.error('登录失败请检查帐号密码!')
        } else if (response.data.data[0] == 'disable') {
          this.$message.error('对不起，该用户已被停用!')
        } else {
          window.localStorage.setItem('platform', response.data.data[2])

          this.setCookie('sessionId', response.data.data[0], 1)
          this.setCookie('name', response.data.data[1], 1)
          this.$message({
            message: '登录成功',
            type: 'success',
          })
          this.$router.replace({
            path: '/pages',
          })
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    // detail(){
    // 	this.$router.push({path: '/ptdetail',query: {}})
    // },
    // details(){
    // 	this.$router.push({path: '/zcdetails',query: {}})
    // },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    setCookie: function(cname, cvalue, exdays) {
      let d = new Date()
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
      let expires = 'expires=' + d.toUTCString()
      document.cookie = cname + '=' + cvalue + '; ' + expires
    },
    getCookie: function(cname) {
      let name = cname + '='
      let ca = document.cookie.split(';')
      // console.log("获取cookie,现在循环")
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        // console.log(c)
        while (c.charAt(0) === ' ') c = c.substring(1)
        if (c.indexOf(name) !== -1) {
          return c.substring(name.length, c.length)
        }
      }
      return ''
    },
    clearCookie: function(cname) {
      this.setCookie(cname, '', -1)
    },
  },
  components: {
    ptDetail,
    zcDetails,
  },
}
</script>

<style scoped>
.he {
  color: black;
}
.tk {
  text-align: left;
  font-size: 13px;
  color: #d7000f;
  padding: 10px 0px;
}
.tk >>> .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #d7000f;
  border-color: #d7000f;
}

.tk >>> .el-checkbox__inner:hover {
  border-color: #d7000f;
}
.el-button--primary:hover {
  background: #d7000f !important;
  border-color: #d7000f !important;
}
.el-button--primary:focus {
  background: #d7000f !important;
  border-color: #d7000f !important;
}
.login-page {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  margin: 180px auto;
  width: 400px;
  padding: 35px 35px 15px;
  background: #fff;
  border: 1px solid #eaeaea;
  /* margin-left: 20%; */
  box-shadow: 0 0 25px #cac6c6;
}

	.zs{
		position:absolute; 
		left:45%;
		top:95%;
		color:#fff;
		opacity: 0.8;
		text-decoration: none;
		text-align: center;
	}
	.zss{
		position:absolute; 
		top:90%;
		left:43.5%;
		color:#fff;
		opacity: 0.8;
		text-decoration: none;
		text-align: center;
	}

.login-input >>> .el-input__inner {
  border-radius: 20px;
}
.login-logo {
  width: 80px;
  line-height: 70px;
}
.btn {
  background-color: #d7000f;
  border: 0;
  font-size: 16px;
}
.btn.el-button.is-round {
  border-radius: 50px;
  padding: 14px 0;
}
.login-container {
  width: 100%;
  height: 100vh;
  position: relative;
  background-image: url('../../../static/images/login-bg.jpg');
  background-size: contain;
}

.login-page {
  -webkit-border-radius: 5px;
  position: absolute;
  border-radius: 5px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 370px;
  height: 380px;
  padding: 35px 55px 15px;
  background: #fff;
  outline: 10px solid rgba(255, 255, 255, 0.3);
}

label.el-checkbox.rememberme {
  margin: 0px 0px 15px;
  text-align: left;
}
</style>

