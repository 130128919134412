import Vue from 'vue'
import './plugins/axios'



import App from './App.vue'
import router from './router'
import './plugins/element.js'

import Astrict from './util/astrict.js'
import calcu from './util/calculateUtils.js'

import permissions from './plugins/permissions'
Vue.use(permissions)
Vue.use(Astrict)
Vue.use(calcu)

import platformConstant from './util/PlatformConstant.js'
Vue.prototype.platformConstant = platformConstant

import api from '@/service/api'
import i18n from './i18n'
Vue.prototype.api = api
//json请求头
Vue.prototype.$jsonHeaders = {
  headers: {
     'Content-Type':'application/json;charset=utf-8'
  }
}

Vue.prototype.$setCookie = function (cname, cvalue, exdays) {
  let d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + "; " + expires;
}
Vue.prototype.$getCookie = function (cname) {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  // console.log("获取cookie,现在循环")
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    // console.log(c)
    while (c.charAt(0) === " ") c = c.substring(1);
    if (c.indexOf(name) !== -1) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
Vue.prototype.$clearCookie =  function (cname) {
  this.setCookie(cname, "", -1);
}
Vue.prototype.doControl =  function (arr,key) {  
  let r = arr.indexOf(key);
  return r;
}


Vue.prototype.menuPermission = function (){
    let name = "贷款初审";
		let params = {
			id: this.$getCookie("sessionId"),
			menuName: name
		}
		let response =  this.api.platformApi.menuPermission(params);

		console.log(name + "页面功能列表start---")
		console.log(response.data.data)
		console.log(name + "页面功能列表end---")
		this.funArr = response.data.data
		console.log(this.funArr)
}

Vue.config.productionTip = false
Vue.directive('enterNumber2', {
  inserted: function (el) {
    el.addEventListener("keypress",function(e){
      e = e || window.event;
      let charcode = typeof e.charCode == 'number' ? e.charCode : e.keyCode;
      let re = /\d/;
      if(charcode == 46){
        if(el.value.includes('.')){
          e.preventDefault();
        }
        return;
      }else if(!re.test(String.fromCharCode(charcode)) && charcode > 9 && !e.ctrlKey){
        if(e.preventDefault){
          e.preventDefault();
        }else{
            e.returnValue = false;
        }
      }
    });
  }
});
new Vue({
  router,

  data:{
    permissionsButton: JSON.parse(localStorage.getItem('permissionsButton')) // 全局按钮权限
  },

  beforeCreate() {
    console.log(this.permissionsButton);
		Vue.prototype.$bus = this //安装全局对象
	},

  mounted(){
    window.addEventListener("beforeunload", () => {
      localStorage.setItem('permissionsButton',JSON.stringify(this.permissionsButton));
    });
  },

  i18n,
  render: h => h(App)
}).$mount('#app')
