<template>
	
	<div class="page">
		<view class="blank-column"></view>
		<view class="title">
			<p>平台用户隐私政策协议</p>
		</view>
		<p class="sd">四川中才智慧信息技术服务有限公司（以下简称“我司”），注册地址为四川省凉山彝族自治州西昌市周堡中路3号中国西昌数字经济产业园3栋第13层1302号。我司非常重视用户的隐私和个人信息保护，深知个人信息对您的重要性，我司承诺依法采取相应的安全保护措施来保护您的个人信息。</p>
		<p class="js">为此，我司特制定本《平台用户隐私政策协议》（以下简称“本政策”）帮助您更充分了解，在您使用我司本产品/服务的过程中，我们会如何收集、使用、共享、储存和保护您的个人信息以及您可以如何管理您的个人信息。希望您在使用我司的产品或服务前仔细阅读，并确认您已经充分理解本政策所写明的内容，并让您可以按照本政策的指引做出您认为适当的选择。对于涉及到您的权益及免除、限制我司责任等重要内容，这些条款已采用宇体加粗形式提示您特别注意。在阅读过程中如您对本政策内容有任何疑问、意见或建议，您可通过本政策提供的各种联系方式与我司取得联系。您使用或在我司更新本政策后继续使用我司产品或服务，即表示您同意本政策（含更新版本）内容，并且同意我司按照本政策收集、存储、使用和共享您的相关信息。如果您未满 18 周岁，请在法定监护人的陪同下阅读本政策，并特别注意未成年人使用条款。</p>
		<p class="xbt">本政策将帮助您了解以下内容：</p>
		<p class="nrbt">一、定义及适用范围</p>
		<p class="nrbt">二、我司如何收集您的个人信息</p>
		<p class="nrbt">三、我司如何使用Cookie技术</p>
		<p class="nrbt">四、我司如何存储和保护您的个人信息</p>
		<p class="nrbt">五、我司如何使用、共享、转让和公开披露您的个人信息</p>
		<p class="nrbt">六、您如何访问和管理自己的个人信息</p>
		<p class="nrbt">七、我司如何处理未成年人信息</p>
		<p class="nrbt">八、本政策的适用及更新</p>
		<p class="nrbt">九、如何联系我司</p>
		<p class="nrbt">十、我司向您发送的信息</p>
		<p class="fdbt">一、定义及适用范围</p>
			<p class="nrjs">1.<span class="color"> 中才智慧服务平台</span>：向<span class="color">平台</span>用户提供薪酬结算、委托代发等交易鉴证平台。</p>
			<p class="nrjs">2.产品及服务：<span class="color">中才智慧服务</span>平台为企业和其名下职业者提供薪酬结算、纳税规范、代发薪酬等一体化服务。</p>
			<p class="nrjs">3.企业用户：在<span class="color">中才智慧服务</span>平台注册的用户及服务商，其中用户包含依据中华人民共和国法律法规依法设立并有效存续的实际用工企业，以及具备民事行为能力及履行相关服务时所应具备专业能力的职业者，服务商包括在<span class="color">中才智慧服务</span>平台提供灵活用工相关服务的服务企业。</p>
			<p class="nrjs">4.个人信息：是指以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。包括姓名、出生日期、证件信息、个人生物识别信息、通信通讯联系方式、住址、通信记录和内容、账号密码、财产信息、行踪轨迹、征信信息、住宿信息、健康生理信息、交易信息、运动步数等。</p>
			<p class="nrjs">5. 个人敏感信息:指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。<span class="color">中才</span>产品及服务可能涉及的个人敏感信息包括身份证件号码、手机号码、个人生物识别信息、银行账号、财产信息、交易信息(我们将在本隐私权政策中对具体个人敏感信息以粗体进行显著标识)。</p>
			<p class="nrjs">6. 适用范围:<span class="color">中才智慧服务</span>平台所有的服务均适用本隐私政策。请您注意，本隐私政策不适用于以下情况:通过<span class="color">中才智慧服务</span>平台的服务而接入的第三方服务收集的信息;通过在<span class="color">中才智慧服务</span>平台服务中提供其他服务的第三方所收集的信息。</p>
		<p class="fdbt">二、我司如何收集您的个人信息</p>
			<p class="nrjs">1.信息如何收集</p>
			<p class="nrjs">在您使用平台软件及服务的过程中，我们将根据合法、正当、必要的原则收集您的信息。我们收集或请您提供的信息将用于:</p>
			<p class="nrjs">（1）保障产品的基础正常运行;</p>
			<p class="nrjs">（2）实现各项功能和服务;</p>
			<p class="nrjs">（3）优化、改善产品和服务；</p>
			<p class="nrjs">（4）保障产品、服务以及用户使用安全。</p>
			<p class="nrjs">2.我们直接收集与使用的个人信息</p>
			<p class="nrjs">我们会按照如下方式收集您在使用服务时主动提供的以及通过自动化手段收集的您在使用功能或接受服务过程中产生的信息:</p>
			<p class="nrjs">（1）保障平台和服务的正常运行</p>
			<p class="nrjs">当您使用平台及相关服务时，为了保障软件与服务的正常运行，我们会收集您的硬件型号、操作系统版本号、国际移动设备识别码(IMEI)、网络设备硬件地址(MA C)、IP地址、软件版本号、网络接入方式及类型、操作日志等信息。请您了解，这些信息是我们提供服务和保障产品正常运行所必须收集的基本信息。</p>
			<p class="nrjs">（2）注册、认证、登录平台和相关服务</p>
			<p class="nrjs">(1)当您注册、认证、登录平台及需要使用相关服务时，您可以通过手机号创建账号，我们将通过发送短信验证码来验证您的身份是否有效，并且您可完善相关的网络身份识别信息(如头像、昵称和密码)，收集这些信息是为了帮助您完成注册。您还可根据自身需求选择填写性别、生日、地区及介绍等信息完善您的个人信息。</p>
			<p class="nrjs">(2)您也可以使用第三方账号(如微信)登录进入平台，您此时将授权我们获取您在第三方平台注册的公开信息(手机号码、头像、昵称等)，并在您同意本协议后将您的第三方账号与您的平台账户绑定，使您可以通过第三方账号直接登录并使用本产品和相关服务。</p>
			<p class="nrjs">(3)在您使用需进行身份认证的功能或服务时，根据中华人民共和国相关法律法规，您可能需要提供您的真实身份信息(真实姓名、身份证号码、电话号码及面部照片等)以完成实名验证，实名认证环节由专业的第三方合同云平台提供服务。如您不提供这些信息，您将不能使用对应的功能，但你可以继续使用平台的其他服务。这些信息属于个人敏感信息，我们将采用行业内通行的方式及尽最大的商业努力来保护您个人敏感信息的安全。如果您不提供这些信息，您将可能无法获得相关服务，但不影响平台其他功能与服务的正常使用。</p>
			<p class="nrjs">3.保障产品、服务及用户使用安全</p>
			<p class="nrjs">为帮助我们更好地了解平台及相关服务的运行情况，以便确保运行与提供服务的安全，我们会记录网络日志信息，以及使用软件及相关服务的频率、崩溃数据、总体安装、使用情况、性能数据等信息。</p>
			<p class="nrjs">4.当您使用我司产品及服务时，为了维护服务的正常运行，优化我司的服务体验及保障您的账号安全，我司会收集以下基础信息，包括您的设备型号、操作系统、唯一设备标识符、登录1P地址、接入网络的方式、类型和状态、网络质量数据、设备加速器、操作日志和服务日志信息，这些信息是为您提供服务必须收集的基础信息，以保障您正常使用我司的服务。</p>
			<p class="nrjs">5.为向您提供更加准确、个性和便捷的服务，提升服务体验、改进服务 质量，或为防范风险，我司会收集您反馈意见建议或举报时提供的信息，收集您使用我司产品或服务的类别、方式、操作信息以及您在我司的用户信息，我司会对这些信息进行统计、分析，并会根据上述信息向您提供相应服务或产品。</p>
			<p class="nrjs">6.以下情形中，您可选择是否授权我司收集、使用您的个人信息：</p>
			<p class="nrjs">（1）摄像头，用手身份证识别、银行卡识别、识别二维码、人脸识别、人脸认证、拍照，在扫一扫等使用。拒绝授权后，上述功能将无法使用。我司将获取的人脸数据，加密后存储于系统后台数据库中，用于业务办理过程留存、辅助识别和核实客户身份。对于某些品牌或型号的手机终端自身的本地生物特征认证功能，如人脸识别功能，其信息由手机终端提供商进行处理，我司不留存您应用手机终端相关功能的信息。</p>
			<p class="nrjs">（2）麦克风，用于智能语音服务、声纹识别等用途，在智能语音服务、声纹登录等场景中使用。拒绝授权后，智能语音服务的语音录入、声纹登录等功能无法使用。我司采集的声纹数据，加密后存储于系统后台数据库中。</p>
			<p class="nrjs">（3）手机短信，在需要短信验证码的环节可能需要读取验证码短信内容协助您进行验证码填写。</p>
			<p class="nrjs">7.上述功能可能需要您在您的设备中向我司开启您的相机、摄像头、相册、麦克风、手机通讯录、手机短信、存储权限、消息通知、设备信息及应用列表的访问权限，以实现这些功能所涉及的信息的收集和使用。请您注意，您开启这些权限即代表您授权我司可以收集和使用这些信息来实现上述功能，如您取消了这些授权，则我司将不再继续收集和使用您的这些信息，也无法为您提供上述与这些授权所对应的功能。</p>
			<p class="nrjs">如在上述情形之外收集或使用你的信息，我司会事先征求您的同意，并向您充分告知信息收集和使用的目的、方式和范围。</p>
			<p class="nrjs">8.请您理解，我司向您提供的功能和服务是不断更新和发展的，如果某一功能或服务未在前述说明中且需要收集您的信息，我司会通过页面提示、交互流程、网站公告等方式另行向您说明信息收集的内容、范国和目的，并在收集信息前征得悠的同意。</p>
			<p class="nrjs">根据相关法律法规、监管要求，以下情形中，我司可能会收集、使用、共享、转让、公开披露您的相关个人信息而无需另行征求您的授权同意：</p>
			<p class="nrjs">(1)与我司履行法律法规、监管规定的义务相关的；</p>
			<p class="nrjs">(2)与国家安全、国防安全直接相关的；</p>
			<p class="nrjs">(3)与公共安全、公共卫生和重大公共利益直接相关的；</p>
			<p class="nrjs">(4)与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
			<p class="nrjs">(5)出于维护您或其他个人的生命、财产等重大合法权益但因情況紧迫无法事先征得您本人同意的；</p>
			<p class="nrjs">(6)所收集的个人信息是您自行向社会公众公开的；</p>
			<p class="nrjs">(7)从合法公开披露的信息中收集的个人信息。例如，合法的新闻报道和政府信息公开等渠道；</p>
			<p class="nrjs">(8)根据您要求签订和履行合同所必需的；</p>
			<p class="nrjs">(9)用于维护所提供的产品或服务的安全稳定运行所必需的。例如，发现、处置产品或服务的故障；</p>
			<p class="nrjs">(10)法律法规及监管规定的其他情形。</p>
		<p class="fdbt">三、我司如何使用Cookie技术</p>
			<p class="nrjs">中才智慧服务平台使用Cookie来帮助您实现您的个性化联机体验。Cookie是由网页服务器存放在您的硬盘中的文本文件。Cookie不能用来运行程序，指定给您的Cookie是唯一的，它只能由将Cookie发布给您的域中的Web服务器读取。Cookie的主要用途之一是提供一种节约时间的实用功能。例如，如果您在该网站注册，Cookie会帮助该网站在您后续访问时调用您的特定信息。这样可以简化记录您的个人信息的流程。当您返回到该网站时，该网站可以调出您以前所提供的信息，使您能够容易地使用你自定义的该网站功能。为确保网站正常运转，我司会在您的计算机或移动设备上存储名为 Cookie 的小数据文件。Cookie 通常包含标识符、站点名称以及一些号码和字符借助于 Cookie，网站能够存储您的偏好等数据。我司不会将 Cookie 用手本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。您可以清除计算机上保存的所有 Cookie,大部分网络浏览器都设有阻止Cookie 的功能。但如果您这么做，则需要在每一次访问我司的网站时更改用户设置。</p>
		<p class="fdbt">四、我司如何存储和保护您的个人信息</p>
			<p class="nrjs">1.存储</p>
			<p class="nrjs">（一）我司在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。</p>
			<p class="nrjs">（二）我司仅在法律、法规、监管要求的期限内，以及为实现本政策声明的目的所必须的时限内保留您的个人信息。例如，登录手机号码：当您需要使用我司提供服务时，我司可能需要一直保存您的登录手机号，以保证您正常使用该服务，当您注销我司提供的账户后，我司将删除相应的信息;</p>
			<p class="nrjs">2.保护</p>
			<p class="nrjs">（一）我司已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权的访问、公开披露、使用、修改、损坏或丢失。我司会采取一切合理可行的措施，保护您的个人信息。例如，我司会使用加密技术确保数据的保密性；我司会使用受信赖的保护机制防止数据遭到恶意攻击；我司会部署访问控制机制，确保只有授权人员才可访问个人信息以及我司会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</p>
			<p class="nrjs">（二）如我司提供的全部或部分服务停止提供，我司将通过公告等形式向您进行告知，同时停止相关产品或服务对您个人信息的收集等操作，保护您的个人信息安全。如果因为技术故障、网络攻击、自然灾害及事故、人为因素等各种原因造成全部或部分服务中断，我司将采取应急处置和恢复措施予以应对，尽快恢复服务。</p>
			<p class="nrjs">（三）我司会采取一切合理可行的措施，确保未收集无关的个人信息。</p>
			<p class="nrjs">（四）请您理解，由于技术水平局限以及可能存在的恶意攻击，有可能出现我司无法合理预见、防范、避免或控制的意外情况。互联网并非绝对安全的环境，请使用复杂密码，协助我司保证您的账号安全。</p>
			<p class="nrjs">（五）如发生个人信息安全事件，我司将按照法律法规的要求，及时将事件相关情况以邮件、信息、电话和推送通知等方式告知您，或采取合理、有效的方式发布公告。告知内容包括但不限于安全事件的内容和影响；已采取或将要采取的处置措施；您的自主防范和降低风险的建议；针对您提供的补救措施以及我司个人信息保护负责人和工作机构的联系方式。同时，我司还将依据法律法规或监管规定，上报个人信息安全事件处置情況。</p>
		
		<p class="fdbt">五、我司如何使用、共享、转让和公开披露悠的个人信息</p>
			<p class="nrjs">1.信息如何使用</p>
			<p class="nrjs">（一）根据本政策的约定向您提供我司的产品或服务，并为改进这些产品或服务时使用。</p>
			<p class="nrjs">（二）在我司向您提供服务期间，您授权我司持续收集和使用您的信息。在您注销服务时，我司将停止收集您相关的个人信息。</p>
			<p class="nrjs">（三）为提升您的产品或服务体验，或为防范风险，我司会对服务使用情况进行汇总、统计分析、加工，但这些信息不会包含您的任何身份识别信息。</p>
			<p class="nrjs">（四）为使您知悉、使用我司金融产品或服务的情况或使您进一步了解我司服务，我司会向您发送服务状态通知以及相关产品或服务的商业性信息。</p>
			<p class="nrjs">（五）我司会采取脱敏、去标识化等方式对您的信息进行综合统计、分析加工，以便为您提供更加准确、个性、流畅及便捷的服务，或帮助我司评估、改善或设计服务及运营活动等。</p>
			<p class="nrjs">（六）您授权同意的以及法律允许的其它用途。</p>
			<p class="nrjs">2.共享和转让</p>
			<p class="nrjs">（一）除非获得您的明确明示同意或授权，我司不会向其他任何公司、组织和个人共享或转让您的个人信息，但是经过加工无法识别特定个人且不能复原的除外。</p>
			<p class="nrjs">（二）如果为了向您提供服务而必须将您的信息共享至第三方，我司会评估该第三方收集信息的合法性、正当性、必要性，并会通过与第三方签订协议，同时要求他们按照我司的说明、本政策或其他任何相关的保密和安全措施来妥善处理、保护您的个人信息。我司将以公告、弹窗等形式向您告知共享个人信息的目的、数据接收方的类型以及可能产生的后果，并征得您的明示授权同意。涉及敏感信息的，我司还会告知敏感信息的类型、数据接收方的身份和数据安全能力，并征得您的明示授权同意。我司仅会出于合法、正当、必要、特定、明确的目的共享或转让您的个人信息或转让提供服务所必要的个人信息，并会严格约束合作伙伴将您的个人信息用于任何其他用途，但我司无法保证第三方一定会遵循我司的要求保护您的信息，我司对此不作任何承诺与保证，如其在使用您的信息过程中侵害了您的合法权益，我们将尽力协助您进行相应的维权处理。</p>
			<p class="nrjs">（三）请您理解，我司可能会根据法律法规规定或按政府主管部门的强制性要求，对外共享您的个人信息。</p>
			<p class="nrjs">（四）与我司分支机构及附属公司的共享：您的个人信息可能会在我司各分支机构及附属公司内部进行共享。我司会遵循必要性的原则共享您的个人信息，且这种共享受本政策所述明目的的约束。一旦改变对您个人信息的处理目的，将再次征求您的授权同意。</p>
			<p class="nrjs">（五）我司在提供服务或产品期间若发生合并、收购或破产清算时，如涉及到信息转让，我司将要求新的持有您信息的公司、组织继续受本政策的约束，否则我司将要求该公司重新向您征求授权同意。</p>
			<p class="nrjs">3.公开披露</p>
			<p class="nrjs">(一）我司不会公开披露您的个人信息，如确需披露，我司会告知您披露个人信息的目的和类型，在获得您明确同意后，按您授权情况披露您的个人信息；涉及敏感信息的还会告知敏感信息的内容，并事先征得您的明示同意。</p>
			<p class="nrjs">（二）请您理解，在法律法规、法律诉讼程序或政府主管部门或司法机关的强制性要求情况下，我司可能会公开披露您的个人信息。</p>
			<p class="nrjs">4.不得披露</p>
			<p class="nrjs">(一)任何情况下，我司不会公开披露管理员及登录人的个人生物识别信息等个人生理信息。</p>
			<p class="nrjs">(二)任何情况下，我司不会公开披露管理员及登录人的种族、民族、政治观点、宗教信仰等敏感数据。共享、公开披露等处理信息时事先征得授权同意的例外。</p>
			<p class="nrjs">5.以下情形中，共享、公开披露等处理您的信息无需事先征得您的授权同意:</p>
			<p class="nrjs">(一)为订立、履行个人作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需;</p>
			<p class="nrjs">(二)为履行法定职责或者法定义务所必需;</p>
			<p class="nrjs">(三)为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需;</p>
			<p class="nrjs">(四)为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息;</p>
			<p class="nrjs">(五)依照本法规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息;</p>
			<p class="nrjs">(六)法律、行政法规规定的其他情形。</p>
		<p class="fdbt">六、您如何访问和管理自己的个人信息</p>
			<p class="nrjs">按照中国相关的法律法规和监管规定，我司保障您对自己的个人信息行使以下权利：</p>
			<p class="nrjs">1.访问、更正及更新您的个人信息您有权通过我司门户网站、小程序等渠道访问及更正、更新你的个人信息，但法律法规另有规定的除外。您有责任及时更新您的个人信息。在您修改个人信息之前，我司会验证您的身份。</p>
			<p class="nrjs">2.在以下情形中，您可以向我司提出删除个人信息的请求：</p>
			<p class="nrjs">（一）如果我司处理个人信息的行为违反了法律法规或监管规定；</p>
			<p class="nrjs">（二）如果我司收集、使用您的个人信息，未征得您的同意;</p>
			<p class="nrjs">（三）如果我司处理个人信息的行为违反了与您的约定；</p>
			<p class="nrjs">（四）如果您不再使用我司的产品或服务或您注销了相关账号。</p>
			<p class="nrjs">如我司决定响应您的删除请求，我司还将同时通知从我司获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定或监管部门另有要求或这些实体获得您的独立授权。</p>
			<p class="nrjs">当您从我司的服务中删除信息后，我司可能不会立即在备份系统中删除相应的信息，但会在备份更新时删除这些信息，监管机构要求保留的信息除外。</p>
			<p class="nrjs">如果我司不再为您提供产品或服务，我司将会删除您的个人信息，法律、法规、监管机构要求保留的信息除外。</p>
			<p class="nrjs">3.响应您的上述请求</p>
			<p class="nrjs">如果您无法通过上述方式访问、更正或删除您的用户信息，或您需要访问、更正或删除您在使用我司服务或功能时所产生的其他用户信息，或您认为我司存在任何违反法律法规或与您关于用户信息的收集或使用的约定，您均可通过本政策中的联系方式与我司取得联系，我们将为您提供人工处理。为了保障安全，我司可能需要您提供书面请求，或以其他方式证明您的身份，我司将在收到您反馈并验证您的身份后的15个工作日内答复您的请求。对于您合理的请求，我司原则上不收取费用，但对多次重复、超出合理限度的请求，我司将视情况收取一定成本费用。对于非法、违规、无正当理由、可能无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我司可能会将予以拒绝。</p>
			<p class="nrjs">尽管有上述约定，但按照法律、法规要求，如涉及以下情形我司将可能无法响应您的请求：</p>
			<p class="nrjs">（一）与我司履行法律法规、监管规定的义务相关的；</p>
			<p class="nrjs">（二）与国家安全、国防安全相关的；</p>
			<p class="nrjs">（三）与公共安全、公共卫生、重大公共利益相关的；</p>
			<p class="nrjs">（四）与犯罪侦查、起诉、审判和判决执行等相关的；</p>
			<p class="nrjs">（五）有充分证据表明您存在主观恶意或滥用权利的；</p>
			<p class="nrjs">（六）响应您的请求将导致您或其他个人、组织的合法杈益受到严重损害的；</p>
			<p class="nrjs">（七）涉及我司商业秘密的；</p>
			<p class="nrjs">（八）法律、法规及监管要求规定的其他情形。</p>
		<p class="fdbt">七、我司如何处理未成年人信息</p>
			<p class="nrjs">我司期望父母或监护人指导未成年人使用我司服务。我司将根据国家相关法律法规的规定保护末成年人信息的保密性及安全性。</p>
			<p class="nrjs">如您为未成年人，请您的父母或监护人阅读本政策，并在征得您父母或监护人同意的前提下使用我司服务或向我司提供您的信息。对于经父母或监护人同意而收集您的信息的情况，我司只会在受到法律允许、父母或监护人明确同意或依法保护您权益所必要的情况下使用或公开披露此信息。如您的监护人不同意您按照本政策使用我司服务或向我司提供信息，请您立即终止使用我司服务并及时通知我司，以便我司采取相应的措施。如果我司发现在未事先获得末成年人的父母或法定监护人同意的情況下收集了未成年人的个人信息，会尽快删除相关数据。</p>
			<p class="nrjs">如您为未成年人的父母或监护人，当您对您所监护的未成年人的信息处理存在疑问时，请通讨下文中的联系方式联系我司。</p>
		<p class="fdbt">八、本政策的适用及更新</p>
			<p class="nrjs">我司的产品及服务均适用本政策，除非相关服务已有独立的隐私政策或相应的用户服务协议当中存在其它的特殊约定。发生下列重大变化情形时，我司会适时对本政策进行更新：</p>
			<p class="nrjs">1.我们的服务模式发生重大变更。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等。</p>
			<p class="nrjs">2.我们所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等。</p>
			<p class="nrjs">3.个人信息共享、转让或公开披露的主要对象发生变化。</p>
			<p class="nrjs">4.您参与个人信息处理方面的权利及其行使方式发生重大变化。</p>
			<p class="nrjs">5.我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时。</p>
			<p class="nrjs">6个人信息安全影响评估报告表明存在高风险时。</p>
			<p class="nrjs">7.其他可能对您的个人信息权益产生重大影响的变化。</p>
			<p class="nrjs">根据国家法律法规变化及服务运营需要，我司将对本政策及相关规则不时地进行更新。届时我司将以推送通知、弹窗提醒或者在我司官方网站发布公告的方式来通知您。为了您能及时接收到通知，建议您在联系方式更新时及时通知我司。如您在本政策更新生效后继续使用我司产品及服务，即表示您已充分阅读、理解、接受更新后的政策并愿意受更新后的政策约束。</p>
		<p class="fdbt">九、如何联系我司</p>
			<p class="nrjs">您如果对本政策内容有任何疑问，或任何相关的投诉、意见可联系客服电话：“ <span class="color">13692677646</span>”，或登录我司官方网站（<span class="color">http://zhongcaizh.44.infooweb.com/fwys.asp</span>）咨询或反馈。我司建立了客户投诉管理机制，受理您的问题后，我司会及时、妥善处理。一般情况下，我们将在受理后不超过15个工作日或法律法规规定期限内予以回复。</p>
		<p class="fdbt">十、我司向您发送的信息</p>
			<p class="nrjs">1.信息推送</p>
			<p class="nrjs">您在使用我司的服务及产品时，<span class="color">中才智慧服务</span>平台可能向您发送电子邮件、短信、站内信或函件通知等。</p>
			<p class="nrjs">2.与服务有关的公告</p>
			<p class="nrjs"><span class="color">中才智慧服务</span>平台在必要时(例如，因系统维护而暂停某一项服务时)向您发出与服务有关的系统公告。 您可能无法取消这些与服务有关、性质不属于广告的公告</p>
			<p class="nrjs">请您在勾选/选择“同意”之前仔细阅读本政策，确保对其内容的含义及相应法律后果已全部知晓并充分理解。您勾选/选择“同意”并确认提交即视为您接受本政策，我司将按照相关法律法规及本政策来合法使用和保护您的个人信息。</p>
			<p class="nrjs"></p>
			<p class="nrjs"></p>
			<p class="nrjs"></p>
			<p class="nrjs"></p>
			<p class="nrjs"></p>
			<p class="nrjs"></p>
	</div>
</template>

<script>
	export default {
		data() {
			return {
			}
		},
		onLoad(option) {},
		methods: {
			
		}
	}
</script>

<style scoped>
	.color{
		color: rgb(3, 1, 199);
	}
	.nrjs{
		font-size: 13px;
		padding: 0px 0px 5px 0px;
	}
	.fdbt{
		font-weight: 900;
		padding: 0px 0px 5px 0px;
	}
	.nrbt{
		font-size: 12px;
		padding: 0px 0px 5px 0px;
	}
	.xbt{
		font-size: 14px;
		font-weight: 900;
		padding: 0px 0px 5px 0px;
	}
	.js{
		font-size: 13px;
		padding: 0px 0px 5px 0px;
	}
	.sd{
		font-size: 13px;
		text-indent: 2em;
		padding: 0px 0px 5px 0px;
	}
	.page {
		background-color: white;
		height: 100%;
		font-size: 34rpx;
		padding: 0px 10px;
		text-align: left;
		color: black;
	}

	.blank-column {
		height: 20rpx;
	}

	.title{
		margin: 0px 20px;
		text-align: center;
	}

</style>
