import '@/plugins/axios';
import platformConstant from '@/util/PlatformConstant.js'
 
 /**
   * 新增员工
   * @param {*} params 
   * @returns 
  */
  export const savePersonal = params => axios.post("/personalInfo/savePersonal",params).then(data => data)


  /**
   * 根据员工id修改员工信息
   * @param {*} params 
   * @returns 
   */
     export const updatePersonalById = params => axios.post("/personalInfo/updatePersonalById",params).then(data =>data)
  
  
  /**
   * 根据员工id查看员工信息
   * @param {*} params 
   * @returns 
   */
     export const getPersonalById = params => axios.post("/personalInfo/getPersonalById",params).then(data => data)
  
  
  /**
   * 根据员工证件号码查看员工信息
   * @param {*} params 
   * @returns 
   */
     export const getPersonalByDocumentNumber = params => axios.post("/personalInfo/getPersonalByDocumentNumber",params).then(data => data)
  
  
  /**
   * 根据分页查看员工信息列表
   * @param {*} params 
   * @returns 
   */
     export const pagePersonalById = params => axios.post("/personalInfo/pagePersonalById",params).then(data => data)
  
  
  /**
   * 根据员工id批量查看员工信息
   * @param {*} params 
   * @returns 
   */
     export const listPersonalById = params => axios.post("/personalInfo/listPersonalById",params).then(data => data)
  
  
  /**
   * 根据员工个人ID查看员工档案信息
   * @param {*} params 
   * @returns 
   */
     export const getEmployeeInfoById = params => axios.post("/personalInfo/getEmployeeInfoById",params).then(data => data)
  
  
  /**
   * 根据员工个人id批量查看员工档案信息
   * @param {*} params 
   * @returns 
   */
     export const listEmployeeById = params => axios.post("/personalInfo/listEmployeeById",params).then(data => data)
  
  
  /**
   * 根据员工个人信息/ID分页查看员工档案列表
   * @param {*} params 
   * @returns 
   */
     export const pageEmployeeById = params => axios.post("/personalInfo/pageEmployeeById",params).then(data =>data)
  
  
  /**
   * 根据员工个人证件号码查看员工档案信息
   * @param {*} params 
   * @returns 
   */
     export const getEmployeeInfoByDocumentNumber = params => axios.post("/personalInfo/getEmployeeInfoByDocumentNumber",params).then(data => data)
  
  
  /**
   * 根据员工个人id修改员工档案信息
   * @param {*} params 
   * @returns 
   */
     export const updateEmployeeInfoById = params => axios.post("/personalInfo/updateEmployeeInfoById",params).then(data => data) 
  /**
   * 计薪申请列表
   * @param {*} params 
   * @returns 
   */
     export const payrollList = params => axios.post("/salaryapply/payrollList",params).then(data => data) 

   /**
    * 计薪申请导入
    * @param {*} params 
    * @returns 
    */
     export const importSalaryApply = params => axios.post("/salaryapply/importSalaryApply",params).then(data => data) 

