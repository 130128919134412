<template>
  <div id="menutree">
    <el-container class="header">
      <el-aside class="header2" width="100%">
        <!-- 头部 -->
        <el-header class="header3">
          <el-row :gutter="20" style="height: 100%">
            <el-col :span="3">
              <div><span class="ii">
                <img src="@/static/images/logo.png" alt="">
              </span></div></el-col
            >
            <el-col :span="6" :offset="13" style="height: 100%">
              <div class="grid-content">
                欢迎你：{{ tenantInfo.name }}
              </div>
            </el-col>
            <template>
              <el-button type="text" @click="open" class="edit_buttom_class"
                >退出登录</el-button
              >
            </template>
          </el-row>
        </el-header>
        <el-container>
          <!-- 侧边栏 -->
          <el-aside width="13%" class="sidebar_class">
            <el-menu @select="handleSelect" ref="menus" background-color="#1a1a2c" text-color="#fff">
              <tree-menus :list="menuList2" route="componentPath" title="name"></tree-menus>
            </el-menu>
          </el-aside>

          <el-container>
            <el-header id="body">
              <el-breadcrumb id="header4" separator="">
                <el-breadcrumb-item :to="{ path: '' }"
                  ><span style="color: #1d90ff">{{
                    columnName
                  }}</span></el-breadcrumb-item
                >
              </el-breadcrumb>
            </el-header>
            <!-- 主体 -->
            <el-main id="header5">
              <keep-alive>
                <router-view v-if="$route.meta.keepAlive"></router-view>
              </keep-alive>
              <!-- 非缓存的路由视图可以另外单独处理 -->
              <router-view v-if="!$route.meta.keepAlive"></router-view>
            </el-main>
          </el-container>
        </el-container>
      </el-aside>
    </el-container>
  </div>
</template>



<script>
import router from "./../router/index";


import treeMenus from './treeMenus.vue';

export default {
  components: {
    treeMenus,
  },
  data() {
    return {
      queryForm:{
        tenant:'',
      },
      menuList2: [],
      openeds: [],
      tenants:'',
      tenantInfo:'',
      uniqueOpened: false,
      menuList: [],
      userName: "",
      columnName: "用户管理",
      icons: [
        "el-icon-s-platform",
        "el-icon-s-tools",
        "el-icon-star-on",
        "el-icon-s-fold",
        "el-icon-s-unfold",
      ],
    };
  },

  async mounted() {
    await this.loadList2();
    let path = this.$route.fullPath;
    this.$refs.menus.activeIndex = path;
    // this.$refs.menus._events.select[0].fns()
    await this.$refs.menus.handleItemClick(this.$refs.menus.items[path]);
    this.listTenantInfos();
    this.finTenantInfo();
    // this.loadList();
  },

  methods: {
    async loadList2(){
      await this.api.platformApi.treeMenu({sessionId: this.$getCookie("sessionId")}).then(res => {
        let data = res.data;
        if(data.code != 200){
          this.$message.error('菜单加载失败');
        }else{
          this.menuList2 = data.data;
        }
      })
    },
        //查询客户信息
    async listTenantInfos() {
      this.tableLoading = true;
      await this.api.salaryApi.finTenants(this.queryForm).then(res => {
        console.log("-----",res)
          this.tenants = res.data[0];
        });
    },
        //查询客户信息
    async finTenantInfo() {
      this.tableLoading = true;
      await this.api.salaryApi.finTenantInfo(this.queryForm).then(res => {
        console.log("-----租户",res)
          this.tenantInfo = res.data;
        });
    },
    
    // treeMenu
    async loadList() {
      var me = this;
      var params = {
        sessionId: this.$getCookie("sessionId"),
      };
      me.api.platformApi
        .PermissionMenu(params)
        .then(function (response) {
          console.log(response.data.data);
          me.menuList = response.data.data;
          for (let i = 0; i < me.menuList.length; i++) {
            me.openeds.push(me.menuList[i].module);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {});
    },
    handleOpen(key, keyPath) {},
    handleClose(key, keyPath) {},
    async handleSelect(key, keyPath,vnode) {
      console.log(key);
      let value = vnode.$vnode.elm.innerText.trim();
      this.columnName = value;
      await this.menuPermission(value,key)
       console.log(value);
    },
    
    getCookie: function (cname) {
      let name = cname + "=";
      let ca = document.cookie.split(";");
      // console.log("获取cookie,现在循环")
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        // console.log(c)
        while (c.charAt(0) === " ") c = c.substring(1);
        if (c.indexOf(name) !== -1) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    open() {
      this.$confirm("确定要退出登录吗？", "退出确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.$router.push({ path: "/login" });
        })
        .catch(() => {});
    },
    clearCookie: function (cname) {
      this.setCookie(cname, "", -1);
    },
    xx: function (columnName, target) {
      this.columnName = columnName;
    },
    handlerFoucus(event) {
      console.log(event, "xx");
    },
    async menuPermission(value,path) {
      await this.$router.push({path},()=>{},()=>{});
				let response = await this.api.platformApi.menuPermission({menuName: value});
				let data = response.data.data;
        this.$bus.permissionsButton = data;
        this.$forceUpdate();
			}
  },
  router,
};
</script>
<style>
::-webkit-scrollbar{
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  
}
::-webkit-scrollbar-track{
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.2);
  /* display: none; */
}
</style>
<style scoped>
#menutree .sidebar_class {
  text-align: center;
  background-color: #1a1a2c !important;
  height: calc(100vh - 60px);
}
>>> .el-submenu__title{
  text-align: left;
  border: 0 !important;
}
>>> li .el-menu-item{
  border-right: 0 !important;
}
#menutree .edit_buttom_class {
  vertical-align: bottom;
  color: white;
}
#header5 {
  margin: 10px;
  background-color: #fff;
  border-radius:12px;
  overflow-y: auto;
  height: calc(100vh - 176px);
}
#header4 {
  line-height: 56px;
  /* height: 55px; */
}
#body {
  height: 56px !important;
  background-color: white;
}
#menutree .el-header {
  /* background-color: #b3c0d1; */
  color: rgb(252, 248, 248);
  line-height: 50px;
}
/* #menutree .el-button {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgba(228, 228, 228, 1);
} */
body {
  margin: 0;
}
#menutree .ii {
  font-weight: bold;
  font-size: 16px;
  line-height: 60px;
  display: block;
}
#menutree img {
  vertical-align: middle;
}
#menutree .el-aside {
  background-color: white;
  color: #333;
}
>>> .el-submenu__title {
  border-bottom: 1px solid rgba(153, 153, 153, 0.2);
  background-color: #f9f9f9;
}
>>> .el-submenu.is-active .el-submenu__title {
  border-bottom: 1px solid rgba(153, 153, 153, 0.2);
}
.el-menu > .el-menu-item {
  /* padding-left: 85px !important; */
  border-bottom: 1px solid rgb(153, 153, 153, 0.2);
}


 >>> li.el-menu-item{
  border-right: 3px #f9f9f9 solid;
}
#menutree .el-menu-item.is-active {
  /* background-color: #e6f7ff !important; */
  border-right: 3px solid #409eff;
  /* padding-left: 88px !important; */
}
#menutree .el-submenu__title {
  background-color: #f9f9f9;
  border-bottom: 1px solid rgba(153, 153, 153, 0.2);
}
#menutree .header {
  border: 0px solid #eee;
}
#menutree .header2 {
  background-color: rgb(238, 241, 246);
  /* background-color: white; */
}
#menutree .header3 {
  /* background: #1d90ff; */
  background-image: url('~@/static/images/navbg.jpg');
  background-size: contain;
  height: 50px;
}
#menutree .grid-content {
  height: 100%;
  line-height: 60px;
}


</style>


