<template>
  <el-dialog
    :title="title+'统计信息'"
    :visible.sync="dialogVisible"
    width="30%"
  >
    <el-descriptions title="签署统计" :column="2">
      <el-descriptions-item label="本年度发薪人数">{{
        info.payrollCount || 0
      }}</el-descriptions-item>
      <el-descriptions-item label="合同有效期内人数">{{
        info.signContractCount || 0
      }}</el-descriptions-item>
      <el-descriptions-item label="已发起未签署人数">{{
        info.signingCount || 0
      }}</el-descriptions-item>
      <el-descriptions-item label="未发起合同人数">{{
        info.notSignCount || 0
      }}</el-descriptions-item>
      <el-descriptions-item label="合同快到期人数">{{
        0 || 0
      }}</el-descriptions-item>
    </el-descriptions>
    <el-button
      @click="exportSigning"
      type="primary"
      plain
      style="margin-top: 16px;width: 49%;"
      >已发起未签署人员下载</el-button
    >
    <el-button
      @click="exportNotSigning"
      type="primary"
      plain
      style="margin-top: 16px;width: 49%;"
      >未发起合同已过期人员下载</el-button
    >
  </el-dialog>
</template>

<script>
import download from '@/util/download'
export default {
  data() {
    return {
      dialogVisible: false,
      info: {},
    }
  },
  computed: {
    title() {
      const now = new Date()
      let month = now.getMonth()
      let year = now.getFullYear()
      if (month === 0) {
        month = 12
        year -= 1
      }
      return `${year}年`
    },
  },
  methods: {
    getData() {
      this.api.contractApi.getContractStatis().then((res) => {
        if (res.data.code === 200) {
          this.info = res.data.data
          this.dialogVisible = true
        } else {
          this.$message.error(res.data.data.msg)
        }
      })
    },
    exportSigning() {
      this.api.contractApi.exportSigning().then((res) => {
        console.log(res.headers)
        
        const type = res.headers['content-type'].split(';')[0]
        download(
          res.data,
          this.title+'签署中员工名单' + Date.now(),
          type
        )
      })
    },
    exportNotSigning() {
      this.api.contractApi.exportNotSigning().then((res) => {
        const type = res.headers['content-type'].split(';')[0]
        download(
          res.data,
          this.title+'未签署员工名单' + Date.now(),
          type
        )
      })
    },
    open() {
      this.getData()
    },
  },
  watch: {
    dialogVisible(value) {
      if (!value) {
        this.$emit('iframeClose')
      }
    },
  },
}
</script>

<style scoped></style>

