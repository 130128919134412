import '@/plugins/axios';
import platformConstant from '@/util/PlatformConstant.js'

/**
 * 查询产品管理页面数据
 */
export const findProductLinkTenantProduct = params => axios.post(platformConstant.host + "/productManagement/findProductLinkTenantProduct", params).then(data => data)

/**
 * 根据id查询产品
 */
export const findByIdProduct = params => axios.post(platformConstant.host + "/productManagement/findByIdProduct", params).then(data => data)

/**
 * 根据授信设置id查询gradeSetting
 */
export const gradeSettingList = params => axios.post(platformConstant.host + "/productManagement/gradeSettingList", params).then(data => data)

/**
 * 根据授信设置id查询授信设置信息
 */
export const findCreditSettingById = params => axios.post(platformConstant.host + "/productManagement/findCreditSettingById", params).then(data => data)

/**
 * 创建product
 */
export const createProduct = params => axios.post(platformConstant.host + "/productManagement/createProduct", params).then(data => data)

/**
 * 根据id修改产品
 */
export const updateByIdProduct = params => axios.post(platformConstant.host + "/productManagement/updateByIdProduct", params).then(data => data)

/**
 * 根据id查询钱包
 */
export const findWalletById = params => axios.post(platformConstant.host + "/wallet/findWalletById", params).then(data => data)

/**
 * 初始化智能合约
 */
export const createConstract = params => axios.post(platformConstant.host + "/wallet/createConstract", params).then(data => data)

/**
 * 修改租户产品状态为上架
 */
export const updateTenanProductPutontheshelves = params => axios.post(platformConstant.host + "/productManagement/updateTenanProductPutontheshelves", params).then(data => data)

/**
 * 修改租户产品状态为下架
 */
export const updateTenanProductOfftheshelf = params => axios.post(platformConstant.host + "/productManagement/updateTenanProductOfftheshelf", params).then(data => data)

/**
 * 批量创建等级设置，自动生成blockId
 */
export const batchCreateGradeSettingAutoGenerateBlockId = params => axios.post(platformConstant.host + "/productManagement/batchCreateGradeSettingAutoGenerateBlockId", params).then(data => data)

/**
 * 批量动态修改等级设置
 */
export const batchUpdateGradeSetting = params => axios.post(platformConstant.host + "/productManagement/batchUpdateGradeSetting", params).then(data => data)

/**
 * 批量删除等级设置
 */
export const batchDeleteGradeSetting = params => axios.post(platformConstant.host + "/productManagement/batchDeleteGradeSetting", params).then(data => data)

/**
 * 调用区块链修改授信设置接口
 */
export const updateCreditSettingBlockchain = params => axios.post(platformConstant.host + "/productManagement/updateCreditSettingBlockchain", params).then(data => data)

/**
 * 白名单集合
 */
export const findWhiteListSet = params => axios.post(platformConstant.host + "/whiteListManagement/findWhiteListSet", params).then(data => data)

/**
 * 未进白名单集合
 */
export const findNotOnTheWhiteList = params => axios.post(platformConstant.host + "/whiteListManagement/findNotOnTheWhiteList", params).then(data => data)

/**
 * 根据risk批量导入白名单
 */
export const createWhiteListsAccordingToRisk = params => axios.post(platformConstant.host + "/whiteListManagement/createWhiteListsAccordingToRisk", params).then(data => data)

/**
 * 根据risk查询授信信息
 */
export const findCreditByRisk = params => axios.post(platformConstant.host + "/whiteListManagement/findCreditByRisk", params).then(data => data)

/**
 * 根据risk更新授信
 */
export const updateCredit = params => axios.post(platformConstant.host + "/whiteListManagement/updateCredit", params).then(data => data)

/**
 * 修改为无效白名单
 */
export const updateWhiteListInvalid = params => axios.post(platformConstant.host + "/whiteListManagement/updateWhiteListInvalid", params).then(data => data)

/**
 * 查询发票详细信息
 */
export const findInvoiceDetailsInfo = params => axios.post(platformConstant.host + "/whiteListManagement/findInvoiceDetailsInfo", params).then(data => data)

/**
 * 导出当前页面白名单数据excel
 */
export const exportWhiteList = params =>exportExcel(platformConstant.host + "/whiteListManagement/exportWhiteList",params)

/**
 * 根据id查询租户
 */
export const findTenantById = params => axios.post(platformConstant.host + "/whiteListManagement/findById", params).then(data => data)

/**
 * 根据product查询apply
 */
export const findApplyByProduct = params => axios.post(platformConstant.host + "/whiteListManagement/findApplyByProduct", params).then(data => data)

/**
 * 判断是否为本平台租户
 */
export const isThisPlatformTenant = params => axios.post(platformConstant.host + "/productManagement/isThisPlatformTenant", params).then(data => data)

/**
 * 判断是否为本平台租户(根据配置租户对比)
 */
export const determineIfThisIsAPlatformTenant = params => axios.post(platformConstant.host + "/productManagement/determineIfThisIsAPlatformTenant", params).then(data => data)

/**
 * 封装导出Excal文件请求
 * @param url
 * @param data
 * @returns {Promise}
 */
//api.js
export function exportExcel(url, options = {}) {
    return new Promise((resolve, reject) => {
      console.log(`${url} 请求数据，参数=>`, JSON.stringify(options))
      axios.defaults.headers['content-type'] = 'application/json;charset=UTF-8'
      axios({
        method: 'post',
        url: url, // 请求地址
        data: options, // 参数
        responseType: 'blob' // 表明返回服务器返回的数据类型
      }).then(
        response => {
          resolve(response.data)
          let blob = new Blob([response.data], {
            type: 'application/vnd.ms-excel'
          })
          console.log(blob)
          let fileName = "白名单导出列表" + '.xlsx'
          if (window.navigator.msSaveOrOpenBlob) {
            // console.log(2)
            navigator.msSaveBlob(blob, fileName)
          } else {
            // console.log(3)
            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = fileName
            link.click()
            //释放内存
            window.URL.revokeObjectURL(link.href)
          }
        },
        err => {
          reject(err)
        }
      )
    })
  }