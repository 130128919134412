<template>
  <div id="whiteListDetails">
    <div class="textTips">
      <el-row>
        <el-col :span="4" align="left">
          <span>基础信息</span>
        </el-col>
      </el-row>
    </div>
    <table id="table">
      <tr>
        <td>供应商名称</td>
        <td>{{ corpRisk.name }}</td>
        <td>统一社会信用代码</td>
        <td>{{ corpRisk.idno }}</td>
        <td>税务登记号</td>
        <td>{{ corpRisk.taxRegistrationNo }}</td>
        <td>组织机构代码</td>
        <td>{{ corpRisk.corpCode }}</td>
      </tr>
      <tr>
        <td>营业执照注册号</td>
        <td>{{ corpRisk.businessLicenseRegistrationNo }}</td>
        <td>营业执照期限</td>
        <td>{{ corpRisk.termBusinessLicense }}</td>
        <td>证照类别</td>
        <td>
          <div
              v-for="(item, index) in licenseTypeList"
              v-bind:key="index"
            >
              <div v-if="item.datavalue == corpRisk.licenseType">
                {{ item.name }}
              </div>
            </div>
        </td>
        <td>注册所在省</td>
        <td>{{ corpRisk.provinceRegistration }}</td>
      </tr>
      <tr>
        <td>注册所在市</td>
        <td></td>
        <td>注册城市区</td>
        <td></td>
        <td>供应商名称</td>
        <td></td>
        <td>经营所在省</td>
        <td>{{ corpRisk.provinceOperation }}</td>
      </tr>
      <tr>
        <td>经营所在市</td>
        <td>{{ corpRisk.cityOperation }}</td>
        <td>经营城市区</td>
        <td>{{ corpRisk.managingUrbanAreas }}</td>
        <td>经营地址</td>
        <td>{{ corpRisk.businessAddress }}</td>
        <td>经营范围</td>
        <td>{{ corpRisk.natureBusiness }}</td>
      </tr>
      <tr>
        <td>企业成立日期</td>
        <td>{{ corpRisk.establishDate }}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </table>

    <div class="textTips">
      <el-row>
        <el-col :span="4" align="left">
          <span>法人信息</span>
        </el-col>
      </el-row>
    </div>
    <table id="table">
      <tr>
        <td>法人姓名</td>
        <td>{{ personRisk.name }}</td>
        <td>证件号码</td>
        <td>{{ personRisk.idno }}</td>
        <td>法人手机号码</td>
        <td>{{ personRisk.mobile }}</td>
      </tr>
    </table>

    <div class="textTips">
      <el-row>
        <el-col :span="4" align="left">
          <span>银行卡信息</span>
        </el-col>
      </el-row>
    </div>
    <table id="table">
      <tr>
        <td>开户行银行账号</td>
        <td>{{ corpRisk.bankcard }}</td>
        <td>开户银行名称</td>
        <td>{{ corpRisk.bankName }}</td>
        <td>开户银行省</td>
        <td></td>
      </tr>
    </table>

    <div class="textTips">
      <el-row>
        <el-col :span="4" align="left">
          <span>金融信息</span>
        </el-col>
      </el-row>
    </div>
    <table id="table">
      <tr>
        <td>日利率</td>
        <td>{{ credit.creditInterestRate / 10000 }}%</td>
        <td>手续费率</td>
        <td>{{ credit.serviceChargeRate / 10000 }}%</td>
        <td>授信额度</td>
        <td>{{ credit.creditAmount / 100 }}</td>
        <td>有效期截止至</td>
        <td>{{ credit.failureTime }}</td>
      </tr>
    </table>

    <div class="textTips">
      <el-row>
        <el-col :span="4" align="left">
          <span>所属平台</span>
        </el-col>
      </el-row>
    </div>
    <table id="table">
      <tr>
        <td>承运商名称</td>
        <td style="width: 90%">{{ tenant.name }}</td>
      </tr>
    </table>

    <div class="textTips">
      <el-row>
        <el-col :span="4" align="left">
          <span>持有发票</span>
        </el-col>
      </el-row>
    </div>

    <el-table
      :data="findInvoiceDetailsInfoList"
      id="table"
      :header-cell-style="tableHeader"
      :cell-style="tableCell"
      border
    >
      <el-table-column
        prop="num"
        label="序号"
        align="center"
        type="index"
        width="100"
      >
      </el-table-column>
      <el-table-column
        prop="stateInvoiceSerialNo"
        label="发票号码"
        :show-overflow-tooltip="true"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="stateInvoiceNo"
        label="发票代码"
        :show-overflow-tooltip="true"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="amountWithTax"
        label="发票含税金额"
        :show-overflow-tooltip="true"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.amountWithTax / 100 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="taxRate"
        label="发票税率"
        :show-overflow-tooltip="true"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.taxRate / 10000 }}%
        </template>
      </el-table-column>
      <el-table-column
        prop="amountNotWithTax"
        label="发票不含税金额"
        :show-overflow-tooltip="true"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.amountNotWithTax / 100 }}
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="creditAmount"
        label="授信额度"
        :show-overflow-tooltip="true"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.creditAmount / 100 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="availableCreditAmount"
        label="剩余可用额度"
        :show-overflow-tooltip="true"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.availableCreditAmount / 100 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="totalUseAmount"
        label="已使用额度"
        :show-overflow-tooltip="true"
        align="center"
      >
        <template slot-scope="scope">
          <el-link
            type="primary"
            icon="el-icon-fetchByIdSysUserList"
            :underline="false"
            size="medium"
            @click="detailsOfUsedQuota(scope.row)"
            >{{ (scope.row.frozenCreditAmount+scope.row.totalUseCreditAmount) / 100 }}</el-link
          >
        </template>
      </el-table-column> -->
      <el-table-column
        prop="invDate"
        label="开票时间"
        :show-overflow-tooltip="true"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="paymentDate"
        label="应付时间"
        :show-overflow-tooltip="true"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="totalUseAmount"
        label="操作"
        :show-overflow-tooltip="true"
        align="center"
      >
        <template slot-scope="scope">
          <el-link
            type="primary"
            icon="el-icon-fetchByIdSysUserList"
            :underline="false"
            size="medium"
            @click="detailsOfUsedQuota(scope.row)"
            >详情</el-link
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      :visible.sync="detailsOfQuotaUsageDialog"
      width="45%"
      title="额度使用详情"
    >
      <!-- <h3>添加产品</h3> -->
      <div class="form">
        <el-table
          :data="findCreditProofAssetsUseInfoByProofIdList"
          id="table"
          :header-cell-style="tableHeader"
          :cell-style="tableCell"
          border
        >
          <el-table-column
            prop="num"
            label="序号"
            align="center"
            type="index"
            width="100"
          >
          </el-table-column>
          <el-table-column
            prop="assetsId"
            label="贷款订单号"
            :show-overflow-tooltip="true"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="useAmount"
            label="确权金额"
            :show-overflow-tooltip="true"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.useAmount / 100 }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="creditProofAssetsState"
            label="额度状态"
            :show-overflow-tooltip="true"
            align="center"
          >
          <template slot-scope="scope">
            <div v-for="(item, index) in dictionList" v-bind:key="index">
              <div v-if="item.datavalue == scope.row.creditProofAssetsState">
                {{ item.name }}
              </div>
            </div>
          </template>
          </el-table-column>
          <el-table-column
            prop="lendingTime"
            label="借款时间"
            :show-overflow-tooltip="true"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="expectedEndDate"
            label="应还时间"
            :show-overflow-tooltip="true"
            align="center"
          >
          </el-table-column>
        </el-table>
      </div>
      <div id="anniu" slot="footer" class="dialog-footer">
        <el-button @click="close">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      findCreditProofAssetsUseInfoByProofIdList: [],
      findInvoiceDetailsInfoList: [],
      row: {
        risk: "",
        legal: "",
        tenant: "",
      },
      corpRisk: {
        name: "",
        idno: "",
        taxRegistrationNo: "",
        corpCode: "",
        businessLicenseRegistrationNo: "",
        termBusinessLicense: "",
        licenseType: -1,
        provinceRegistration: "",
        provinceOperation: "",
        cityOperation: "",
        managingUrbanAreas: "",
        businessAddress: "",
        natureBusiness: "",
        establishDate: "",
        bankNo: "",
        bankName: "",
      },
      personRisk: {
        name: "",
        idno: "",
        mobile: "",
      },
      tenant: {
        name: "",
      },
      credit: {
        creditInterestRate: "",
        serviceChargeRate: "",
        quota: 0,
        failureTime: "",
      },
      detailsOfQuotaUsageDialog: false,
      addProductForm: {},
      diction: {
        code: "creditProofAssetsState",
      },
      dictionList: [],
      licenseType:{
        code:"licenseType"
      },
      licenseTypeList:[],
    };
  },
  mounted: function () {
    this.row = JSON.parse(localStorage.getItem("row"));
    console.log("接收参数", this.row);

    this.findCreditByRisk(this.row.risk);
    this.findCorpAndRisk(this.row.risk);
    this.findPersonAndRisk(this.row.legal);
    this.findInvoiceDetailsInfo(this.row.risk);
    this.findTenantById();
    this.findDictionariesItemList(this.diction)
  },
  methods: {
    //证照类别
    async licenseTypes(param) {
      let response = await this.api.platformApi.findDictionariesItemList(param);
      console.log("证照类别数据字典返回结果", response);
      this.licenseTypeList = response.data.data;
      console.log("证照类别数据字典集合", this.licenseTypeList);
    },
    //creditproofassets状态
    async findDictionariesItemList(param) {
      let response = await this.api.platformApi.findDictionariesItemList(param);
      console.log("creditproofassets状态数据字典返回结果", response);
      this.dictionList = response.data.data;
      console.log("creditproofassets状态数据字典集合", this.dictionList);
    },
    //根据id查询tenant
    async findTenantById() {
      var params = {
        
      };
      let response = await this.api.preLoanManagementApi.findTenantById(params);
      console.log("=========response", response.data.data);
      if (null != response.data.data) {
        this.tenant = response.data.data;
      }
      console.log("=========根据id查询tenant", this.tenant);
    },
    //已使用额度详情
    detailsOfUsedQuota(row) {
      this.detailsOfQuotaUsageDialog = true;
      this.findCreditProofAssetsUseInfoByProofId(row);
    },
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0";
    },
    close() {
      this.detailsOfQuotaUsageDialog = false;
    },
    //根据risk查询授信信息
    async findCreditByRisk(risk) {
      var params = {
        riskId: risk,
        creditSettingId: this.row.creditSettingId,
      };
      let response = await this.api.preLoanManagementApi.findCreditByRisk(
        params
      );
      console.log("=========response", response.data.data);
      if (null != response.data.data) {
        this.credit = response.data.data;
      }
      console.log("=========根据risk查询授信信息", this.credit);
    },
    //查询crop
    async findCorpAndRisk(id) {
      var param = {
        id: id,
      };
      let corpResponse = await this.api.postLoanManagementApi.findCorpAndRisk(
        param
      );
      console.log("=======公司结果=====", corpResponse.data.data);
      if (corpResponse.data.data != null) {
        this.corpRisk = corpResponse.data.data;
        this.licenseTypes(this.licenseType)
      }
    },
    //查询person
    async findPersonAndRisk(id) {
      var idParam = {
        id: id,
      };
      let personResponse = await this.api.platformApi.findPersonAndRisk(
        idParam
      );
      console.log("=======法人结果=====", personResponse.data.data);
      if (null != personResponse.data.data) {
        this.personRisk = personResponse.data.data;
      }
    },
    //查询发票详细信息
    async findInvoiceDetailsInfo(risk) {
      var params = {
        riskId: risk,
        creditSettingId: this.row.creditSettingId
      };
      let response = await this.api.preLoanManagementApi.findInvoiceDetailsInfo(
        params
      );
      console.log("findInvoiceDetailsInfo===========", response.data.data);
      if (response.data.data != null) {
        this.findInvoiceDetailsInfoList = response.data.data;
      }
      console.log(
        "findInvoiceDetailsInfoList===========",
        this.findInvoiceDetailsInfoList
      );
    },
    //该发票额度使用详情
    async findCreditProofAssetsUseInfoByProofId(row) {
      var params = {
        id: row.proofId,
      };
      let response = await this.api.postLoanManagementApi.findCreditProofAssetsUseInfoByProofId(
        params
      );
      console.log(
        "findCreditProofAssetsUseInfoByProofId===========",
        response.data.data
      );
      if (response.data.data != null) {
        this.findCreditProofAssetsUseInfoByProofIdList = response.data.data;
      }
      console.log(
        "findCreditProofAssetsUseInfoByProofIdList===========",
        this.findCreditProofAssetsUseInfoByProofIdList
      );
    },
  },
};
</script>

<style scoped>
#whiteListDetails {
  background-color: white;
  padding: 15px 20px 100px;
}
.td_widthf {
  width: 90%;
}
#table {
  margin-top: 10px;
  width: 100%;
  background-color: rgba(29, 144, 255, 0);
  margin-bottom: 50px;
}
#whiteListDetails table,
td {
  border: 1px solid #e8e8e8;
  border-collapse: collapse;
  line-height: 35px;
  /* margin-top: 3px; */
}
td:nth-child(odd) {
  background: #fafafa;
  width: 10%;
  text-align: center;
  font-size: 13px;
}
td:nth-child(even) {
  width: 15%;
  text-align: left;
  color: #7c7c7c;
  font-size: 13px;
}
>>> .el-dialog__body {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgb(228, 228, 228, 1);
  padding-left: 0;
  padding-right: 0;
  line-height: 100%;
}
>>> .el-dialog__header {
  text-align: left;
}
.form {
  padding: 0 50px;
}
</style>