import '@/plugins/axios';

/**
 * 计薪-列表
 */
export const listPayrollSummary = params => axios.post("/payrollSummary/listPayrollSummary", params).then(data => data)

 /**
 * 计薪-详情
 */
export const payrollSummaryDetail = params => axios.post("/payrollSummary/payrollSummaryDetail", params).then(data => data)

  /**
 * 计薪-审批列表
 */
export const payrollSummaryApproval = params => axios.post("/payrollSummary/payrollSummaryStatusOneAndTwo", params).then(data => data)

 /**
 * 计薪-提交审核
 */
export const updateSubmitReviewStatus = params => axios.post("/payrollSummary/updateSubmitReviewStatus", params).then(data => data)

 /**
 * 计薪-审核完成
 */
  export const updateAuditCompletedStatus = params => axios.post("/payrollSummary/updateAuditCompletedStatus", params).then(data => data)

   /**
 * 计薪-撤销审核
 */
export const updateRevokeApprovalStatus = params => axios.post("/payrollSummary/updateRevokeApprovalStatus", params).then(data => data)

 /**
 * 计薪-审核失败
 */
  export const updateAuditFailedStatus = params => axios.post("/payrollSummary/updateAuditFailedStatus", params).then(data => data)

   /**
 * 计薪-作废
 */
export const updateNullifyStatus = params => axios.post("/payrollSummary/updateNullifyStatus", params).then(data => data)

  