<template>
  <el-dialog title="发起签署" :visible.sync="dialogFormVisible" width="720px">
    <el-form :model="form">
      <el-form-item
        label="签署任务主题"
        :label-width="formLabelWidth"
        :required="true"
      >
        <el-input
          style="width: 100%"
          v-model.trim="form.signTaskSubject"
          autocomplete="off"
          placeholder="请输入签署任务主题"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="签署截止时间"
        :label-width="formLabelWidth"
        :required="true"
      >
        <el-date-picker
          v-model="form.expiresTime"
          type="date"
          placeholder="选择日期"
          style="width: 100%"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>

      <template v-if="multiple">
        <el-form-item
          v-if="multiple"
          label="选择模板"
          :label-width="formLabelWidth"
          :required="true"
        >
          <el-select
            v-model.trim="templateId"
            placeholder="选择模板"
            style="width: 100%;"
            @change="templateChange"
            filterable
          >
            <el-option
              v-for="elem of templateList"
              :key="elem.id"
              :value="elem.id"
              :label="elem.name">
              <span style="float: left">{{ elem.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ elem.tenantName }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="参与方"
          :label-width="formLabelWidth"
          :required="true"
        >
          <div>
            <el-upload
              ref="upload"
              class="upload-demo"
              action="/"
              :auto-upload="false"
              :show-file-list="true"
              :limit="1"
              :on-change="handleChange"
            >
              <el-button size="small" type="primary">导入数据</el-button>
              <el-button type="text" @click.stop="download">模板下载</el-button>
              <span slot="tip" class="el-upload__tip">
                <span>
                  &nbsp;只能上传execl文件！
                </span>
                <el-button size="small" type="text"
                  >已导入{{ fillFieldVOS.length }}人</el-button
                >
              </span>
            </el-upload>
          </div>
        </el-form-item>
        <!-- <el-table :data="fillFieldVOS" height="280px" center>
          <el-table-column v-for="elem of tableCloumn" :key="elem.fieldId" :prop="elem.fieldName" :label="elem.fieldName"></el-table-column>
          <el-table-column prop="actorName" label="参与方名称">
          </el-table-column>
          <el-table-column
            prop="actorMobile"
            label="参与方手机号码"
          ></el-table-column>
        </el-table> -->
      </template>
      <template v-else>
        <el-form-item
          label="合同开始日期"
          :label-width="formLabelWidth"
          :required="true"
        >
          <el-date-picker
            v-model="form.startTime"
            type="date"
            placeholder="选择日期"
            style="width: 100%"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="合同到期日期"
          :label-width="formLabelWidth"
          :required="true"
        >
          <el-date-picker
            v-model="form.dueDate"
            type="date"
            placeholder="选择日期"
            style="width: 100%"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        
        <el-form-item
          label="参与方姓名"
          :label-width="formLabelWidth"
          :required="true"
        >
          <el-input
            style="width: 100%"
            v-model.trim="actorVO.actorName"
            autocomplete="off"
            placeholder="请输入参与方姓名"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="参与方手机号"
          :label-width="formLabelWidth"
          :required="true"
        >
          <el-input
            style="width: 100%"
            v-model.trim="actorVO.actorMobile"
            autocomplete="off"
            placeholder="请输入参与方手机号"
          ></el-input>
        </el-form-item>
      </template>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="loading"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import dayjs from 'dayjs'
import XLSX from 'xlsx'
export default {
  props: {
    isTemplate: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      loading: false,
      form: {
        signTaskSubject: '',
        expiresTime: '',
        dueDate: '',
        startTime: '',
      },
      fillFieldVOS: [],
      templateId: '',
      formLabelWidth: '110px',
      pickerOptions: {
        disabledDate(time) {
          // 禁用今天的日期之前的所有日期
          return time.getTime() < Date.now() - 3600 * 1000 * 24
        },
      },
      templateList: [],
      actorVO: {
        actorName: '',
        actorMobile: '',
      },
      tableCloumn: [],
    }
  },
  methods: {
    templateChange(value) {
      if (!value) {
        return
      }
      this.getTemplatefields()
    },
    async getTemplatefields() {
      try {
        const res = await this.api.contractApi.templateGetFillFields({
          id: this.templateId,
        })
        if (res.data.code !== 200) {
          return this.$message.error(res.data.msg)
        }
        const data = res.data.data
        this.tableCloumn = data
      } catch (error) {
        console.log(error)
      }
    },
    async download() {
      if (!this.templateId) return this.$message.error('请先选择模板')
      const data = this.tableCloumn
      const tableData = [
        data.map((elem) => elem.parties + '-' + elem.fieldName),
      ]
      // 设置列宽
      const wscols = tableData[0].map((elem) => ({ wch: elem.length * 3 }))
      let workSheet = XLSX.utils.aoa_to_sheet(tableData)
      workSheet['!cols'] = wscols
      let bookNew = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(bookNew, workSheet, '批量签署导入模板')
      let name = '批量签署导入模板' + Date.now() + '.xlsx'
      XLSX.writeFile(bookNew, name)
    },
    handleChange(file, fileList) {
      this.$refs.upload.clearFiles()
      if (!this.templateId) {
        return this.$message.error('请先选择模板')
      }
      const fileContent = file.raw
      const fileName = file.name
      const fileType = fileName.substring(fileName.lastIndexOf('.') + 1)
      if (fileContent) {
        if (fileType === 'xlsx' || fileType === 'xls') {
          this.importfile(fileContent)
        } else {
          this.$message({
            type: 'warning',
            message: '附件格式错误，请重新上传！',
          })
        }
      } else {
        this.$message({
          type: 'warning',
          message: '请上传附件！',
        })
      }
    },
    importfile(obj) {
      const reader = new FileReader()
      const _this = this
      reader.readAsArrayBuffer(obj)
      reader.onload = function() {
        const buffer = reader.result
        const bytes = new Uint8Array(buffer)
        const length = bytes.byteLength
        let binary = ''
        for (let i = 0; i < length; i++) {
          binary += String.fromCharCode(bytes[i])
        }
        const wb = XLSX.read(binary, {
          type: 'binary',
          cellDates: true
        })
        const outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]],{head: 0,defval: ""})
        const tableData = outdata
        const arr = []
        let valueList = []
        for (let i = 0; i < tableData.length; i++) {
          valueList = []
          const dataItem = tableData[i]
          console.log(dataItem)
          for (let j = 0; j < _this.tableCloumn.length; j++) {
            const cloumnItem = _this.tableCloumn[j]
            const valueField = `${cloumnItem.parties}-${cloumnItem.fieldName}`
            if (!dataItem.hasOwnProperty(valueField)) {
              return _this.$message.error('模板与导入数据不匹配')
            }
            // 如果是时间格式
            if(Date.prototype.isPrototypeOf(dataItem[valueField])){
              dataItem[valueField] = dayjs(dataItem[valueField]).add(60, 'second').format('YYYY/MM/DD')
            }
            valueList.push({ ...cloumnItem, fieldValue: dataItem[valueField] })
          }
          arr.push(valueList)
        }
        _this.fillFieldVOS = arr
        console.log(arr)
      }
    },
    open(templateId) {
      this.templateId = templateId || ''
      this.fillFieldVOS = []
      this.actorVO = {
        actorName: '',
        actorMobile: '',
      }
      this.form = {
        signTaskSubject: '',
        expiresTime: '',
        dueDate: '',
        startTime: '',
      }
      if (this.templateId) {
        this.dialogFormVisible = true
      } else {
        const data = {
          pageNo: 1,
          pageSize: 999,
          status: 'valid',
          tenantId: localStorage.getItem('platform'),
        }
        this.api.contractApi.listPageTemplate(data).then((res) => {
          if (res.data.code === 200) {
            this.templateList = res.data.data.records
            this.dialogFormVisible = true
          } else {
            this.$message.error(res.data.msg)
          }
        })
      }
    },
    submit() {
      if (!this.templateId) {
        this.$message.error('请选择模板')
        return
      }
      if (!this.form.signTaskSubject) {
        this.$message.error('请填写签署任务主题')
        return
      }
      if (!this.form.expiresTime) {
        this.$message.error('请选择截止时间')
        return
      }
      if (!this.multiple && !this.form.dueDate) {
        this.$message.error('请选择合同到期日期')
        return
      }
      if (!this.multiple && !this.form.startTime) {
        this.$message.error('请选择开始日期')
        return
      }
      if (!this.fillFieldVOS.length && this.multiple) {
        this.$message.error('请导入参与方')
        return
      }
      if (
        (!this.actorVO.actorName || !this.actorVO.actorMobile) &&
        !this.multiple
      ) {
        this.$message.error('请输入参与方')
        return
      }
      const data = {
        signTaskSubject: this.form.signTaskSubject,
        expiresTime:
          dayjs(this.form.expiresTime)
            .endOf('day')
            .valueOf() + '',
        redirectUrl:
          window.location.origin +
          window.location.pathname +
          'contract-success.html',
      }
      if (this.isTemplate) {
        data.id = this.templateId
      }
      if (this.multiple) {
        data.fillFieldVOS = this.fillFieldVOS
      }
      if (!this.multiple) {
        data.startTime =
          dayjs(this.form.startTime)
            .endOf('day')
            .valueOf() + ''
        data.actorVO = this.actorVO
        data.dueDate =
          dayjs(this.form.dueDate)
            .endOf('day')
            .valueOf() + ''
      }
      let requestUrl = this.api.contractApi.start
      if (this.multiple) {
        requestUrl = this.api.contractApi.batchSignStart
      }

      this.loading = true
      requestUrl(data)
        .then((res) => {
          if (res.data.code === 200) {
            this.$message.success('发起签署成功')
            this.dialogFormVisible = false
            if (!this.multiple) {
              this.$emit('success', res.data.data)
            }
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
<style scoped>
.upload-demo {
  text-align: left;
}
</style>

