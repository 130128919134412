<template>
	<div class="page">
		<view class="blank-column"></view>
		<view class="title">
			<p>平台注册协议</p>
		</view>
		<p class="sd">四川中才智慧信息技术服务有限公司（以下简称“我司”），注册地址为四川省凉山彝族自治州西昌市周堡中路3号中国西昌数字经济产业园3栋第13层1302号。我司非常重视用户的使用体验，为更好地为您提供服务，请您认真阅读本协议。</p>
		<!-- <p class="xbt">本政策将帮助您了解以下内容：</p> -->
		<p class="nrbt">提示条款</p>
			<p class="nrjs">欢迎您与我司签署本《平台服务协议》（下称“本协议”） 并使用<span class="color">中才智慧服务</span>平台服务。各服务条款前所列关键词仅为帮助您理解该条款表达的主旨之用，不影响或限制本协议条款的含义或解释。为维护您自身权益，建议您仔细阅读各条款具体表述。</p>
			<p class="nrjs">【审慎阅读】您在申请注册流程中点击同意本协议之前，应当认真阅读本协议。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。如您对协议有任何疑问，可向我司客服咨询。</p>
			<p class="nrjs">【签约动作】当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容，并与我司达成一致，成为<span class="color">中才智慧服务</span>平台“用户”问读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册程序。</p>
			<p class="nrjs">本协议是您与我司之间就<span class="color">中才智慧服务</span>平台产品及服务等相关事宜达成的合意，请您仔细阅读本协议。点击“同意协议”按钮之日起，即表示您已充分阅读、理解并同意自己与我司订立本协议，且您自愿受本协议的条款约束。我司有权随时变更本协议并在我司网站上予以公告。经修订的条款一经在我司网站上公布后，立即自动生效。如您不同意相关变更，必须停止使用我司。本协议内容包括协议正文及已经后续签署的各类合同文件，均属于本协议不可分割的一部分，与本协议正文具有同等法律效力。一旦您继续使用本平台，则表示您已接受并自愿遵守经修订后的条款。本协议即构成对您与我司之间有约束力的法律文件。</p>
			<p class="nrjs">依照《中华人民共和国民法典》及相关法律、行政法规、遵循平等、自愿、公平和诚信原则，为明确各方责任，保护双方合法权益，经双方友好协商，达成如下协议：</p>
		<p class="nrbt">第一条定义</p>
			<p class="nrjs">1.<span class="color">中才智慧服务</span>平台：向使用平台的用户提供薪酬结算、委托代发等交易鉴证平台。</p>
			<p class="nrjs">2.平台注册协议：指公示在<span class="color">中才智慧服务</span>平台之上的，与<span class="color">中才智慧服务</span>平台运营相关的全部规范性文件及流程操作指引。</p>
			<p class="nrjs">3.“用工企业”：指已成为<span class="color">中才智慧服务</span>平台的用工企业注册用户并准备或正在<span class="color">中才智慧服务</span>平台发布项目的用户。</p>
			<p class="nrjs">4.“职业者”：指在<span class="color">中才智慧服务</span>平台上已注册并经确认符合属于某用工企业的职业者。</p>
			<p class="nrjs">5.“薪酬数额”：用工企业按相关合同及职业者当月用工薪资情况确定薪酬数额。</p>
			<p class="nrjs">6.“派遣费”：是指用工企业向我司支付的资金，用以发放职业者每个月劳动报酬。</p>
			<p class="nrjs">7.“不可抗力”：指不能预见、不能避免并不能克服的客观情况，包括但不限于战争、台风、水灾、火灾、雷击或地震、罢工、暴动、法定疾病、黑客攻击、网络病毒、电信网监部门管制、政府行为或任何其它自然或人为造成的灾难等强力制约本协议正常履行的客观情况。</p>
		<p class="nrbt">第二条 服务内容</p>
			<p class="nrjs">1.服务内容：各方确认并同意，本协议项下用工企业委托<span class="color">中才智慧服务</span>平台提供的薪酬代发服务内容及服务方式等应当符合国家法律法规以及有关部门的监管规定，且应符合双方的经营范国及资质许可要求。</p>
			<p class="nrjs">2.职业者仅按相关协议约定的服务内容为用工企业提供信息及相关服务，因职业者或第三方原因导致用工企业损失的，<span class="color">中才智慧服务</span>平台不承担相关赔偿责任和/或相关所有连带责任。</p>
			<p class="nrjs">3.费用发放：用工企业按相关合同确认发放职业者薪资报酬之后，<span class="color">中才智慧服务</span>平台根据与用工企业之间的相关合同，从用工企业账户派遣费中划扣相应金额至职业者账户。</p>
			<p class="nrjs">4.职业者账户：职业者在<span class="color">中才智慧服务</span>平台上传银行卡账号用于接收用工企业委托我司进行代发薪酬账户。</p>
		<p class="nrbt">第三条 用户认证条件及证明文件提交</p>
			<p class="nrjs">1.认证条件：用工企业及职业者申请成为<span class="color">中才智慧服务</span>平台认证用户，在<span class="color">中才智慧服务</span>平台开展经营活动，同时职业者应保证满足以下全部条件：</p>
			<p class="nrjs">（1）职业者同意严格遵守本协议及平台用户隐私政策协议、相关操作指引等文件，我司将不断补充完善，更新后<span class="color">中才智慧服务</span>平台会及时在平台以公示方式通知职业者；职业者有权选择拒绝履行，并立即停止使用<span class="color">中才智慧服务</span>平台的服务。</p>
			<p class="nrjs">（2）根据中国法律、法规、行政规章成立并合法存在的机关、企事业单位、社团组织和其他组织。不满足条件的自然人不能注册为我司用户的，其与我司之间协议自始无效，我司一经发现，有权立即终止对该用户的服务，并追究其使用我司服务的一切法律责任。</p>
			<p class="nrjs">2.证明文件提交</p>
			<p class="nrjs">（1） 职业者根据平台规则及相关要求向<span class="color">中才智慧服务</span>平台提交身份证、银行账户等清晰扫描件。</p>
			<p class="nrjs">（2） 职业者保证向平台提供的上述证明文件或其他相关证明真实、合法、准确、有效，并保证上述证明文件或其他相关证明发生任何变更、更新、撒销或注销之日起十日内书面通知平台；若因职业者提交虛假、过期文件或未及时更新或通知证明文件导致纠纷或被相关国家机关处罚的，由职业者独立承担全部法律责任，如因此造成<span class="color">中才智慧服务</span>平台或用工企业(包括用工企业代理人或职员）其他任何相关方损失的，职业者应当承担全部的赔偿责任.</p>
			<p class="nrjs">第四条 职业者权力与义务 </p>
			<p class="nrjs">1.对于职业者账户，<span class="color">中才智慧服务</span>平台在其提出开通申请并满足以下条件后为其开通服务。</p>
			<p class="nrjs">（1）职业者已按照本协议约定提交相关文件或其他相关证明并已在<span class="color">中才智慧服务</span>平台完成备案；</p>
			<p class="nrjs">（2） 职业者已注册成为<span class="color">中才智慧服务</span>平台用户且本协议已生效。</p>
			<p class="nrjs">2.职业者提交的任务信息须符合法律法规规定，出现以下任一情形时，<span class="color">中才智慧服务</span>平台有权随时停止职业者相关账户的服务，有权解除本协议，并应由违约方承担因下述事宜造成的全部损失；</p>
			<p class="nrjs">（1） 职业者不再满足入驻条件的；</p>
			<p class="nrjs">（2） 职业者提供虛假资质文件的；</p>
			<p class="nrjs">（3）职业者执行项目错误或虛假业务，导致<span class="color">中才智慧服务</span>平台受到或面临行政处罚、争议或纠纷的；</p>
			<p class="nrjs">（4）职业者提交的项目信息含有可能危害国家安全、破坏民族团结及宗教政策、宣扬邪教迷信、淫秽色情、虛假、侮辱、诽谤、恐吓或骚扰、涉嫌非法集资、侵犯他人知识产权和商业秘密、人身权或其他合法权益等违法或有违社会公序良俗的信息。</p>
			<p class="nrjs">第五条 用工企业权利与义务</p>
			<p class="nrjs">1.用工企业有权根据本协议、平台相关规则等合同文件，向我司支付派遣费，由我司代为向职业者支付薪资报酬。</p>
			<p class="nrjs">2.用工企业应自行负责自己的用户账号和密码，不得擅自以任何形式转让或授权他人使用，如用工企业向他人提出操作指导帮助请求并把平台账户名称及密码主动透露给他人，用工企业仍须对在用户账号密码下发生的所有活动承担责任。用工企业有杈根据需要更改密码，且<span class="color">中才智慧服务</span>平台建议用工企业定期更改。因用工企业过错而导致的任何损失由用工企业自行承担，该过错包括但不限于：不按照交易提示操作，未及时进行交易操作，遗忘或泄漏密码，因密码保存不善被他人破解等。</p>
			<p class="nrjs">3.用工企业在平台网上交易过程中如与职业者因交易产生纠纷，可以请求平台沟通协调。用工企业、职业者如发现其他用户有违法或违反本协议的行为，可以向平台举报。</p>
			<p class="nrjs">4.用工企业应当保证本合作资金来源的稳定性及合法，若因资金自身存在的问题并由此带来的一切后果及法律责任由用工企业自行承担。</p>
			<p class="nrjs">第六条 <span class="color">中才智慧服务</span>平台权利与义务</p>
			<p class="nrjs">1.<span class="color">中才智慧服务</span>平台仅为用工企业提供一个便捷服务平台，为解决职业者薪酬的发放提供高质量的服务，用工企业可以更好地节约用工成本。<span class="color">中才智慧服务</span>平台负责<span class="color">中才智慧服务</span>平台运营、维护，确保平台上用工企业薪酬支付等操作流程的正常运行。</p>
			<p class="nrjs">2.<span class="color">中才智慧服务</span>平台有权对用工企业的资质进行审查，用工企业应当配合并按照<span class="color">中才智慧服务</span>平台的要求予以改正；<span class="color">中才智慧服务</span>平台的审核行为并不意味着<span class="color">中才智慧服务</span>平台对用工企业的经营资质、任务、交易或用工企业自行上传在<span class="color">中才智慧服务</span>平台的其他信息的真实性、准确性、合法性、有效性承担任何明示或暗示的保证或担保等责任，也不意味着<span class="color">中才智慧服务</span>平台成为用工企业与职业者之间的参与方，因用工企业的行为导致的任何投诉、纠纷、争议、赔偿等用工企业应以自己的名义独立沟通与解决，并承担全部相应责任，平台可给予用工企业提供必要配合，但<span class="color">中才智慧服务</span>平台不承担由此产生的任何法律责任。</p>
			<p class="nrjs">3.用工企业与<span class="color">中才智慧服务</span>平台之问，均不因本协议的存在而成立劳动关系、劳务关系、雇佣关系、代理关系等非平台服务合同关系，用工企业将员工薪酬发放项目委托我司，由我司XXX平台承接项目后另行与职业者签署相关协议。双方确定<span class="color">中才智慧服务</span>平台无须为用工企业交纳社会保险及意外伤害等商业保险，但各方另有书面约定除外。同时，<span class="color">中才智慧服务</span>平台强烈建议用工企业为职业者购买商业保险。职业者在执行任务期问受到或对任何第三方造成人身、财产伤害，用工企业应自行承担后果，不得要求<span class="color">中才智慧服务</span>平台承担侵权等赔偿责任。</p>
			<p class="nrjs">4.用工企业基于平台发生的任何商业行为所引起的一切法律纠纷由用工企业自行承担，与<span class="color">中才智慧服务</span>平台无关。但<span class="color">中才智慧服务</span>平台会积极配合用工企业处理相关事宜以促进各项事宜的解决。</p>
			<p class="nrjs">5.因<span class="color">中才智慧服务</span>平台或用工企业的相关系统升级、调试等原因，需要按计划暂停服务时，不应视为<span class="color">中才智慧服务</span>平台违约，<span class="color">中才智慧服务</span>平台有义务尽可能减少对用工企业的影响，并提前通知用工企业。</p>
			<p class="nrjs">6.<span class="color">中才智慧服务</span>平台因以下情况没有正确执行用工企业委托事项的，不应视为<span class="color">中才智慧服务</span>平台违约，<span class="color">中才智慧服务</span>平台不承担任何责任：</p>
			<p class="nrjs">（1） 接收到的用工企业委托事项有关信息（包括但不限于对账单等）不明确、不完整或无法辨认；</p>
			<p class="nrjs">（2） 用工企业的预付款的余额、或可用资金额度不足；</p>
			<p class="nrjs">（3） 用工企业未按照<span class="color">中才智慧服务</span>平台的有关平台规则、流程规范等要求操作与执行；</p>
			<p class="nrjs">（4） 不可抗力或其他不可归因于<span class="color">中才智慧服务</span>平台的情形，包括但不限于网络故障、通讯故障、停电、黑客或病毒侵袭等；</p>
			<p class="nrjs">（5）有关部门或国家机关依法命令<span class="color">中才智慧服务</span>平台停止提供服务或停止为用工企业服务。</p>
			<p class="nrjs">第七条 计费标准及结算方式</p>
			<p class="nrjs">1.用工企业按照与我司签署的相关协议将派遣费划入我司账户，由我司按照该协议约定发放职业者薪资报酬时间从<span class="color">中才智慧服务</span>平台用工企业账户划至职业者账户，完成支付；</p>
			<p class="nrjs">2.各方根据本协议开展的服务，以及职业者在平台与用工企业之间展开的业务合作，均以人民币 (RMB/￥）作为法定结算货币，另有约定除外。</p>
			<p class="nrjs">第八条 所有权、知识产权、使用杈</p>
			<p class="nrjs">1.平台所使用的任何相关软件、程序、内容，包括但不限于作品、图片、图像、视频、档案、资料、网站构架、网站版面的安排、网页设计、经由<span class="color">中才智慧服务</span>平台或广告商向用户呈现的广告或资讯，均由<span class="color">中才智慧服务</span>平台或其它权利人依法享有相应的知识产权，包括但不限于著作权、商标权、专利权或其它专属权利等，受到相关法律的保护。未经<span class="color">中才智慧服务</span>平台或权利人明示书面授权职业者，职业者保证不修改、出租、出借、出售、散布<span class="color">中才智慧服务</span>平台及<span class="color">中才智慧服务</span>平台所使用的上述任何资料和资源，或根据上述资料和资源制作成任何种类产品。与<span class="color">中才智慧服务</span>平台相关的且由此业务产生的商业秘密信息、客户资料、渠道资源、技术资料和技术诀窍等所有杈均归我司所有。</p>
			<p class="nrjs">2.对于<span class="color">中才智慧服务</span>平台向职业者提供的平台服务，除各方另有约定外，并不表示职业者已经从<span class="color">中才智慧服务</span>平台获得向第三人转让或许可第三人使用该平台的权利。</p>
			<p class="nrjs">3.<span class="color">中才智慧服务</span>平台在本合作协议项下的任何权利不因其发生收购、兼并、重组而发生变化；如<span class="color">中才智慧服务</span>平台被收购、被兼并、被重组，则<span class="color">中才智慧服务</span>平台在本合作协议项下的权利随之转移至收购、兼并或重组单位。</p>
			<p class="nrjs">4.服务软件形式</p>
			<p class="nrjs">若<span class="color">中才智慧服务</span>平台依托“软件”（若有）向职业者或用工企业提供平台服务，还应遵守以下约定：</p>
			<p class="nrjs">（1） <span class="color">中才智慧服务</span>平台可能为不同的终端设备开发不同的软件版本，职业者及用工企业应当根据实际需要选择下载合适的版本进行安装。</p>
			<p class="nrjs">（2）如果职业者或用工企业从未经合法授权的第三方获取本软件或与本软件名称相同的安装程序，<span class="color">中才智慧服务</span>平台将无法保证该软件能否正常使用，由此给职业者或用工企业造成的任何损失不予负责。</p>
			<p class="nrjs">（3） 为了增进平台用户体验、完善服务内容，<span class="color">中才智慧服务</span>平台将不时提供软件更新服务（该更新可能会采取软件替换、修改、功能强化、版本升级等形式）。为不断优化用户体验，保证服务安全性与功能的一致性，<span class="color">中才智慧服务</span>平台有权对软件进行更新或对软件的部分功能效果进行改变或限制。</p>
			<p class="nrjs">(4)软件新版本发布后，旧版软件可能无法使用。<span class="color">中才智慧服务</span>平台不保证1日版软件继续可用及相应的客户服务，请职业者及用工企业随时核对并下载最新版本。</p>
			<p class="nrjs">5.除非法律允许或<span class="color">中才智慧服务</span>平台书面许可，用工企业不得从事下列行为：</p>
			<p class="nrjs">(1)删除软件及其副本上关于著作权的信息；</p>
			<p class="nrjs">(2)对软件进行反向工程、反向汇编、反向编译或者以其他方式尝试发现软件的源代码；</p>
			<p class="nrjs">(3)对<span class="color">中才智慧服务</span>平台或其关联公司拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等侵犯知识产权及相关专属权利的行为；</p>
			<p class="nrjs">(4)对软件或者软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及软件运行所必需的系统数据，进行复制、修改、增加、删除或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经合法授权的第三方工具/服务接入软件和相关系统；</p>
			<p class="nrjs">(5) 修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论上述行为是否为商业目的；</p>
			<p class="nrjs">(6) 通过非<span class="color">中才智慧服务</span>平台开发、授权的第三方软件、插件、外挂、系统，使用<span class="color">中才智慧服务</span>平台服务，或制作、发布、传播非<span class="color">中才智慧服务</span>平台开发、授权的第三方软件、插件、外挂、系统；</p>
			<p class="nrjs">(7)其他未经<span class="color">中才智慧服务</span>平台明示授权和/或许可的行为。</p>
			<p class="nrjs">6.用工企业如因违反本协议第八条而对<span class="color">中才智慧服务</span>平台构成违约或侵权，用工企业应向<span class="color">中才智慧服务</span>平台支付一定金额的损害赔偿金。</p>
			<p class="nrjs">第九条 保密条款</p>
			<p class="nrjs">1.各方对于本协议的签订、内容及在履行本协议期问所获知的另一方的商业秘密负有保密义务。非经对方书面同意，任何一方不得向第三方（关联公司除外）泄露、给予或转让该等保密信息（根据法律、法规、证券交易所规则向政府、证券交易所或其他监管机构提供、双方的法律、会计、商业及其他顾问、雇员除外）。</p>
			<p class="nrjs">2.在本协议终止之后，各方在本条款项下的义务并不随之终止，各方仍需遵守本协议之保密条款，履行其所承诺的保密义务，直到其他方同意其解除此项义务，或事实上不会因违反本协议保密条款而给其他方造成任何形式的损害时为止。</p>
			<p class="nrjs">3.任何一方均应告知并督促其因履行本协议之目的而必须获知本协议内容及因合作而获知对方商业秘密的雇员、代理人、顾问等遵守保密条款，并对其雇员、代理人、顾问等的行为承担连带责任。</p>
			<p class="nrjs">第十条 不可抗力</p>
			<p class="nrjs">1.因不可抗力或者其他意外事件，使得本协议的履行不可能、不必要或者无意义的，各方均不承担责任。</p>
			<p class="nrjs">2.对于因不可抗力或<span class="color">中才智慧服务</span>平台不能控制的原因造成的网络服务中断或其它缺陷，<span class="color">中才智慧服务</span>平台不承担任何责任，但将尽力减少因此而给用工企业、职业者造成的损失和影响。</p>
			<p class="nrjs">3.任何一方因不可抗力事件不能履行本协议的，应当在事件发生后7日内书面通知对方，以减轻可能给对方造成的损失，并应当在通知发出后7日内提供相关的证明。</p>
			<p class="nrjs">4.如果不可抗力持续15日以上，任何一方均有杈终止均有权终止本协议。</p>
			<p class="nrjs">第十一条 违约责任</p>
			<p class="nrjs">1.任何一方违反本协议约定的，应承担相应的违约责任，并赔偿对方因此受到的直接损失。守约方有权单方提前终止本协议。</p>
			<p class="nrjs">2.本协议有效期内，因国家相关主管部门颁布、变更的法令、政策（包括但不限于监管机构向<span class="color">中才智慧服务</span>平台出具的，要求停止或整改本协议约定的业务的书面通知等，下同）导致<span class="color">中才智慧服务</span>平台不能提供约定服务的，不视为<span class="color">中才智慧服务</span>平台违约，双方应根据相关的法令、政策变更本协议内容。</p>
			<p class="nrjs">3.如一方违反本协议约定的禁止性规定（禁止性规定，是指协议条款中含有“不得”、“不能”等表述的内容，不可抗力条款中的表述除外)，守约方有权单方提前终止协议。</p>
			<p class="nrjs">4.职业者不论采取何种方式非法获取<span class="color">中才智慧服务</span>平台系统数据、利用<span class="color">中才智慧服务</span>平台谋取不正当利益或从事非法活动的，<span class="color">中才智慧服务</span>平台有权追究违约责任。</p>
			<p class="nrjs">5.<span class="color">中才智慧服务</span>平台有权对职业者是否涉嫌违反本协议之约定做出认定，并根据认定结果中止、终止向职业者提供服务或采取其他限制措施。</p>
			<p class="nrjs">6.职业者违反本协议约定对任意第三方造成损害的，职业者均应当以自己的名义独立承担所有的法律责任，并应确保<span class="color">中才智慧服务</span>平台、用工企业免于因此产生损失或增加费用。如职业者该等行为使<span class="color">中才智慧服务</span>平台、用工企业遭受任何损失，或受到任何第三方的索赔，或受到任何行政管理部门的处罚，职业者应当赔偿以上主体因此遭受的全部损失（即直接损失及签订本协议时所能预见到的损失）和/或发生的费用，包括合理的律师费用、调查取证费用等。</p>
			<p class="nrjs">第十二条 本协议的签订、变更、补充</p>
			<p class="nrjs">1.本协议由本协议正文条款、附件与<span class="color">中才智慧服务</span>平台规则等公示的各项通知、操作指引等规范性文件组成，相关名词可互相引用参照，如有不同理解，以本协议条款为准。</p>
			<p class="nrjs">2.任何一方拒绝履行本协议项下义务，或在违反本协议约定且在能够纠正的情况下，未能于3个工作日内履行义务或纠正违约行为，则守约方可要求违约方继续履行协议或立即予以补救。违约方在上述限定时间内仍拒绝履行或未采取有效补救措施的，守约方有权解除本协议，且本协议的解除不影响守约方要求违约方承担违约责任。</p>
			<p class="nrjs">第十三条 适用法律和争议解决</p>
			<p class="nrjs">1.本协议订立、履行、解释及争议解决均在<span class="color">中才智慧服务</span>平台规则基础上理解，并适用中华人民共和国（不包括香港、澳门、台湾地区） 法律。</p>
			<p class="nrjs">2.双方约定，如果就本协议发生争议，双方一致同意在四川中才智慧信息技术服务有限公司（我司）所在地法院进行诉讼解决。</p>
			<p class="nrjs">第十四条 其他</p>
			<p class="nrjs">1.本协议自下列两种情形之任意一种最先发生之日起</p>
			<p class="nrjs">生效：各方均签署本协议时，或职业者在<span class="color">中才智慧服务</span>平台完成职业者用户注册时。</p>
			<p class="nrjs">签署声明：各方充分知悉且已理解本协议全部内容，各方均保证下列之签名者和/或网络流程操作者已获有效授权井足以代表各方订立本协议</p>
			<p class="nrjs"></p>
			<p class="nrjs"></p>
			<p class="nrjs"></p>
			<p class="nrjs"></p>
			<p class="nrjs"></p>
	</div>
</template>

<script>
	export default {
		data() {
			return {
			}
		},
		onLoad(option) {},
		methods: {
			
		}
	}
</script>

<style scoped>
	.color{
		color: rgb(3, 1, 199);
	}
	.nrjs{
		font-size: 13px;
		padding: 0px 0px 5px 0px;
	}
	.fdbt{
		font-weight: 900;
		padding: 0px 0px 5px 0px;
	}
	.nrbt{
		font-size: 12px;
		padding: 0px 0px 5px 0px;
	}
	.xbt{
		font-size: 14px;
		font-weight: 900;
		padding: 0px 0px 5px 0px;
	}
	.js{
		font-size: 13px;
		padding: 0px 0px 5px 0px;
	}
	.sd{
		font-size: 13px;
		text-indent: 2em;
		padding: 0px 0px 5px 0px;
	}
	.page {
		background-color: white;
		height: 100%;
		font-size: 34rpx;
		padding: 0px 10px;
		text-align: left;
		color: black;
	}

	.blank-column {
		height: 20rpx;
	}

	.title{
		margin: 0px 20px;
		text-align: center;
	}

</style>
