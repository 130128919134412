<template>
  <div id="loanProcessingList">
    <el-form :inline="true" :model="formInline">
      <el-row>
        <el-col :span="7" align="left">
          <el-form-item label="发票代码:">
            <el-input
              v-model="formInline.stateInvoiceNo"
              placeholder="请输入查询"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7" align="left">
          <el-form-item label="所属供应商:">
            <el-input
              v-model="formInline.riskName"
              placeholder="请输入查询"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7" align="left">
          <el-form-item label="发票状态:">
            <el-select
              v-model="formInline.invoiceState"
              placeholder="请选择"
              class="el-select,select"
              clearable
            >
              <el-option
                v-for="item in dictionList"
                :key="item.id"
                :label="item.name"
                :value="item.datavalue"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12" align="left">
          <el-form-item label="应付时间:">
            <el-date-picker
              v-model="formInline.paymentDateStart"
              type="date"
              placeholder="起"
              editable
              pattern="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
            ></el-date-picker
            >-
            <el-date-picker
              type="date"
              v-model="formInline.paymentDateEnd"
              placeholder="止"
              editable
              pattern="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="4" :offset="3">
          <el-form-item id="anniu">
            <el-button icon="el-icon-search" type="primary" @click="submit"
              >查询</el-button
            >
            <el-button
              icon="el-icon-refresh-right"
              type="primary"
              @click="reset"
              >重置</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10" id="anniu" :pull="2">
          <div class="padding_bottom_class">
            <!-- <el-button icon="el-icon-download" type="primary">导入</el-button> -->
            <!-- <el-button icon="el-icon-upload2" type="primary"
              ><a href="http://localhost:8100/invoice/downloadsExcelDownInvoice?pageNo+"+this.getPageNo+"&pageSize">导出</a></el-button
            > -->
            <el-button
              icon="el-icon-upload2"
              type="primary"
              @click="downloadsExcelDownInvoice()"
              v-if="funArr.indexOf('导出') !== -1"
              >导出</el-button
            >
            <el-button
              icon="el-icon-upload2"
              type="primary"
              style="width: 100px"
              @click="bulkPayment()"
              v-if="funArr.indexOf('批量支付') !== -1"
              >批量支付</el-button
            >
          </div>
        </el-col>
      </el-row>
    </el-form>
    <!-- =================================批量支付弹窗 -->
    <el-dialog
      class="dialogBox"
      :close-on-click-modal="false"
      :title="'批量支付:' + invoiceNumber + '张'"
      :show-close="true"
      :visible.sync="dialogVisibleBulkPayment"
      :destroy-on-close="true"
    >
      <bulk-payment
        :bulkPayment="payOBjectList"
        ref="payment"
        v-if="dialogVisibleBulkPayment"
      ></bulk-payment>
      <span id="anniu" slot="footer">
        <el-button type="success" @click="SMSofflinepayment1()"
          >已线下支付</el-button
        >
        <el-button @click="dialogVisibleBulkPayment = false">取 消</el-button>
        <el-button type="primary" :loading="batchPay" @click="verifySMS()"
          >确认</el-button
        >
      </span>
    </el-dialog>
    <!-- =================================批量支付弹窗end -->
    <!-- =================================额度使用详情弹窗 -->
    <el-dialog
      class="dialogBox"
      :close-on-click-modal="false"
      title="额度使用详情"
      :show-close="true"
      :visible.sync="dialogVisiblequovalue"
    >
      <quo-detail
        :quo="proofId"
        ref="quovalue"
        v-if="dialogVisiblequovalue"
      ></quo-detail>
      <span id="anniu" slot="footer" class="dialog-footer">
        <el-button @click="cancelQuotaDetail()">关闭</el-button>
      </span>
    </el-dialog>
    <!-- =================================额度使用详情弹窗end -->
    <!-- =================================修改弹窗 -->
    <el-dialog
      class="dialogBox"
      :close-on-click-modal="false"
      title="发票修改"
      :show-close="true"
      :visible.sync="dialogVisibleUpdateInvoice"
    >
      <update-invoice
        :updateInvoice="updatePage"
        ref="updateInvoice"
        v-if="dialogVisibleUpdateInvoice"
      ></update-invoice>
      <span id="anniu" slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleUpdateInvoice = false">取 消</el-button>
        <el-button
          type="primary"
          @click="updateInvoiceSuccessBottom()"
          :loading="buttonFlag"
          >确认</el-button
        >
      </span>
    </el-dialog>
    <!-- =================================修改弹窗end -->
    <!-- =================================支付弹窗 -->
    <el-dialog
      class="dialogBox"
      :close-on-click-modal="false"
      title="发票支付"
      :show-close="true"
      :visible.sync="dialogVisiblepayment"
    >
      <payment
        :payment="payment1"
        ref="payment"
        v-if="dialogVisiblepayment"
      ></payment>
      <span id="anniu" slot="footer" class="dialog-footer">
        <el-button @click="dialogVisiblepayment = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="onlyOnlinePay"
          @click="SMSpayDialogButtom()"
          >确认</el-button
        >
      </span>
    </el-dialog>
    <!-- =================================支付弹窗end -->
    <!-- =================================线下支付带验证码弹窗 -->
    <el-dialog
      class="dialogBox"
      title="线下支付"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="dialogVisibleofflinePaymentSMS"
    >
      <div>
        <el-form label-width="150px">
          <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
                <el-form-item label="户名:">
                  <el-input disabled v-model="dialogSMS.name"></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"><div class="main_class"></div></el-col>
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
                <el-form-item label="电子账户:">
                  <el-input disabled v-model="dialogSMS.bankNum"></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"><div class="main_class"></div></el-col>
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
                <el-form-item label="开户行:">
                  <el-input disabled v-model="dialogSMS.branchName"></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"><div class="main_class"></div></el-col>
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
                <el-form-item label="可用余额:">
                  <el-input disabled v-model="dialogSMS.useamt"></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"><div class="main_class"></div></el-col>
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
                <el-form-item label="本次支付金额:">
                  <el-input v-model="amountAD"></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"><div class="main_class"></div></el-col>
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
                <el-form-item label="支付通道:">
                  <el-select placeholder="请选择" v-model="channelType">
                    <el-option v-for="channelType in channelTypes"  :label="channelType.name" :value="channelType.datavalue" :key="channelType.datavaluex"/>
                  </el-select>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"/>
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :span="12"
              ><div>
                <el-form-item label="手机号:">
                  <el-input v-model="dialogSMS.mobile"></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"></el-col>
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :span="12"
              ><div>
                <el-form-item label="验证码:">
                  <el-input v-model="dialogSMS.verificationCode"></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"
              ><div class="main_class">
                <div class="main_buttom_class">
                  <div>
                    <el-button type="primary" @click="sendSms2">{{
                      dialogSMS.timeStr
                    }}</el-button>
                  </div>
                </div>
              </div></el-col
            >
          </el-row>
        </el-form>
      </div>
      <!-- <div class="offlinePayment_text_class">是否确定该发票线下已支付？</div> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleofflinePaymentSMS = false"
          >取 消</el-button
        >
        <el-button type="success" @click="verifySMS2()" :loading="onlyPayFLag"
          >确定已支付</el-button
        >
      </span>
    </el-dialog>
    <!-- =================================线下支付带验证码弹窗end -->
    <!-- =================================线下支付弹窗 -->
    <el-dialog
      class="dialogBox"
      title="线下支付"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="dialogVisibleofflinePayment"
      width="30%"
    >
      <div class="offlinePayment_text_class">是否确定该发票线下已支付？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleofflinePayment = false"
          >取 消</el-button
        >
        <el-button
          type="success"
          @click="offlinePaymentOnly()"
          :loading="onlyPayFLag"
          >确定已支付</el-button
        >
      </span>
    </el-dialog>
    <!-- =================================线下支付弹窗end -->
    <template>
      <el-table
        :data="list"
        id="table"
        :header-cell-style="tableHeader"
        :cell-style="tableCell"
        border
        ref="multipleTable"
        v-loading="loading"
        @selection-change="handleSelectionChange"
        row-key="proofId"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
          :reserve-selection="true"
        >
          <!-- :selectable="selectInit" -->
        </el-table-column>
        <el-table-column prop="sss" label="序号" align="center">
          <template slot-scope="scope">
            <span>{{
              scope.$index + 1 + (page.pageNo - 1) * page.pageSize
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="proofId"
          label="proofId"
          :show-overflow-tooltip="true"
          align="center"
          v-if="show"
        ></el-table-column>
        <el-table-column
          prop="invoiceSurplusAmount"
          label="发票剩余金额"
          :show-overflow-tooltip="true"
          align="center"
          v-if="show"
        ></el-table-column>
        <el-table-column
          prop="stateInvoiceNo"
          label="发票代码"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="stateInvoiceSerialNo"
          label="发票号码"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="amountWithTax"
          label="发票含税金额"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.amountWithTax / 100 }}
          </template>
        </el-table-column>
        <el-table-column
          prop="taxRate"
          label="发票税率"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.taxRate / 10000 }}%
          </template>
        </el-table-column>
        <el-table-column
          prop="amountNotWithTax"
          label="发票不含税金额"
          :show-overflow-tooltip="true"
          align="center"
          v-if="show"
        >
          <template slot-scope="scope">
            {{ scope.row.amountNotWithTax / 100 }}
          </template>
        </el-table-column>
        <el-table-column
          prop="riskName"
          label="所属供应商"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>

        <el-table-column
          prop="creditAmount"
          label="授信额度"
          :show-overflow-tooltip="true"
          align="center"
          v-if="show"
        >
          <template slot-scope="scope">
            {{ scope.row.creditAmount / 100 }}
          </template>
        </el-table-column>
        <el-table-column
          prop="availableCreditAmount"
          label="可用额度"
          :show-overflow-tooltip="true"
          align="center"
          v-if="show"
        >
          <template slot-scope="scope">
            {{ scope.row.availableCreditAmount / 100 }}
          </template>
        </el-table-column>
        <el-table-column
          prop="totalUseAmount"
          label="已使用额度"
          :show-overflow-tooltip="true"
          align="center"
          v-if="show"
        >
          <template slot-scope="scope">
            <a @click="quoDetail(scope.row.proofId)" class="a_class">{{
              scope.row.totalUseAmount / 100
            }}</a>
            <!-- <el-link type="primary" ></el-link> -->
          </template>
        </el-table-column>
        <el-table-column
          prop="creditProofState"
          label="状态"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <div v-for="(item, index) in dictionList" v-bind:key="index">
              <div v-if="item.datavalue == scope.row.creditProofState">
                {{ item.name }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="paidAmount"
          label="已支付金额"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.paidAmount / 100 }}
          </template>
        </el-table-column>
        <el-table-column
          prop="createProofLastRepaymentTime"
          label="支付时间"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="paymentDate"
          label="应付时间"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="proofId"
          label="proofId"
          :show-overflow-tooltip="true"
          align="center"
          v-if="show"
        ></el-table-column>
        <el-table-column
          prop="totalNotPaidAmount"
          label="发票未支付总额"
          :show-overflow-tooltip="true"
          align="center"
          v-if="show"
        ></el-table-column>
        <el-table-column
          prop="taxAmount"
          label="税额"
          :show-overflow-tooltip="true"
          align="center"
          v-if="show"
        ></el-table-column>
        <el-table-column label="操作" width="200" align="center">
          <template slot-scope="scope">
            <el-link
              type="primary"
              icon="el-icon-fetchByIdSysUserList"
              @click="detail(scope.row.proofId)"
              :underline="false"
              size="medium"
              >详情</el-link
            >
            <el-link
              type="primary"
              icon="el-icon-fetchByIdSysUserList"
              @click="updateInvoice(scope.row)"
              :underline="false"
              size="medium"
              v-if="funArr.indexOf('修改') !== -1"
              >修改</el-link
            >
            <span v-if="scope.row.creditProofState != 2">
              <el-link
                type="primary"
                icon="el-icon-fetchByIdSysUserList"
                @click="payment(scope.row)"
                :underline="false"
                size="medium"
                v-if="funArr.indexOf('支付') !== -1"
                >支付</el-link
              >
            </span>
            <span v-if="scope.row.creditProofState == 2">
              <el-link
                type="info"
                icon="el-icon-fetchByIdSysUserList"
                @click="payment()"
                :underline="false"
                disabled
                size="medium"
                v-if="funArr.indexOf('支付') !== -1"
                >支付</el-link
              >
            </span>

            <span
              v-if="
                scope.row.totalNotPaidAmount > 0 ||
                scope.row.creditProofState == 2
              "
            >
              <el-link
                type="info"
                icon="el-icon-fetchByIdSysUserList"
                disabled
                :underline="false"
                size="medium"
                v-if="funArr.indexOf('线下支付') !== -1"
                >线下支付</el-link
              >
            </span>
            <span
              v-if="
                scope.row.totalNotPaidAmount == 0 &&
                scope.row.creditProofState != 2
              "
            >
              <el-link
                type="primary"
                icon="el-icon-fetchByIdSysUserList"
                @click="offlinePayment(scope.row)"
                :underline="false"
                size="medium"
                v-if="funArr.indexOf('线下支付') !== -1"
                >线下支付</el-link
              >
            </span>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        id="page"
        background
        @size-change="handleSizeChange"
        @current-change="currentChange"
        :page-sizes="[10, 15, 20]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total"
        :current-page="page.pageNo"
      >
      </el-pagination>
    </template>
  </div>
</template>

<script>
import bulkPayment from "./bulkPayment";
import updateInvoice from "./updateInvoice";
import quoDetail from "./quoDetail";
import payment from "./payment";
import { exportExcel } from "@/service/moudules/postLoanManagementApi";
import { downloadsExcelDownInvoice } from "@/service/moudules/postLoanManagementApi";
import { findLoanTrackingList } from "@/service/moudules/postLoanManagementApi";
import { findDictionariesItemList } from "@/service/moudules/platformApi";
import { offlinePaidInvoiceSession } from "@/service/moudules/postLoanManagementApi";
import { onlinePaidInvoiceSession } from "@/service/moudules/postLoanManagementApi";
import { batchOnlinePaidInvoiceSession } from "@/service/moudules/postLoanManagementApi";
import platformConstant from "@/util/PlatformConstant.js";
// import UpdateInvoice from "./updateInvoice.vue";
// import BulkPayment from "./bulkPayment.vue";
export default {
  data() {
    return {
      funArr: [],
      payObjectListNow: [],
      channelType:'',
      buttonFlag: false,
      formInline: {
        stateInvoiceNo: "",
        riskName: "",
        paymentDateStart: "",
        paymentDateEnd: "",
        invoiceState: "",
      },
      list: [],
      lists: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      show: false,

      //要传到额度使用详情的proofId
      proofId: "",
      dialogVisiblequovalue: false,
      dialogVisibleofflinePayment: false,
      dialogVisibleofflinePaymentSMS: false,
      dialogVisiblepayment: false,
      dialogVisibleUpdateInvoice: false,
      dialogVisibleBulkPayment: false,
      diction: {
        code: "creditProofState",
      },
      channelDiction:{
        code:"channel"
      },
      dictionList: [],
      channelTypes:[],
      //修改弹窗参数
      updatePage: {
        proofIdUpdate: "",
        payDate: "",
      },
      //批量支付
      invoiceNumber: "",
      loading: true,
      totalNotPaidAmountPay: {
        //发票不含税金额总额
        amountExcludingTax: 0,
        //发票剩余金额总额
        invoiceSurplusAmount: 0,
        proofId: [],
        //验证参数
        mobile: "15816121608",
        verificationCode: "",
        name: "",
        bankNum: "",
        amuont: "",
        branchName: "",
        useamt: "",
      },
      //不含税金额数组
      amountNotWithTaxList: [],
      //发票剩余金额总额数组
      invoiceSurplusAmountList: [],
      proofIdList: [],
      //线下支付单个参数
      onlyProofId: [],
      proofIdTrue: [],
      onlyAmount: "",
      onlyOnlinePay: false,
      onlyPayFLag: false,
      batchPay: false,
      amountTrue: "",
      //当前选中的税额
      taxAmount: "",
      //跳转支付参数
      payment1: {
        //发票含税金额
        amountWithTax: "",
        //发票税率
        taxRate: "",
        //发票不含税金额
        amountNotWithTax: "",
        //授信额度
        creditAmount: "",
        //发票已使用额度
        totalUseAmount: "",
        //发票剩余额度
        invoiceSurplusAmount: "",
        //发票未支付总额
        totalNotPaidAmount: "",
        //税额
        taxAmount: "",
        //可用额度
        availableCreditAmount: "",
        //已支付金额
        paidAmount: "",
        proofId: "",
        offlinePaymentAmount: "",
        //对象数组
        payOBjectList: [],
      },
      verificationCodeFlag: false,
      dialogSMS: {
        mobile: "15816121608",
        verificationCode: "",
        name: "",
        bankNum: "",
        amuont: "",
        branchName: "",
        useamt: "",
        timeStr: "获取短信验证码",
        //验证码
        verificationCode: "",
      },
      amountAD: "",
    };
  },
  mounted: function () {
    console.log("sessionId" + this.getCookie("sessionId"));
    this.invoiceList();
    this.findDictionariesItemList(this.diction);
    this.findDictionariesEnableItemList(this.channelDiction).then(result=>this.channelTypes = result);
    //获取验证码参数
    this.findAccountBalance("", "");

  },

      created:function(){
	
	this.menuPermission()
},
  methods: {

  async menuPermission() {
		let name = "发票查询";
		let params = {
			id: this.$getCookie("sessionId"),
			menuName: name
		}
		let response = await this.api.platformApi.menuPermission(params);

		console.log(name + "页面功能列表start---")
		console.log(response.data.data)
		console.log(name + "页面功能列表end---")
		this.funArr = response.data.data
		console.log(this.funArr)
  },
    async findDictionariesEnableItemList(param){
      let response = await this.api.platformApi.findDictionariesEnableItemList(param);
      console.log("===数据字典返回结果", response);
      console.log("数据字典集合", response.data.data);
      return response.data.data;
    },
    //确认线下支付多个单个都可(带验证码)
    async offlinePaymentOnly2() {
      this.proofIdTrue = [];

      //单个支付
      this.proofIdTrue = this.onlyProofId;
      this.amountTrue = this.accMul1(this.amountAD, 100);
      this.onlyPayFLag = true;
      var params = {
        invoiceIds: this.proofIdTrue,
        amount: this.amountTrue,
        channel:this.channelType,
        session: this.$getCookie("sessionId"),
      };
      let response = await this.api.postLoanManagementApi.offlinePaidInvoiceSession(
        params
      );
      setTimeout(() => {
        if (response.data.code != 200) {
          this.$message({
            message: response.data.msg,
            type: "warning",
          });
          this.dialogVisibleofflinePayment = false;
          this.dialogVisibleofflinePaymentSMS = false;
          this.invoiceList();
          this.onlyPayFLag = false;
        } else {
          this.$message({
            message: "线下支付成功",
            type: "success",
          });
          this.dialogVisibleofflinePayment = false;
          this.dialogVisibleofflinePaymentSMS = false;
          this.invoiceList();
          this.onlyPayFLag = false;
        }
      }, 3000);
    },
    handleClose() {},
    //线下支付带验证码弹窗
    //短信取消
    cancel() {
      this.messageBox = false;
    },
    //短信确认放款
    confirm() {
      this.verifySMS2();
    },
    //验证短信
    async verifySMS2() {
      if(this.channelType == '' || this.channelType == undefined){
        this.$message.warning("请选择支付通道");
        this.fullscreenLoading = false;
        this.verificationCodeFlag = false;
        return;
      }
      var smsParam = {
        mobile: this.dialogSMS.mobile,
        verificationCode: this.dialogSMS.verificationCode,
      };
      let smsResponse = await this.api.platformApi.authSendSms(smsParam);
      console.log("验证验证码结果", smsResponse.data);
      if (smsResponse.data.code != 200) {
        this.$message.error("验证码错误");
        this.fullscreenLoading = false;
        this.verificationCodeFlag = false;
      } else {
        this.verificationCodeFlag = true;
        this.$message.success("验证码成功");
        this.offlinePaymentOnly2();
      }
    },
    //发送验证码
    async sendSms2() {
      if (
        this.dialogSMS.timeStr !== "获取短信验证码" &&
        this.dialogSMS.timeStr !== "重新获取"
      ) {
        return;
      }
      var params = {
        mobile: this.dialogSMS.mobile,
        smsType: 1,
      };
      console.log("sendsms====", params);
      let response = await this.api.platformApi.sendSms(params);
      console.log("验证码结果:", response);
      if (response.data.code != 200) {
        this.$message.error("验证码发送失败");
        return;
      }
      this.$message.success("验证码已发送");
      this.send_code2();
    },
    send_code2: function () {
      this.show = true;
      var _this = this;
      var i = 60;
      var timer = setInterval(function () {
        i--;
        _this.dialogSMS.timeStr = i + "秒";
        if (i == 0) {
          clearInterval(timer);
          _this.dialogSMS.timeStr = "重新获取";
        }
      }, 1000);
    },
    /**
     *  判断是否可回收
     */
    // selectInit(row, index) {
    //   if (row.totalNotPaidAmount > 0 || row.creditProofState == 2) {
    //     return false; //可勾选
    //   } else {
    //     return true; //不可勾选
    //   }
    // },
    async findAccountBalance() {
       var param = {
        subNo: platformConstant.yzw_subNo,
        cltNm: platformConstant.yzw_cltNm,
        channel: platformConstant.channel
      };
      console.log("查询可用余额参数", this.param);
      let response = await this.api.platformApi.findYzwWallet(param);
      console.log("可用金额返回结果", response.data);

      this.dialogSMS.useamt = response.data.data.findAccountBalance.data.useamt / 100;
      this.dialogSMS.name = response.data.data.result.name;
      this.dialogSMS.bankNum = JSON.parse(response.data.data.result.walletComment).rel_acct_no;
      this.dialogSMS.branchName = response.data.data.bankName;
      this.dialogSMS.mobile = platformConstant.mobile;

    },
    async goInvoiceButtom() {
      this.proofIdTrue = [];
      //批量支付
      this.proofIdTrue = this.totalNotPaidAmountPay.proofId;
      this.amountTrue = this.accMul1(
        this.$refs.payment.invoiceSurplusAmount,
        100
      );

      var params = {
        invoiceIds: this.proofIdTrue,
        amount: this.amountTrue,
        session: this.$getCookie("sessionId"),
      };
      let response = await this.api.postLoanManagementApi.batchOnlinePaidInvoiceSession(
        params
      );
      setTimeout(() => {
        if (response.data.code != 200) {
          this.$message({
            message: response.data.msg,
            type: "warning",
          });
          this.dialogVisibleBulkPayment = false;
          this.invoiceList();
          this.batchPay = false;
        } else {
          this.$message({
            message: "批量支付成功",
            type: "success",
          });
          this.dialogVisibleBulkPayment = false;
          this.invoiceList();
          this.batchPay = false;
        }
      }, 3000);
    },
    //验证短信批量线下支付
    async SMSofflinepayment1() {
      var smsParam = {
        mobile: this.$refs.payment.mobile,
        verificationCode: this.$refs.payment.verificationCode,
      };
      let smsResponse = await this.api.platformApi.authSendSms(smsParam);
      console.log("验证验证码结果", smsResponse.data);
      if (smsResponse.data.code != 200) {
        this.$message.error("验证码错误");
        this.fullscreenLoading = false;
        this.verificationCodeFlag = false;
      } else {
        this.verificationCodeFlag = true;
        this.$message.success("验证码成功");
        this.offlinepayment1();
      }
    },
    //验证短信批量支付
    async verifySMS() {
      var smsParam = {
        mobile: this.$refs.payment.mobile,
        verificationCode: this.$refs.payment.verificationCode,
      };
      let smsResponse = await this.api.platformApi.authSendSms(smsParam);
      console.log("验证验证码结果", smsResponse.data);
      if (smsResponse.data.code != 200) {
        this.$message.error("验证码错误");
        this.fullscreenLoading = false;
        this.verificationCodeFlag = false;
      } else {
        this.verificationCodeFlag = true;
        this.$message.success("验证码成功");
        this.batchOnlinePaidInvoiceButtom();
      }
    },
    //批量支付确认按钮
    async batchOnlinePaidInvoiceButtom() {
      this.batchPay = true;
      this.proofIdTrue = [];
      //批量支付
      // this.proofIdTrue = this.totalNotPaidAmountPay.proofId;
      // this.amountTrue = this.accMul1(
      //   this.$refs.payment.invoiceSurplusAmount,
      //   100
      // );
      var aflag = true;
      for (var i = 0; i < this.$refs.payment.payOBjectList1.length; i++) {
        this.$refs.payment.payOBjectList1[i].paidAmount =
          this.$refs.payment.payOBjectList1[i].amount * 100;
        if (this.$refs.payment.payOBjectList1[i].paidAmount == 0) {
          aflag = false;
        }
      }
      if (aflag == false) {
        this.$message({
          message: "支付金额不能为0",
          type: "warning",
        });
        this.batchPay = false;
      }else if(this.$refs.payment.channelType == '' || this.$refs.payment.channelType == undefined){
        this.$message({
          message: "请选择支付通道",
          type: "warning",
        });
        this.batchPay = false;
      }else {
        var params = {
          paidInvoiceParams: this.$refs.payment.payOBjectList1,
          channel:this.$refs.payment.channelType,
          sessionId: this.$getCookie("sessionId"),
        };
        let response = await this.api.postLoanManagementApi.batchOnlinePaidInvoiceOperationSession(
          params
        );
        setTimeout(() => {
          if (response.data.code != 200) {
            this.$message({
              message: response.data.msg,
              type: "warning",
            });
            this.dialogVisibleBulkPayment = false;
            this.invoiceList();
            this.batchPay = false;
          } else {
            this.$message({
              message: "批量支付成功",
              type: "success",
            });
            this.dialogVisibleBulkPayment = false;
            this.$refs.multipleTable.clearSelection();
            this.invoiceList();
            this.batchPay = false;
          }
        }, 3000);
      }
    },

    accMul1(arg1, arg2) {
      var m = 0,
        s1 = arg1.toString(),
        s2 = arg2.toString();
      try {
        m += s1.split(".")[1].length;
      } catch (e) {}
      try {
        m += s2.split(".")[1].length;
      } catch (e) {}
      return (
        (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
        Math.pow(10, m)
      );
    },
    accMul(arg1, arg2) {
      var m = 0,
        s1 = arg1.toString(),
        s2 = arg2.toString();
      try {
        m += s1.split(".")[1].length;
      } catch (e) {}
      try {
        m += s2.split(".")[1].length;
      } catch (e) {}
      return (
        (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
        Math.pow(10, m)
      );
    },
    //单个支付确认按钮
    async SMSpayDialogButtom() {
      if(this.$refs.payment.channelType == '' || this.$refs.payment.channelType == undefined){
        this.$message.warning("请选择支付通道");
        this.fullscreenLoading = false;
        this.verificationCodeFlag = false;
        return;
      }
      var smsParam = {
        mobile: this.$refs.payment.mobile,
        verificationCode: this.$refs.payment.verificationCode,
      };
      let smsResponse = await this.api.platformApi.authSendSms(smsParam);
      console.log("验证验证码结果", smsResponse.data);
      if (smsResponse.data.code != 200) {
        this.$message.error("验证码错误");
        this.fullscreenLoading = false;
        this.verificationCodeFlag = false;
      } else {
        this.verificationCodeFlag = true;
        this.channelType = this.$refs.payment.channelType;
        this.$message.success("验证码成功");
        this.payDialogButtom();
      }
    },
    async payDialogButtom() {
      // if (this.$refs.payment.show == false) {
      //   this.$message({
      //     message: "请先点击结清剩余余额再支付",
      //     type: "warning",
      //   });
      //   return;
      // }
      // this.accMul(this.$refs.payment.amount,100)
      this.onlyOnlinePay = true;
      var params = {
        invoiceId: this.$refs.payment.paymentDia.proofId,
        amount: this.accMul(this.$refs.payment.amount, 100),
        channel:this.channelType,
        session: this.$getCookie("sessionId"),
      };
      let response = await this.api.postLoanManagementApi.onlinePaidInvoiceSession(
        params
      );
      setTimeout(() => {
        if (response.data.code != 200) {
          this.$message({
            message: response.data.msg,
            type: "warning",
          });
          this.dialogVisiblepayment = false;
          this.invoiceList();
          this.onlyOnlinePay = false;
        } else {
          this.$message({
            message: "支付成功",
            type: "success",
          });
          this.dialogVisiblepayment = false;
          this.invoiceList();
          this.onlyOnlinePay = false;
        }
      }, 3000);
    },
    //确认线下支付多个单个都可
    async offlinePaymentOnly() {
      var aflag = true;console.log(this)
      for (var i = 0; i < this.payObjectListNow.length; i++) {
        this.payObjectListNow[i].paidAmount =
          this.payObjectListNow[i].amount * 100;
        if (this.payObjectListNow[i].paidAmount == 0) {
          aflag = false;
        }
      }
      if (aflag == false) {
        this.$message({
          message: "支付金额不能为0",
          type: "warning",
        });
        this.batchPay = false;
      }else if(this.channelType == '' || this.channelType == undefined){
        this.$message({
          message: "请选择支付通道",
          type: "warning",
        });
        this.batchPay = false;
      }else {
        this.onlyPayFLag = true;
        var params = {
          paidInvoiceParams: this.payObjectListNow,
          channel:this.channelType,
          sessionId: this.$getCookie("sessionId"),
        };
        let response = await this.api.postLoanManagementApi.batchOfflinePaidInvoiceOperationSession(
          params
        );
        setTimeout(() => {
          if (response.data.code != 200) {
            this.$message({
              message: response.data.msg,
              type: "warning",
            });
            this.dialogVisibleofflinePayment = false;
            this.dialogVisibleofflinePaymentSMS = false;
            this.invoiceList();
            this.onlyPayFLag = false;
          } else {
            this.$message({
              message: "线下支付成功",
              type: "success",
            });
            this.dialogVisibleofflinePayment = false;
            this.dialogVisibleofflinePaymentSMS = false;
            this.$refs.multipleTable.clearSelection();
            this.invoiceList();
            this.onlyPayFLag = false;
          }
        }, 3000);
      }
    },
    //多选框
    handleSelectionChange(val) {
      //这个val是所有已选中的json的数组
      this.multipleSelection = val.proofId;
    },
    //修改发票确认
    updateInvoiceSuccessBottom() {
      this.$refs.updateInvoice.submitForm();

      this.buttonFlag = true;

      setTimeout(() => {
        this.dialogVisibleUpdateInvoice = false;
        this.buttonFlag = false;
        this.loading = true;
        // alert("222")
        this.invoiceList();
      }, 3000);
      this.loading = false;
    },
    //批量支付里的线下支付
    offlinepayment1() {
      // if (this.$refs.payment.invoiceSurplusAmount != null) {
      //   this.offlinePaymentAmount = this.accMul1(
      //     this.$refs.payment.invoiceSurplusAmount,
      //     100
      //   );
      // }
      var aflag = true;
      for (var i = 0; i < this.$refs.payment.payOBjectList1.length; i++) {
        if (this.$refs.payment.payOBjectList1.paidAmount == 0) {
          aflag = false;
        }
      }
      if (aflag == false) {
        this.$message({
          message: "支付金额不能为0",
          type: "warning",
        });
        return;
      }else if(this.$refs.payment.channelType == '' || this.$refs.payment.channelType == undefined){
        this.$message({
          message: "请选择支付通道",
          type: "warning",
        });
        this.batchPay = false;
      } else {
        this.payObjectListNow = this.$refs.payment.payOBjectList1,
        this.channelType = this.$refs.payment.channelType;
        this.dialogVisibleBulkPayment = false;
        this.dialogVisibleofflinePayment = true;
      }
    },
    //批量支付弹窗
    bulkPayment() {
      this.payOBjectList = [];
      this.onlyProofId = [];
      this.proofIdList.length = 0;
      this.totalNotPaidAmountPay.proofId.length = 0;
      //发票不含税金额
      this.totalNotPaidAmountPay.amountExcludingTax = 0;
      this.amountNotWithTaxList.length = 0;
      //发票剩余金额总额
      this.totalNotPaidAmountPay.invoiceSurplusAmount = 0;
      this.invoiceSurplusAmountList.length = 0;
      console.log(this.$refs.multipleTable.selection);
      // alert(this.$refs.multipleTable.selection.length)
      this.invoiceNumber = this.$refs.multipleTable.selection.length;
      if (this.invoiceNumber == 0) {
        this.$message({
          message: "请勾选发票再批量支付",
          type: "error",
        });
        return;
      }
      console.log("====================循环");
      for (var i = 0; i < this.$refs.multipleTable.selection.length; i++) {
        // console.log(this.$refs.multipleTable.selection[i].proofId)
        this.payOBjectList[i] = this.$refs.multipleTable.selection[i];
        console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
        console.log(this.payOBjectList[i]);
        this.proofIdList[i] = this.$refs.multipleTable.selection[i].proofId;
        this.totalNotPaidAmountPay.proofId[
          i
        ] = this.$refs.multipleTable.selection[i].proofId;
        // this.onlyProofId[i] = this.$refs.multipleTable.selection[i].proofId;
        this.amountNotWithTaxList[i] = this.$refs.multipleTable.selection[
          i
        ].amountNotWithTax;
        this.invoiceSurplusAmountList[i] =
          this.$refs.multipleTable.selection[i].amountWithTax -
          this.$refs.multipleTable.selection[i].paidAmount;

        // alert(this.proofIdList[i])
      }

      //循环获取到的发票不含税金额数组并求和
      for (var i = 0; i < this.amountNotWithTaxList.length; i++) {
        this.totalNotPaidAmountPay.amountExcludingTax += this.amountNotWithTaxList[
          i
        ];
      }
      //循环获取发票剩余金额总额数组并求和
      for (var i = 0; i < this.invoiceSurplusAmountList.length; i++) {
        this.totalNotPaidAmountPay.invoiceSurplusAmount += this.invoiceSurplusAmountList[
          i
        ];
      }
      // this.totalNotPaidAmount = this.totalNotPaidAmount / 100;

      this.dialogVisibleBulkPayment = true;
      console.log("4454545555555555555555");
      console.log(this.payOBjectList);
    },
    //修改弹窗
    updateInvoice(data) {
      this.updatePage.proofIdUpdate = data.proofId;
      this.updatePage.payDate = data.paymentDate;
      this.dialogVisibleUpdateInvoice = true;
    },
    //支付弹窗
    payment(data) {
      this.payment1.amountWithTax = data.amountWithTax;
      this.payment1.taxRate = data.taxRate;
      this.payment1.amountNotWithTax = data.amountNotWithTax;
      this.payment1.creditAmount = data.creditAmount;
      this.payment1.totalUseAmount = data.totalUseAmount;
      this.payment1.invoiceSurplusAmount = data.invoiceSurplusAmount;
      this.payment1.totalNotPaidAmount = data.totalNotPaidAmount;
      this.payment1.taxAmount = data.taxAmount;
      this.payment1.proofId = data.proofId;
      this.payment1.availableCreditAmount = data.availableCreditAmount;
      this.payment1.paidAmount = data.paidAmount;
      this.dialogVisiblepayment = true;
    },
    //线下支付弹窗
    offlinePayment(row) {
      this.onlyProofId = [];
      this.onlyProofId[0] = row.proofId;
      this.onlyAmount = row.amountWithTax;
      this.taxAmount = row.paidAmount;
      this.dialogSMS.amount = this.onlyAmount - this.taxAmount;
      this.amountAD = this.dialogSMS.amount / 100;
      this.dialogSMS.mobile = "15816121608";
      this.dialogSMS.verificationCode = "";
      this.dialogVisibleofflinePaymentSMS = true;
    },
    //关闭已使用额度弹窗
    cancelQuotaDetail() {
      this.dialogVisiblequovalue = false;
      this.invoiceList();
    },
    //已使用额度弹窗
    quoDetail(data) {
      this.proofId = data;
      console.log("获取到额度弹窗的proofId：" + this.proofId);
      this.dialogVisiblequovalue = true;
    },
    reset() {
      this.formInline.stateInvoiceNo = "";
      this.formInline.riskName = "";
      this.formInline.paymentDateStart = "";
      this.formInline.paymentDateEnd = "";
      this.formInline.invoiceState = "";
    },
    getCookie: function (cname) {
      let name = cname + "=";
      let ca = document.cookie.split(";");
      // console.log("获取cookie,现在循环")
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        // console.log(c)
        while (c.charAt(0) === " ") c = c.substring(1);
        if (c.indexOf(name) !== -1) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0";
    },
    submit() {
      this.page.pageNo = 1;
      setTimeout(() => {
        this.loading = true;
        this.invoiceList();
      }, 500);
      this.loading = false;
      console.log("submit!");
    },
    downloadsExcelDownInvoice() {
      let _this = this;

      let param = {
        minPaymentDate: this.formInline.paymentDateStart,
        maxPaymentDate: this.formInline.paymentDateEnd,
        riskName: this.formInline.riskName,
        stateInvoiceNo: this.formInline.stateInvoiceNo,
        // pageSize: this.page.pageSize,
        // pageNo: this.page.pageNo,
        creditProofState: this.formInline.invoiceState,
      };

      console.log(param);
      let response = this.api.postLoanManagementApi.downloadsExcelDownInvoice(
        param
      );
      console.log(response);
      // window.location = response;
    },
    async findDictionariesItemList(param) {
      this.loading = true;
      let response = await this.api.platformApi.findDictionariesItemList(param);
      console.log("数据字典返回结果", response);
      this.dictionList = response.data.data;
      console.log("数据字典集合", this.dictionList);
    },
    async invoiceList() {
      console.log("formInline" + this.formInline);
      var params = {
        minPaymentDate: this.formInline.paymentDateStart,
        maxPaymentDate: this.formInline.paymentDateEnd,
        riskName: this.formInline.riskName,
        stateInvoiceNo: this.formInline.stateInvoiceNo,
        pageSize: this.page.pageSize,
        pageNo: this.page.pageNo,
        creditProofState: this.formInline.invoiceState,
      };
      let response = await this.api.postLoanManagementApi.findInvoiceDetailsPageInfo(
        params
      );
     // console.log("==========", JSON.stringify(response.data));
      this.list = response.data.data.records;
      // console.log(JSON.stringify(this.list));
      console.log("total" + response.data.data.total);
      this.page.total = response.data.data.total;
      this.loading = false;
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      setTimeout(() => {
        this.invoiceList();
      }, 500);
      // this.invoiceList();
      this.loading = false;
      // this.invoiceList();
    },
    currentChange(pageNo) {
      this.page.pageNo = pageNo;
      this.loading = true;
      this.invoiceList();
    },
    async detail(row) {
      console.log("=====row====" + row);

      localStorage.setItem("proofId", row);

      console.log("=====id=====", localStorage.getItem("proofId"));
      this.$router.push({ name: "invoiceDetails" });
    },
  },
  components: {
    quoDetail,
    payment,
    updateInvoice,
    bulkPayment,
  },
};
</script>

<style scoped>
/* /deep/ .el-input>.el-input__inner{
    height: 32px;
} */
.main_buttom_class {
  padding-left: 10px;
}
.right {
  float: right;
}
#table {
  width: 100%;
  background-color: rgba(29, 144, 255, 0);
}
#loanProcessingList .marginCss {
  margin-top: 20px;
}
#loanProcessingList .grid-anniu button {
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
  margin: 10px;
}
#anniu {
  border: 0;
}

#anniu button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
#page {
  float: right;
  margin-top: 15px;
}
#loanProcessingList {
  overflow: hidden;
  background-color: white;
  padding: 15px 20px 100px;
}
.input {
  height: 40px;
  width: 210px;
}
.select {
  height: 40px;
  width: 252px;
}
.left {
  text-align: left;
}
.fixed {
  width: 1600px;
  height: 100%;
}
.a_class {
  color: dodgerblue;
  text-decoration: underline;
  cursor: pointer;
}
.dialogBox {
  text-align: left;
}
#anniu {
  border: 0;
}

#anniu button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
>>> .el-dialog__body {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgb(228, 228, 228, 1);
  padding-left: 0;
  padding-right: 0;
}
.padding_bottom_class {
  padding-bottom: 15px;
}
.offlinePayment_text_class {
  text-align: center;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}
.dialog1_class {
  margin-right: 50px;
}
</style>