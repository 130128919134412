<template>
  <el-dialog title="新增模板" :visible.sync="dialogFormVisible" width="640px">
    <el-form :model="form">
      <el-form-item
        label="选择客户"
        :label-width="formLabelWidth"
        :required="true"
      >
      <el-select v-model="form.myTenantId" clearable style="width: 100%;">
        <el-option v-for="elem of tenantList" :key="elem.id" :value="elem.id" :label="elem.name"></el-option>
      </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      dialogFormVisible: false,
      form: {
        id:'',
        myTenantId:''
      },
      formLabelWidth: '120px',
      tenantList:[]
    }
  },
  created(){
    this.getTenant()
  },
  methods: {
    getTenant(){
      this.api.salaryApi.finTenants().then(res=>{
          this.tenantList = res.data
      })
    },
    open(id) {
      this.form.myTenantId = ''
      this.form.id = id
      this.dialogFormVisible = true
    },
    submit() {
      if (!this.form.myTenantId) {
        this.$message.error('请选择客户')
        return
      }
      const data = {
        ...this.form,
      }
      this.api.contractApi.copyTemplate(data).then((res) => {
        if (res.data.code === 200) {
          this.$message.success('复制模板成功')
          this.dialogFormVisible = false
          this.$emit('success', res.data.data)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
  },
}
</script>
<style scoped>
.upload-demo {
  text-align: left;
}
</style>

