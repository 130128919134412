<template>
  <div id="sysRoleList">
    <el-row>
      <el-col id="add" :span="11">
        <el-row style="text-align: left">
          <el-button
            id="anniu"
            type="primary"
            icon="el-icon-plus"
            @click="addrole()"
      v-permissions="'添加角色'"
            >添加角色
          </el-button>
          <el-dialog
            title="添加角色"
            :show-close="true"
            :close-on-click-modal="false"
            :visible.sync="dialogVisibleadd"
          >
            <add-role
              :addrole="1"
              :total="total"
              ref="role"
              @closeDialog="cancel"
            ></add-role>
            <span slot="footer" class="dialog-footer">
              <el-button type="success" @click="cancel()">取消</el-button>
              <el-button type="primary" @click="add">添加</el-button>
            </span>
          </el-dialog>
        </el-row>

        <el-row>
          <div id="bd" style="text-align: left">
            <img src="@/static/system/u977.svg" />
            <span style="color: #565c5f">当前选择:</span>
            <span id="select">{{ currentSelection }}</span>
            <a id="navname" v-if="!isHidden" @click="deselect">取消选择 </a>
          </div>
        </el-row>
        <el-table
          @current-change="handleCurrentChange"
          ref="singleTable"
          :highlight-current-row="true"
          @row-click="getDetails"
          :data="list"
          :header-cell-style="tableHeader"
          :cell-style="tableCell"
          border
        >
          <el-table-column
            type="index"
            prop="code"
            label="编号"
            width="50"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{
                scope.$index +
                1 +
                (queryParams.pageNo - 1) * queryParams.pageSize
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="角色名称"
            align="center"
          ></el-table-column>
          <el-table-column prop="gname" label="所属部门" align="center">
            <template slot-scope="scope">
              {{ showGroup(scope.row) }}
            </template>
          </el-table-column>
          <el-table-column
            sortable
            prop="status"
            label="是否启用"
            :formatter="formatRole"
            align="center"
          ></el-table-column>
          <el-table-column
            sortable
            prop="timestamp"
            label="创建时间"
            type="date"
            align="center"
          ></el-table-column>
        </el-table>
        <div id="two">
          <template>
            <el-pagination
              id="page"
              background
              @size-change="handleSizeChange"
              @current-change="pageChange"
              :page-sizes="[10, 15, 20]"
              :page-size="queryParams.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </template>
        </div>
      </el-col>
      <el-col :span="12">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <!-- 基本信息 -->
          <el-tab-pane label="基础信息" :model="userlist" class="showBox">
            <el-row id="msg" type="flex" :class="{ hiddenBox: isHidden }">
              <el-col :xs="24" :sm="16" :md="12">
                <el-form
                  :model="ruleForm"
                  ref="ruleForm"
                  label-width="100px"
                  class="demo-ruleForm"
                >
                  <el-form-item label="角色编码:" prop="code">
                    <el-input v-model="userlist.code" disabled></el-input>
                  </el-form-item>
                  <el-form-item label="角色名称:" prop="name">
                    <el-input v-model="userlist.name"></el-input>
                  </el-form-item>
                  <el-form-item label="所属部门:" prop="gname">
                    <el-input v-model="userlist.gname" disabled></el-input>
                  </el-form-item>
                  <el-form-item label="是否启用:" prop="status">
                    <el-radio-group v-model="userlist.status">
                      <el-radio :label="0">是</el-radio>
                      <el-radio :label="1">否</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="角色描述:" prop="details">
                    <el-input
                      type="textarea"
                      :rows="2"
                      placeholder="请输入内容"
                      v-model="userlist.details"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                    <el-button type="primary" @click="submitForm('ruleForm')"
                    v-permissions="'修改角色'"
                      >修改</el-button
                    >
                  </el-form-item>
                </el-form>
              </el-col>
            </el-row>
          </el-tab-pane>
          <!-- 权限配置 -->

          <el-tab-pane label="权限配置" name="second" class="showBox">
            <permissioninfo
              :roleId="roleid"
              :class="{ hiddenBox: isHidden }"
            ></permissioninfo>
          </el-tab-pane>

          <!-- 用户列表 -->
          <el-tab-pane label="用户列表" name="third" class="showBox">
            <div id="back" :class="{ hiddenBox: isHidden }" style="padding-left:20px;padding-right:20px;">
              <div class="butT">
                <el-button
                  id="bt1"
                  type="primary"
                  icon="el-icon-plus"
                  @click="handleCreate()"
                  >新增用户
                </el-button>
                <el-dialog
                  class="titlebox"
                  :close-on-click-modal="false"
                  title="新增用户"
                  :show-close="true"
                  :visible.sync="dialogVisible"
                >
                  <add-sys-role-list
                    :roleId="userlist.id"
                    :roleName="userlist.name"
                    :parentNode="currentThis"
                    ref="rolevalue"
                  ></add-sys-role-list>
                  <span slot="footer" class="buttonbox">
                    <el-button type="success" @click="cancel()">取消</el-button>
                    <el-button type="primary" @click="insertrole"
                      >添加</el-button
                    >
                  </span>
                </el-dialog>

                <el-button
                  id="bt1"
                  type="primary"
                  icon="el-icon-plus"
                  @click="findByRoleUser()"
            v-permissions="'加入用户'"
                  >加入用户</el-button
                >

                <el-dialog
                  class="titlebox"
                  :close-on-click-modal="true"
                  title="添加已有用户"
                  :show-close="true"
                  :visible.sync="addUser"
                >
                  <sys-user-list
                    v-if="addUser"
                    :roleId="roleid"
                    :parentNode="currentThis"
                    ref="addaRoleUser"
                  ></sys-user-list>
                  <span id="bt2" slot="footer" class="dialog-footer">
                    <el-button @click="cancel()">取消</el-button>
                    <el-button type="primary" @click="addaRoleUser"
                      >确认</el-button
                    >
                  </span>
                </el-dialog>
              </div>

              <el-table
                class="tablebox"
                :data="listuser"
                :header-cell-style="tableHeader"
                :cell-style="tableCell"
                border
                fit
              >
                <el-table-column
                  type="index"
                  prop="code"
                  label="编号"
                  width="50"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span>{{
                      scope.$index +
                      1 +
                      (queryParams.pageNo - 1) * queryParams.pageSize
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="account"
                  label="用户账号"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="name"
                  label="名字"
                  align="center"
                ></el-table-column>
                <el-table-column prop="flag" label="状态" align="center">
                  <template slot-scope="scope">
                    <span v-if="scope.row.flag == 0">已启用</span>
                    <span v-if="scope.row.flag == 1">已停用</span>
                  </template>
                </el-table-column>
                <el-table-column
                  sortable
                  prop="timestamp"
                  label="加入时间"
                  align="center"
                ></el-table-column>
              </el-table>
              <div class="block">
                <el-pagination
                  background
                  @size-change="userListHandleSizeChange"
                  @current-change="userListHandleCurrentChange"
                  :current-page="userListPageNo"
                  :page-sizes="[10, 15, 20]"
                  :page-size="userListPageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="userListTotal"
                >
                </el-pagination>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import addSysRoleList from "./addSysUserList";
import SysUserList from "../role/addUser";
import addRole from "./addSysRoleList";
import permissioninfo from "../../../components/Permission";

export default {
  data() {
    return {
      currentThis : this,
      activeName: "second",
      dialogVisibleadd: false,
      dialogVisible: false,
      currentSelection: "",
      activeName: "second",
      groupMap: {},
      userlist: {
        id: "",
        name: "",
        status: "",
        details: "",
      },

      formInline: {
        id: "",
        code: "",
        name: "",
        status: "",
        timestamp: "",
      },
      total: 0,
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        role: "",
      },
      ruleForm: {
        id: "",
        name: "",
        code: "",
        status: "",
        details: "",
        timestamp: "",
        account: "",
        gender: "",
        mobile: "",
        gname: "",
        rname: "",
      },
      isHidden: true,
      userListPageSize: 10,
      userListPageNo: 1,
      userListTotal: 0,
      activeName: "",
      list: [],
      listuser: [],
      roleid: "",
      role: "",
      addUser: false,
	  funArr: [],
    };
  },
 
  computed: {
    showGroup() {
      return function (data) {
        // console.log(data)
        let str = ""; //创建要显示的字符串
        //如果存在，遍历每一个父级部门
        if (data.parentGroup) {
          for (var index = 0; index < data.parentGroup.length; index++) {
            //通过key-value获得部门名,拼接字符串
            str += this.groupMap[data.parentGroup[index]] + "/";
          }
        }

        if (data.gid) {
          str += this.groupMap[data.gid];
        }
        return str;
      };
    },
  },
  created() {
    this.loadGroupInfo();
	console.log("角色管理")
	
	this.menuPermission()
  },
  mounted: function () {
    this.loadList();
    // this.loadListRole();
  },
  methods: {
	  async menuPermission() {
	  	let name = "角色管理";
	  	let params = {
	  		id: this.$getCookie("sessionId"),
	  		menuName: name
	  	}
	  	let response = await this.api.platformApi.menuPermission(params);
	  
	  	console.log(name + "页面功能列表start---")
	  	console.log(response.data.data)
	  	console.log(name + "页面功能列表end---")
	  	this.funArr = response.data.data
	  	console.log(this.funArr)
	  },
    //加载部门信息
    async loadGroupInfo() {
      let res = await this.api.platformApi.loadGroupInfo();

      if (res.data.code == 200) {
        for (var index in res.data.data) {
          let group = res.data.data[index];
          this.groupMap[group.id] = group.name;
        }
      } else {
        this.$message({
          message: "部门信息加载失败",
          type: "error",
        });
      }
    },
    add() {
      this.$refs.role.submitForm("ruleForm");
    },

    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },
    getDetails(row, data) {
      console.log(row);
      this.userlist.id = row.id;
      this.userlist.code = row.code;
      this.userlist.name = row.name;
      this.userlist.status = row.status;
      this.userlist.details = row.details;
      this.userlist.gname = row.gname;
      this.roleid = row.id;
      this.role = row.name;
      this.currentSelection = row.name;
      this.loadlistuser();
      this.isHidden = false;
    },
    handleCreate(tab, event) {
      this.dialogVisible = true;
    },
    handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.queryParams.pageNo = 1;
      this.loadList();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(val);
    },

    //查询角色列表并分页
    async loadList() {
      var temp = this;
      var params = {
        pageSize: temp.queryParams.pageSize,
        pageNo: temp.queryParams.pageNo,
      };
      await temp.api.platformApi
        .RoleList(params)
        .then(function (response) {
          temp.list = response.data.data.records;
          temp.total = response.data.data.total;
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {});
    },
    //用户列表
    async loadlistuser() {
      var list = this;
      console.log(list.listuser);

      var params = {
        role: this.userlist.id,
        pageNo: this.userListPageNo,
        pageSize: this.userListPageSize,
      };
      await list.api.platformApi
        .RoleUserList(params)
        .then((response) => {
          list.listuser = response.data.data.pageInfo.list;
          list.userListPageNo = response.data.data.pageInfo.pageNum;
          list.userListPageSize = response.data.data.pageInfo.pageSize;
          list.userListTotal = response.data.data.pageInfo.total;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    addaRoleUser() {
      this.$refs.addaRoleUser.addaRoleUser();
      this.addUser = false;
      this.loadlistuser();
      this.$forceUpdate();
    },

    //修改
    async submitForm(formName) {
      await this.$refs[formName].validate((valid) => {
        if (valid) {
          var params = {
            sessionId: this.$getCookie("sessionId"),
            id: this.userlist.id,
            name: this.userlist.name,
            status: this.userlist.status,
            details: this.userlist.details,
          };
          this.updaterole(params);
        } else {
          return false;
        }
      });
    },

    async updaterole(params) {
      let response = await this.api.platformApi.EditRole(params);
      if ((response.data.data = true)) {
        this.$message({
          message: "修改成功",
          type: "success",
        });
        this.loadList();
      } else {
        this.$message({
          message: "修改失败",
          type: "success",
        });
      }
    },

    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0";
    },

    formatRole(row, column) {
      return row.status == "0" ? "是" : row.status == "1" ? "否" : "aaa";
    },
    onSubmit() {
      this.loadList();
    },
    addrole() {
      this.dialogVisibleadd = true;
    },

    pageChange(val) {
      this.queryParams.pageNo = val;
      this.loadList();
    },
    cancel() {
      this.loadList();
      this.addUser = false;
      this.dialogVisible = false;
      this.dialogVisibleadd = false;
    },
    handleClick(table, event) {},
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    findByRoleUser() {
      this.addUser = true;
    },
    deselect() {
      this.pageSwitch = false;
      this.currentSelection = "";
      this.isHidden = true;
      //this.$refs.tree.setCheckedKeys([])
    },
    insertrole() {
      this.$refs.rolevalue.submitForm("ruleForm");
      this.dialogVisible = false;
    },
    userListHandleCurrentChange(val) {
      this.userListPageNo = val;
      this.loadlistuser();
    },
    userListHandleSizeChange(val) {
      this.userListPageSize = val;
      this.userListPageNo = 1;
      this.loadlistuser();
    },
  },
  components: {
    addSysRoleList,
    sysUserList: SysUserList,
    addRole,
    permissioninfo,
  },
};
</script>
<style scoped>
#sysRoleList .block {
  text-align: right;
  margin-top: 15px;
}

#buttonbox button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}

#bt2 button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}

#bt1 {
  margin-right: 15px;
  padding: 0px;
  width: 120px;
  height: 32px;
  border-radius: 5px;
}

#back {
  background-color: white;
  padding: 15px 10px;
}

#msg button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}

#msg {
  background-color: white;
  padding: 15px 0 100px;
}

#page {
  float: right;
  margin-top: 15px;
}

#navname {
  margin-right: 220px;
  color: #409eff;
}

#select {
  display: inline-block;
  width: 100px;
}

#anniu {
  padding: 0px;
  width: 100px;
  height: 32px;
  border-radius: 5px;
}

#sysRoleList .butT {
  float: left;
  /* padding-left: 2%; */
  margin-bottom: 2%;
}

#add {
  background-color: white;
  padding: 20px 20px 200px;
  margin-right: 20px;
}

#tablebox {
  width: 95%;
  margin-top: 15px;
  background-color: rgba(29, 144, 255, 0);
}

#bd {
  line-height: 40px;
  height: 40px;
  background-color: #e6f7ff;
  border-radius: 5px;
  border: 1px solid #1d90ff;
  font-size: 16px;
  margin: 20px 0 10px 0;
}

#bd > span {
  vertical-align: middle;
  margin-right: 20px;
}

#bd > img {
  vertical-align: middle;
  margin: 0 5px 0 10px;
}

>>> .el-dialog__body {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgba(228, 228, 228, 1);
  padding-left: 0;
  padding-right: 0;
}

.el-table--striped .el-table__body tr.el-table__row--striped.current-row td,
>>> .el-table__body tr.current-row > td {
  color: #fff;
  background-color: #e6f7ff !important;
}

#sysRoleList .hiddenBox {
  visibility: hidden;
}

#sysRoleList .showBox {
  background-color: white;
}

#sysRoleList .titlebox {
  text-align: left;
}

#sysRoleList .el-dialog button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
</style>
