<template>
  <div id="loanReviewDetails" v-loading="loading">
    <el-row :gutter="12">
      <el-col :pull="11">
        <span>借款信息</span>
      </el-col>
    </el-row>
    <div class="table padding_class">
      <table>
        <tr>
          <td>贷款编号</td>
          <td>{{ loaninformationRep.assetsId }}</td>
        </tr>
        <tr>
          <td>申请时间</td>
          <td>{{ loaninformationRep.applicationTime }}</td>
        </tr>
        <tr>
          <td>放款时间</td>
          <td>{{ loaninformationRep.lendingTime }}</td>
        </tr>
        <tr>
          <td>到期时间</td>
          <td>{{ loaninformationRep.expectedEndDate }}</td>
        </tr>
        <tr>
          <td>贷款产品</td>
          <td>{{ loaninformationRep.productName }}</td>
        </tr>
        <tr>
          <td>贷款金额</td>
          <td>{{ loaninformationRep.loanAmount / 100 }}</td>
        </tr>
        <tr>
          <td>贷款利率</td>
          <td>{{ loaninformationRep.creditInterestRate / 10000 }}%/日</td>
        </tr>
        <tr>
          <td>手续费率</td>
          <td>{{ loaninformationRep.serviceChargeRate / 10000 }}%</td>
        </tr>
        <tr>
          <td>手续费</td>
          <td>
            {{ loaninformationRep.expectedServiceCharge / 100 }}
          </td>
        </tr>
        <tr>
          <td>贷款期限</td>
          <td>{{ loaninformationRep.repaymentInterval }}天</td>
        </tr>
        <tr>
          <td>预计利息</td>
          <td>
            {{ loaninformationRep.expectedInterest / 100 }}
          </td>
        </tr>
        <tr>
          <td>已收利息</td>
          <td>{{ loaninformationRep.interestReceived / 100 }}</td>
        </tr>
        <tr>
          <td>还款方式</td>
          <td>
            <div
              v-for="(item, index) in loanTypeDictionList"
              v-bind:key="index"
            >
              <div v-if="item.datavalue == loaninformationRep.loanType">
                {{ item.name }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>通知确权书</td>
          <!-- <td></td> -->
          <td>
            <el-button type="text" @click="getConfirmationNoticeUrl"
              >查看</el-button
            >
          </td>
        </tr>
        <tr>
          <td>借款合同</td>
          <!-- <td></td> -->
          <td>
            <el-button type="text" @click="getLoanContractUrl">查看</el-button>
          </td>
        </tr>
      </table>

      <div class="marginCss">
        <el-row :gutter="12">
          <el-col :pull="10">
            <span class="leftCss">本订单所使用发票及额度</span>
          </el-col>
        </el-row>
      </div>

      <el-table
        id="personTable"
        :data="creditInvoiceInfo"
        :header-cell-style="tableHeader"
        :cell-style="tableCell"
        border
      >
        <!-- <el-table-column prop="sss" width="100" label="序号">
          <template slot-scope="scope">
            <span>{{
              scope.$index + 1 + (page.pageNo - 1) * page.pageSize
            }}</span>
          </template>
        </el-table-column> -->
        <!-- <el-table-column prop="id" label="编号" :show-overflow-tooltip="true" align="center"></el-table-column> -->
        <el-table-column
          prop="stateInvoiceSerialNo"
          label="发票序号"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="stateInvoiceNo"
          label="发票代码"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="amountWithTax"
          label="发票含税金额"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.amountWithTax / 100 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="taxRate"
          label="发票税率"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.taxRate / 10000 }}%
          </template>
        </el-table-column>
        <el-table-column
          prop="amountExcludingTax"
          label="发票不含税金额"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.amountExcludingTax / 100 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="creditAmount"
          label="授信额度"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.creditAmount / 100 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="availableCreditAmount"
          label="剩余可用额度"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.availableCreditAmount / 100 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="useAmount"
          label="确权金额"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.useAmount / 100 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="invDate"
          label="开票时间"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="paymentDate"
          label="应付时间"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
      </el-table>

      <div class="marginCss">
        <el-row :gutter="0">
          <el-col :pull="11">
            <span>供应商信息</span>
          </el-col>
        </el-row>
      </div>

      <div class="table padding_class">
        <table>
          <tr>
            <td>供应商企业名称</td>
            <td>{{ corp.name }}</td>
          </tr>
          <tr>
            <td>法人姓名</td>
            <td>{{ corp.legalName }}</td>
          </tr>
          <tr>
            <td>社会统一信用代码</td>
            <td>{{ corp.idno }}</td>
          </tr>
          <tr>
            <td>经营地址</td>
            <td>{{ corp.businessAddress }}</td>
          </tr>
          <tr>
            <td>收款银行账号</td>
            <td>{{ corp.branchNo }}</td>
          </tr>
          <tr>
            <td>收款银行全称</td>
            <td>{{ corp.branchName }}</td>
          </tr>
          <tr>
            <td>开户银行省、市</td>
            <td>{{ corp.provinceOperation + corp.cityOperation }}</td>
          </tr>
          <tr>
            <td>实名认证</td>
            <td>已认证</td>
          </tr>
          <tr>
            <td>电子签章</td>
            <td>已开通</td>
          </tr>
        </table>
      </div>

      <div class="marginCss">
        <el-row :gutter="5">
          <el-col :pull="11">
            <span>法人信息</span>
          </el-col>
        </el-row>
      </div>

      <div class="table padding_class">
        <table>
          <tr>
            <td>法人姓名</td>
            <td>{{ riskPerson.name }}</td>
          </tr>
          <tr>
            <td>身份证号</td>
            <td>{{ riskPerson.idno }}</td>
          </tr>
          <tr>
            <td>手机号</td>
            <td>{{ riskPerson.mobile }}</td>
          </tr>
          <tr>
            <td>银行卡号</td>
            <td>{{ riskPerson.bankcard }}</td>
          </tr>
          <tr>
            <td>实名认证</td>
            <td>已认证</td>
          </tr>
          <tr>
            <td>电子签章</td>
            <td>已开通</td>
          </tr>
        </table>
      </div>

      <div class="marginCss">
        <el-row :gutter="12">
          <el-col :pull="11">
            <span>审批结果</span>
          </el-col>
        </el-row>
      </div>

      <el-table
        id="personTable"
        :data="firstInstance"
        :header-cell-style="tableHeader"
        :cell-style="tableCell"
        border
      >
        <el-table-column
          prop="state"
          label="审核结果"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <div v-for="(item, index) in dictionList" v-bind:key="index">
              <div v-if="item.datavalue == scope.row.state">
                {{ item.name }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="reviewedBy"
          label="审核人"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="auditTime"
          label="审核时间"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="auditOpinion"
          label="审核意见"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
      </el-table>

      <div class="marginCss">
        <el-row :gutter="12">
          <el-col :pull="11">
            <span>复审信息</span>
          </el-col>
        </el-row>
      </div>

      <div class="fuInfo">
        <el-row :gutter="12">
          <el-col :span="2">
            <span>复审结果:</span>
          </el-col>
          <el-col :span="1">
            <el-radio v-model="radio" label="3">通过</el-radio>
          </el-col>
          <el-col :span="1">
            <el-radio v-model="radio" label="4">驳回</el-radio>
          </el-col>
        </el-row>
      </div>

      <div class="marginCss">
        <el-row :gutter="12">
          <el-col :span="2">
            <span>复审意见:</span>
          </el-col>
          <el-col :span="8">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="textarea"
            ></el-input>
          </el-col>
        </el-row>
      </div>

      <div class="marginCss">
        <el-row :gutter="30">
          <el-col :span="4">
            <div class="buttonCss">
              <el-button type="primary" @click="submit">提交</el-button>
              <el-button @click="close">关闭</el-button>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import { findAssetsRepaymentInfo } from "@/service/moudules/platformApi";
import { findCreditInvoiceInfo } from "@/service/moudules/platformApi";
import { findRepaymentPlan } from "@/service/moudules/postLoanManagementApi";
import { findDictionariesItemList } from "@/service/moudules/platformApi";
import { findByApplicantApplyAndType } from "@/service/moudules/platformApi";
export default {
  data() {
    return {
      loading: false,
      textarea: "",
      radio: "3",
      loaninformationRep: {
        assetsId: "",
        productName: "",
        loanAmount: 0,
        repaymentInterval: 0,
        creditInterestRate: 0,
        principalAmount: 0,
        createTime: "",
        lendingTime: "",
        time: "",
        loanType: 0,
        expectedInterest: 0,
        interestReceived: 0,
        expectedServiceCharge: 0,
        serviceChargeRate: 0,
      },
      corp: {
        name: "",
        legalName: "",
        idno: "",
        businessAddress: "",
        branchNo: "",
        branchName: "",
        provinceOperation: "",
        cityOperation: "",
      },
      creditInvoiceInfo: [],
      repaymentPlan: [],
      diction: {
        code: "applyState",
      },
      loanTypeDiction: {
        code: "loanType",
      },
      loanTypeDictionList: [],
      riskPerson: {
        name: "",
        idno: "",
        mobile: "",
        bankcard: "",
      },
      dictionList: [],
      applyList: [],
      firstInstance: [],
      toInstance: [],
      loan: [],
      applyId: "",
    };
  },
  mounted: function () {
    let param = JSON.parse(localStorage.getItem("id"));
    console.log("接收参数", param);
    this.corp = param.corpInfo;
    this.applyId = param.applyId;
    this.findLoaninformationRep(param.id);
    this.findCreditInvoiceInfo(param.corpInfo.id, param.id);
    this.findDictionariesItemList(this.diction);
    this.findLoanTypeDictionList(this.loanTypeDiction);
    // this.findByApplicantApplyAndType(param.id);
    this.approvalResults(param.id);
    this.findRerson(param.corpInfo.legal);
  },
  methods: {
    //根据id查询申请
    async findApplyById(id) {
      console.log("查询申请参数:", id);
      var param = {
        id: id,
      };
      let response = await this.api.postLoanManagementApi.findApplyById(param);
      if (response.data.code === 200) {
        if (response.data.data.state === 1) {
          console.log("进入复审");
          if (this.radio == 3) {
            this.reviewedAndApproved();
          }
          if (this.radio == 4) {
            this.reviewRejected();
          }
          return
        }
        this.$message.success("该比贷款已操作,请返回复审列表");
      }
    },
    getCookie: function (cname) {
      let name = cname + "=";
      let ca = document.cookie.split(";");
      // console.log("获取cookie,现在循环")
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        // console.log(c)
        while (c.charAt(0) === " ") c = c.substring(1);
        if (c.indexOf(name) !== -1) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    submit() {
      // this.updateStateById()
      // this.salability()
      // if (this.radio == 3) {
      //   this.reviewedAndApproved();
      // }
      // if (this.radio == 4) {
      //   this.reviewRejected();
      // }
      this.findApplyById(this.applyId)
    },
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0";
    },
    //复审通过
    async reviewedAndApproved() {
      var param = {
        assets: JSON.parse(localStorage.getItem("id")).id,
        reviewer: this.getCookie("sessionId"),
        opinion: this.textarea,
      };
      let response = await this.api.postLoanManagementApi.reviewedAndApproved(
        param
      );
      console.log("复审通过返回结果", response.data);
      if(response.data.code!==200 && response.data.msg ==="正在处理复审通过中,请稍后再尝试"){
        this.$message.success(response.data.msg);
        return 
      }
      if (response.data.data == true) {
        this.loading = false;
        this.$router.push({ name: "loanProcessingList" });
      }
      if (response.data.data == false) {
        this.loading = false;
        this.$router.push({ name: "loanReviewList" });
      }
    },
    //复审拒绝
    async reviewRejected() {
      var param = {
        assets: JSON.parse(localStorage.getItem("id")).id,
        reviewer: this.getCookie("sessionId"),
        opinion: this.textarea,
        risk: JSON.parse(localStorage.getItem("id")).corpInfo.id,
      };
      let response = await this.api.postLoanManagementApi.reviewRejected(param);
      console.log("复审拒绝返回结果", response.data);
      if(response.data.code!==200 && response.data.msg ==="正在处理复审拒绝中,请稍后再尝试"){
        this.$message.success(response.data.msg);
        return 
      }
      if (response.data.data == true || response.data.data == false) {
        this.loading = false;
        this.$router.push({ name: "loanReviewList" });
      }
    },
    async findLoaninformationRep(assetsId) {
      var param = {
        assets: assetsId,
      };
      let response = await this.api.postLoanManagementApi.findLoaninformationRep(
        param
      );
      console.log("资产还款返回结果", response.data);
      if (null != response.data.data) {
        this.loaninformationRep = response.data.data;
      }
      console.log("赋值结果:", this.loaninformationRep);
    },
    async findRerson(id) {
      var idParam = {
        id: id,
      };
      let personResponse = await this.api.platformApi.findPersonAndRisk(
        idParam
      );
      this.riskPerson = personResponse.data.data;
      console.log("法人返回结果", personResponse.data);
    },
    async findCreditInvoiceInfo(riskId, assetsId) {
      var param = {
        riskId: riskId,
        assetsId: assetsId,
      };
      let response = await this.api.platformApi.findCreditInvoiceInfo(param);
      console.log("发票返回结果", response.data);
      this.creditInvoiceInfo = response.data.data;
      console.log("发票赋值集合", this.creditInvoiceInfo);
    },
    async findRepaymentPlan(assetsId) {
      var param = {
        id: assetsId,
      };
      let response = await this.api.postLoanManagementApi.findRepaymentPlan(
        param
      );
      console.log("还款计划返回结果", response.data);
      this.repaymentPlan = response.data.data;
    },
    async findDictionariesItemList(param) {
      let response = await this.api.platformApi.findDictionariesItemList(param);
      console.log("数据字典", response);
      this.dictionList = response.data.data;
    },
    async findLoanTypeDictionList(param) {
      let response = await this.api.platformApi.findDictionariesItemList(param);
      console.log("还款状态数据字典", response);
      this.loanTypeDictionList = response.data.data;
    },
    async findByApplicantApplyAndType(assetsId) {
      var param = {
        id: assetsId,
      };
      let response = await this.api.platformApi.findByApplicantApplyAndType(
        param
      );
      if (response.data.data == null) {
        return;
      }
      console.log("审批结果", response.data);
      this.firstInstance = response.data.data;
    },
    async approvalResults(assetsId) {
      var param = {
        id: assetsId,
      };
      let response = await this.api.postLoanManagementApi.approvalResults(
        param
      );
      if (response.data.data == null) {
        return;
      }
      console.log("审批结果", response.data);
      this.firstInstance = response.data.data;
    },
    async updateStateById() {
      // console.log(this.formInline)
      // debugger;
      var params = {
        assets: JSON.parse(localStorage.getItem("id")).id,
        applyState: this.radio,
        reviewer: this.getCookie("sessionId"),
        opinion: this.textarea,
      };
      let response = await this.api.postLoanManagementApi.updateStateById(
        params
      );
      console.log("==========", JSON.stringify(response.data));
      console.log(response.data);
    },
    async salability() {
      var params = {
        id: JSON.parse(localStorage.getItem("id")).id,
      };
      let response = await this.api.postLoanManagementApi.salability(params);
      console.log("==========", JSON.stringify(response.data));
      console.log(response.data);
    },
    close() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
#loanReviewDetails {
  background-color: white;
  padding: 15px 0 100px;
}
#loanReviewDetails table,
td {
  border: 1px solid #e8e8e8;
  border-collapse: collapse;
  line-height: 35px;
  margin-left: 30px;
  margin-top: 3px;
}
table {
  width: 90%;
}
td:nth-child(odd) {
  background: #fafafa;
  width: 13%;
  text-align: center;
  font-size: 13px;
}
td:nth-child(even) {
  text-align: left;
  color: #7c7c7c;
  font-size: 13px;
}
#personTable {
  width: 90%;
  margin-top: 15px;
  margin-left: 30px;
  background-color: rgba(29, 144, 255, 0);
}
#loanReviewDetails span {
  font-size: 15px;
}
#loanReviewDetails .marginCss {
  margin-top: 35px;
}
#loanReviewDetails .leftCss {
  margin-right: 27px;
}
#loanReviewDetails .buttonCss {
  border-radius: 5px;
}
.buttonCss button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
#loanReviewDetails .fuInfo {
  margin-top: 10px;
}
.padding_class {
  margin-top: 15px;
}
</style>