<template>
    <div>
        <div id="downloadQrcode">
            <div class="cropName">{{this.tenant}}</div>
            <div class="qrcode">
                <div  ref="qrCodeUrl"></div>
            </div>
        </div>
        <div style="text-align: center;"><el-button type="text" @click="download">下载</el-button></div>
	</div>
</template>

<script>
import QRCode from 'qrcodejs2'
import html2canvas from 'html2canvas';

    export default {
            props: {
                user:{
                    type: String,
                    default: '',
                },
                tenant:{
                    type: String,
                    default: '',
                }
            },
            data() {
                return {
                    url: null,
                    path: '/pages/corp/binding/',
                    param: '?tenantId=',
                    value: null
                }
            },
            mounted() {
                this.url = window.location.href.split("#")[0]+'#';
                this.value=this.$route.query.id;
                this.creatQrCode();
            },
            methods: {
                creatQrCode() {
                    var qrcode = new QRCode(this.$refs.qrCodeUrl, {
                        text: this.user, // 需要转换为二维码的内容
                        width: 300,
                        height: 300,
                        colorDark: '#000000',
                        colorLight: '#ffffff',
                        correctLevel: QRCode.CorrectLevel.H
                    })
                },
                download(){
                    html2canvas(document.getElementById('downloadQrcode'),{}).then(canvas => {
                        var imgUrl = canvas.toDataURL();
                        //imgUrl即为图片的base64形式
                        // console.log(imgUrl);
                        var date = new Date();
                        var aLink = document.createElement("a"); // 创建一个a标签
                        var blob = this.base64ToBlob(imgUrl);
                        var event = document.createEvent("HTMLEvents");
                        event.initEvent("click", true, true);
                        aLink.download = date.getTime() + "." + blob.type.split("/")[1]; // 使用时间戳给文件命名
                        aLink.href = URL.createObjectURL(blob);
                        aLink.click();
                    });
                },
                base64ToBlob(code) {
                    var parts = code.split(";base64,");
                    var contentType = parts[0].split(":")[1];
                    var raw = window.atob(parts[1]);
                    var rawLength = raw.length;
                    var uint8Array = new Uint8Array(rawLength);
                    for (var i = 0; i < rawLength; i++) {
                        uint8Array[i] = raw.charCodeAt(i);
                    }
                    return new Blob([uint8Array], {type: contentType});
                }
            }
        };
</script>

<style  scope>
    .cropName{
        text-align: center;
        margin-top: 30px;
        font-size: 18px;
        color: #989898;
    }
     .qrcode{
        background-color: #111;
        width:300px;
        height:300px;
        margin: 0 auto; 
        position: relative;
        display: flex;
        justify-content: center;
        margin-top: 30px;
    } 
    #downloadQrcode{
        width: 500px;
        margin: auto;
        padding: 30px 0px;
    }


</style>