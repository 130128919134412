import '@/plugins/axios';

/**
 * 查询项目列表
 */
export const listPaidPlan = params => axios.post("paidPlan/listPaidPlan", params).then(data => data)

/**
 * 新增方案
 */
export const savePaidPlan = params => axios.post("paidPlan/savePaidPlan", params).then(data => data)

/**
 * 修改方案
 */
export const updatePaidPlan = params => axios.post("paidPlan/updatePaidPlan", params).then(data => data)

/**
 * 查询项目列表
 */
export const listPaidPlans = params => axios.post("paidPlan/listPaidPlans", params).then(data => data)

/**
 * 分页查询项目列表
 */
export const pagePaidPlan = params => axios.post("paidPlan/pagePaidPlan", params).then(data => data)








