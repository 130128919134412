<template>
  <div id="loanListDetails">
    <el-row :gutter="12">
      <el-col :pull="11">
        <span>借款信息</span>
      </el-col>
    </el-row>
    <div class="table">
      <div class="table_padding_class">
      <table>
        <tr>
          <td>贷款编号</td>
          <td>{{ loaninformationRep.assetsId }}</td>
        </tr>
        <tr>
          <td>申请时间</td>
          <td>{{ loaninformationRep.applicationTime }}</td>
        </tr>
        <tr>
          <td>放款时间</td>
          <td>{{ loaninformationRep.lendingTime }}</td>
        </tr>
        <tr>
          <td>到期时间</td>
          <td>{{ loaninformationRep.expectedEndDate }}</td>
        </tr>
        <tr>
          <td>贷款产品</td>
          <td>{{ loaninformationRep.productName }}</td>
        </tr>
        <tr>
          <td>贷款金额</td>
          <td>{{ loaninformationRep.loanAmount / 100 }}</td>
        </tr>
        <tr>
          <td>贷款利率</td>
          <td>
            {{ loaninformationRep.creditInterestRate / 10000 }}%/日
          </td>
        </tr>
        <tr>
          <td>手续费率</td>
          <td>
            {{ loaninformationRep.serviceChargeRate / 10000 }}%
          </td>
        </tr>
        <tr>
          <td>手续费</td>
          <td>
            {{ loaninformationRep.expectedServiceCharge / 100 }}
          </td>
        </tr>
        <tr>
          <td>贷款期限</td>
          <td>{{ loaninformationRep.repaymentInterval }}天</td>
        </tr>
        <tr>
          <td>预计利息</td>
          <td>
            {{ loaninformationRep.expectedInterest / 100 }}
          </td>
        </tr>
        <tr>
          <td>已收利息</td>
          <td>{{ loaninformationRep.interestReceived / 100 }}</td>
        </tr>
        <tr>
          <td>还款方式</td>
          <td>
            <div v-for="(item, index) in loanTypeDictionList" v-bind:key="index">
              <div v-if="item.datavalue == loaninformationRep.loanType">
                {{ item.name }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>通知确权书</td>
          <!-- <td></td> -->
          <td><el-button type="text" @click="getConfirmationNoticeUrl">查看</el-button></td>
        </tr>
        <tr>
          <td>借款合同</td>
          <!-- <td></td> -->
          <td><el-button type="text" @click="getLoanContractUrl">查看</el-button></td>
        </tr>
      </table>
</div>
      <div class="marginCss">
        <el-row :gutter="12">
          <el-col :pull="11">
            <span>还款计划</span>
          </el-col>
        </el-row>
      </div>

      <el-table
        id="personTable"
        :data="repaymentPlan"
        :header-cell-style="tableHeader"
        :cell-style="tableCell"
        border
      >
        <!-- <el-table-column prop="sss" width="100" label="序号">
          <template slot-scope="scope">
            <span>{{
              scope.$index + 1 + (page.pageNo - 1) * page.pageSize
            }}</span>
          </template>
        </el-table-column> -->
        <!-- <el-table-column prop="id" label="编号" :show-overflow-tooltip="true" align="center"></el-table-column> -->
        <el-table-column
          prop="time"
          label="应还时间"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="principalAmount"
          label="应还本金"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.principalAmount / 100 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="notYetRepaidPenaltyInterest"
          label="剩余应还总额"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{
              (scope.row.principalAmount + scope.row.interestAmount) / 100
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="interestAmount"
          label="应还利息"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.interestAmount / 100 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="repaymentPlanState"
          label="还款状态"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <div v-for="(item, index) in repaymentDictionList" v-bind:key="index">
              <div v-if="item.datavalue == scope.row.repaymentPlanState">
                {{ item.name }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="repaymentParty"
          label="还款方"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="date"
          label="还款时间"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
      </el-table>

      <div class="marginCss">
        <el-row :gutter="12">
          <el-col :pull="10">
            <span class="leftCss">本订单所使用发票及额度</span>
          </el-col>
        </el-row>
      </div>

      <el-table
        id="personTable"
        :data="creditInvoiceInfo"
        :header-cell-style="tableHeader"
        :cell-style="tableCell"
        border
      >
        <!-- <el-table-column prop="sss" width="100" label="序号">
          <template slot-scope="scope">
            <span>{{
              scope.$index + 1 + (page.pageNo - 1) * page.pageSize
            }}</span>
          </template>
        </el-table-column> -->
        <!-- <el-table-column prop="id" label="编号" :show-overflow-tooltip="true" align="center"></el-table-column> -->
        <el-table-column
          prop="stateInvoiceSerialNo"
          label="发票序号"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="stateInvoiceNo"
          label="发票代码"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="amountWithTax"
          label="发票含税金额"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.amountWithTax / 100 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="taxRate"
          label="发票税率"
          :show-overflow-tooltip="true"
          align="center"
        >
           <template slot-scope="scope">
            {{ scope.row.taxRate / 10000 }}%
          </template>
        </el-table-column>
        <el-table-column
          prop="amountExcludingTax"
          label="发票不含税金额"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.amountExcludingTax / 100 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="creditAmount"
          label="授信额度"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.creditAmount / 100 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="availableCreditAmount"
          label="剩余可用额度"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.availableCreditAmount / 100 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="useAmount"
          label="确权金额"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.useAmount / 100 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="invDate"
          label="开票时间"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="paymentDate"
          label="应付时间"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
      </el-table>

      <div class="marginCss">
        <el-row :gutter="0">
          <el-col :pull="11">
            <span>供应商信息</span>
          </el-col>
        </el-row>
      </div>

      <div class="table">
        <div class="table_padding_class">
        <table>
          <tr>
            <td>供应商企业名称</td>
            <td>{{ corp.name }}</td>
          </tr>
          <tr>
            <td>法人姓名</td>
            <td>{{ corp.legalName }}</td>
          </tr>
          <tr>
            <td>社会统一信用代码</td>
            <td>{{ corp.idno }}</td>
          </tr>
          <tr>
            <td>经营地址</td>
            <td>{{ corp.businessAddress }}</td>
          </tr>
          <tr>
            <td>收款银行账号</td>
            <td>{{ corp.branchNo }}</td>
          </tr>
          <tr>
            <td>收款银行全称</td>
            <td>{{ corp.branchName }}</td>
          </tr>
          <tr>
            <td>开户银行省、市</td>
            <td>{{ corp.provinceOperation + corp.cityOperation}}</td>
          </tr>
          <tr>
            <td>实名认证</td>
            <td>已认证</td>
          </tr>
          <tr>
            <td>电子签章</td>
            <td>已开通</td>
          </tr>
        </table>
        </div>
      </div>

      <div class="marginCss">
        <el-row :gutter="5">
          <el-col :pull="11">
            <span>法人信息</span>
          </el-col>
        </el-row>
      </div>

      <div class="table">
        <div class="table_padding_class">
        <table>
          <tr>
            <td>法人姓名</td>
            <td>{{ riskPerson.name }}</td>
          </tr>
          <tr>
            <td>身份证号</td>
            <td>{{ riskPerson.idno }}</td>
          </tr>
          <tr>
            <td>手机号</td>
            <td>{{ riskPerson.mobile }}</td>
          </tr>
          <tr>
            <td>银行卡号</td>
            <td>{{ riskPerson.bankcard }}</td>
          </tr>
          <tr>
            <td>实名认证</td>
            <td>已认证</td>
          </tr>
          <tr>
            <td>电子签章</td>
            <td>已开通</td>
          </tr>
        </table>
        </div>
      </div>

      <div class="marginCss">
        <el-row :gutter="12">
          <el-col :pull="11">
            <span>审批结果</span>
          </el-col>
        </el-row>
      </div>

      <el-table
        id="personTable"
        :data="firstInstance"
        :header-cell-style="tableHeader"
        :cell-style="tableCell"
        border
      >
        <el-table-column
          prop="state"
          label="审核结果"
          :show-overflow-tooltip="true"
          align="center"
        >
         <template slot-scope="scope">
              <div v-for="(item, index) in dictionList" v-bind:key="index">
                <div v-if="item.datavalue == scope.row.state">
                  {{ item.name }}
                </div>
              </div>
            </template>
        </el-table-column>
        <el-table-column
          prop="reviewedBy"
          label="审核人"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="auditTime"
          label="审核时间"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="auditOpinion"
          label="审核意见"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
      </el-table>

    </div>
  </div>
</template>
<script>
import { findAssetsRepaymentInfo } from "@/service/moudules/platformApi";
import { findCreditInvoiceInfo } from "@/service/moudules/platformApi";
import { findRepaymentPlan } from "@/service/moudules/postLoanManagementApi";
import { findDictionariesItemList } from "@/service/moudules/platformApi";
import { findByApplicantApplyAndType } from "@/service/moudules/platformApi";
export default {
  data() {
    return {
      loanReq:{
        assets:"",
        corp:"",
        thirdOrderNo:"",
        apply:"",
      },
      lendingParametersReq:{
        assets:"",
        apply:"",
      },
      loaninformationRep: {
        assetsId:"",
        productName:"",
        loanAmount:0,
        repaymentInterval:0,
        creditInterestRate:0,
        principalAmount:0,
        createTime:"",
        lendingTime:"",
        time:"",
        loanType:0,
        expectedInterest:0,
        interestReceived:0,
        expectedServiceCharge:0,
        serviceChargeRate:0,
      },
      corp: {
        name:'',
        legalName:'',
        idno:'',
        businessAddress:'',
        branchNo:'',
        branchName:'',
        provinceOperation:'',
        cityOperation:'',
      },
      creditInvoiceInfo: [],
      repaymentPlan: [],
      diction: {
        code: "applyState",
      },
      repaymentDiction: {
        code: "repaymentState",
      },
      loanTypeDiction:{
        code: "loanType",
      },
      loanTypeDictionList:[],
      repaymentDictionList:[],
      riskPerson: {
        name:"",
        idno:"",
        mobile:"",
        bankcard:"",
      },
      dictionList: [],
      applyList: [],
      firstInstance: [],
      toInstance: [],
      loan: [],
      confirmationNotice:[],
      loanContract:[],
    };
  },
  mounted: function () {
    let param = JSON.parse(localStorage.getItem("id"));
    console.log("接收参数", param);
    this.corp = param.corpInfo;
    console.log("corp"+ JSON.stringify(this.corp))
    this.loanReq.assets = param.id;
    this.loanReq.corp = param.corpInfo.id;
    this.lendingParametersReq.assets = param.id;
    this.findLoaninformationRep(param.id);
    this.findCreditInvoiceInfo(param.corpInfo.id,param.id);
    this.findRepaymentPlan(param.id);
    this.findDictionariesItemList(this.diction);
    this.findDictionariesItemListRepayment(this.repaymentDiction);
    this.findLoanTypeDictionList(this.loanTypeDiction)
    // this.findByApplicantApplyAndType(param.id);
    this.approvalResults(param.id);
    this.findRerson(param.corpInfo.legal);
  },
  methods: {
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    // tableCell(column) {
    //   return "height:40px;background-color:#fafafa;color:#000000;padding:0";
    // },
    tableCell(column) {
      return "height:40px;color:#000000;padding:0";
    },
    async getConfirmationNoticeUrl() {

      var param = {
        proofSet:this.loaninformationRep.proofSet,
      };
      let response = await this.api.postLoanManagementApi.getConfirmationNoticeUrl(param);
      console.log("获取确权通知书合同url返回结果", response.data);
       if (null != response.data.data) {
        window.open(response.data.data);
       }
    },
    async getLoanContractUrl() {

      var param = {
        proofSet:this.loaninformationRep.proofSet,
      };
      let response = await this.api.postLoanManagementApi.getLoanContractUrl(param);
      console.log("获取借款合同url返回结果", response.data);
       if (null != response.data.data) {
        window.open(response.data.data);
       }
    },
    async findLoaninformationRep(assetsId) {
      var param = {
        assets: assetsId,
      };
      let response = await this.api.postLoanManagementApi.findLoaninformationRep(param);
      console.log("资产还款返回结果", response.data);
      if (null != response.data.data) {
        this.loaninformationRep = response.data.data;
      }
      console.log("赋值结果:", this.loaninformationRep);
    },
    async findRerson(id) {
      var idParam = {
        id: id,
      };
      let personResponse = await this.api.platformApi.findPersonAndRisk(
        idParam
      );
      this.riskPerson = personResponse.data.data;
      console.log("法人返回结果", personResponse.data);
    },
    async findCreditInvoiceInfo(riskId,assetsId) {
      var param = {
        riskId: riskId,
        assetsId:assetsId,
      };
      let response = await this.api.platformApi.findCreditInvoiceInfo(param);
      console.log("发票返回结果", response.data);
      this.creditInvoiceInfo = response.data.data;
      console.log("发票赋值集合", this.creditInvoiceInfo);
    },
    async findRepaymentPlan(assetsId) {
      var param = {
        assets: assetsId,
      };
      let response = await this.api.postLoanManagementApi.findRepaymentPlan(
        param
      );
      console.log("还款计划返回结果", response.data);
      this.repaymentPlan = response.data.data;
    },
    async findDictionariesItemList(param) {
      let response = await this.api.platformApi.findDictionariesItemList(param);
      console.log("数据字典", response);
      this.dictionList = response.data.data;
    },
    async findDictionariesItemListRepayment(param) {
      let response = await this.api.platformApi.findDictionariesItemList(param);
      console.log("还款状态数据字典", response);
      this.repaymentDictionList = response.data.data;
    },
    async findLoanTypeDictionList(param) {
      let response = await this.api.platformApi.findDictionariesItemList(param);
      console.log("还款状态数据字典", response);
      this.loanTypeDictionList = response.data.data;
    },
    
    async findByApplicantApplyAndType(assetsId) {
      var param = {
        id: assetsId,
      };
      let response = await this.api.platformApi.findByApplicantApplyAndType(
        param
      );
      if (response.data.data == null) {
        return;
      }
      console.log("审批结果", response.data);
      this.firstInstance = response.data.data;
      this.loanReq.apply = this.firstInstance[0].apply;
      this.lendingParametersReq.apply = this.firstInstance[0].apply;

    },
    async approvalResults(assetsId) {
      var param = {
        id: assetsId,
      };
      let response = await this.api.postLoanManagementApi.approvalResults(
        param
      );
      if (response.data.data == null) {
        return;
      }
      console.log("审批结果", response.data);
      this.firstInstance = response.data.data;
    },
    async sign(params) {
      console.log("sign"+JSON.stringify(params))
      let response = await this.api.postLoanManagementApi.sign(
        params
      );
      console.log("=====sign=====", JSON.stringify(response.data));
      console.log(response.data);
    },
   async canLoan(params) {
      let response = await this.api.postLoanManagementApi.canLoan(
        params
      );
      console.log("==========", JSON.stringify(response.data));
      console.log(response.data);
    },
    close() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
#loanListDetails {
  background-color: white;
  padding: 15px 0 100px;
}
#loanListDetails table,
td {
  border: 1px solid #e8e8e8;
  border-collapse: collapse;
  line-height: 35px;
  margin-left: 30px;
  margin-top: 3px;
}
table {
  width: 90%;
}
td:nth-child(odd) {
  background: #fafafa;
  width: 13%;
  text-align: center;
  font-size: 13px;
}
td:nth-child(even) {
  text-align: left;
  color: #7c7c7c;
  font-size: 13px;
}
#personTable {
  width: 90%;
  margin-top: 15px;
  margin-left: 30px;
  background-color: rgba(29, 144, 255, 0);
}
#loanListDetails span {
  font-size: 15px;
}
#loanListDetails .marginCss {
  margin-top: 35px;
}
#loanListDetails .leftCss {
  margin-right: 27px;
}
#loanListDetails .buttonCss {
  border-radius: 5px;
}
.buttonCss button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
#loanListDetails .fuInfo {
  margin-top: 5px;
}
.table_padding_class{
  margin-top:15px;
}
</style>