<template>
  <div class="search">
    <div class="log_page_class">
      <template>
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-row :gutter="24">
            <el-col :span="8" align="left">
              <div class="grid-content bg-purple">
                <el-form-item label="账号:">
                  <el-input
                    v-model="formInline.account"
                    placeholder="请输入账号查询"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="16" align="left">
              <div class="grid-content bg-purple">
                <el-form-item label="操作时间:">
                  <el-date-picker
                    v-model="formInline.timestart"
                    type="date"
                    placeholder="起"
                    editable
                    pattern="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker
                  > -
                  <el-date-picker
                    v-model="formInline.timeend"
                    type="date"
                    placeholder="止"
                    editable
                    pattern="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="8" align="left">
              <div>
                <el-form-item label="路径:">
                  <el-input
                    v-model="formInline.permission"
                    placeholder="请输入路径查询"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="8" align="left">
                <el-form-item label="请求内容:">
                  <el-input
                    v-model="formInline.body"
                    placeholder="请输入内容查询"
                  ></el-input>
                </el-form-item>
              
            </el-col>
            <el-col :span="8" >
              <div>
                <el-form-item class="buttonbox">
                  <el-button
                    type="primary"
                    @click="onSubmit"
                    icon="el-icon-search"
                    >查询</el-button
                  >
                  <el-button
                    type="primary"
                    @click="rest"
                    icon="el-icon-refresh-right"
                    >重置</el-button
                  >
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </template>
    </div>
    <el-table
      id="table"
      :data="list"
      :header-cell-style="tableHeader"
      :cell-style="tableCell"
      :default-sort="{ prop: 'timestamp', order: 'descending' }"
      border
    >
      <el-table-column
        type="index"
        prop="id"
        label="编号"
        width="50"
        :show-overflow-tooltip="true"
        align="center"
        
      >
        <template slot-scope="scope">
          <span>{{
            scope.$index + 1 + (queryParams.pageNo - 1) * queryParams.pageSize
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="account"
        label="账号"
        :show-overflow-tooltip="true"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="name"
        label="名称"
        :show-overflow-tooltip="true"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="ip"
        label="IP地址"
        :show-overflow-tooltip="true"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="permission"
        label="路径"
        :show-overflow-tooltip="true"
        align="center"
      />
      <el-table-column
        prop="header"
        label="请求头"
        :show-overflow-tooltip="true"
        align="center"
      />
      <el-table-column
        prop="body"
        label="请求内容"
        :show-overflow-tooltip="true"
        align="center"
      />
      <el-table-column
        prop="timestamp"
        label="操作时间"
        :show-overflow-tooltip="true"
        align="center"
      ></el-table-column>
      <el-table-column fixed="right" label="操作" width="100" align="center">
        <template slot-scope="scope">
          <el-button @click="showLog(scope.row)" type="text" size="small"
            >查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div>
      <template>
        <el-pagination
          class="sx"
          background
          @size-change="handleSizeChange"
          @current-change="pageChange"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="queryParams.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </template>
      <el-dialog
        class="tabbox"
        :close-on-click-modal="false"
        title="查看"
        :show-close="true"
        :visible.sync="dialogVisiblelog"
      >
        <find-log :log="siglelog" @closeDialog="cancellog"></find-log>
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="cancellog()">关闭</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import findLog from "./findLog";
export default {
  data() {
    return {
      formInline: {
        id: "",
        permission: "",
        body: "",
        account: "",
        timestart: "",
        timeend: "",
      },
      siglelog: [],
      total: 0,
      dialogVisiblelog: false,
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        accountNo: "",
        role: "",
      },
      list: [],
    };
  },
  mounted: function () {
    this.loadList();
  },
  methods: {
    async loadList() {
      var me = this;
      var params = {
        account: this.formInline.account,
        permission: this.formInline.permission,
        body: this.formInline.body,
        timestart: this.formInline.timestart,
        timeend: this.formInline.timeend,
        pageSize: this.queryParams.pageSize,
        pageNo: this.queryParams.pageNo,
        sessionId: this.$getCookie("sessionId"),
      };
      await me.api.platformApi.AuditLogList(params).then(function (response) {
          me.list = response.data.data.records;
          me.total = response.data.data.total;
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {});
    },
    handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.queryParams.pageNo = 1;
      this.loadList();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },

    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0";
    },
    onSubmit() {
      this.loadList();
    },
    cancellog() {
      this.loadList();
      this.dialogVisiblelog = false;
    },
    pageChange(val) {
      this.queryParams.pageNo = val;
      this.loadList();
    },
    handleExportXls() {
      axios
        .get("/sysAuditLog/downloadsExcelDown")
        .then(function (response) {})
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {});
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    rest() {
      this.formInline.timestart = "";
      this.formInline.timeend = "";
      this.formInline.account = "";
      this.formInline.name = "";
      this.formInline.body = "";
      this.formInline.permission = "";
    },
    showLog(row) {
      this.dialogVisiblelog = true;
      this.siglelog = row;
    },
  },
  components: {
    findLog,
  },
};
</script>
<style scoped>
/* .log_page_class {
  width: 1200px;
  height: 100%;
} */
.tabbox {
  text-align: left;
}

#table {
  
  /* padding: 20px 20px 0px 20px; */
  background-color: rgba(29, 144, 255, 0);
}

.el-dialog {
  text-align: left;
}

>>> .el-dialog__body {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgba(228, 228, 228, 1);
  padding-left: 0;
  padding-right: 0;
}

.el-dialog button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}

.search {
  background-color: white;
  padding: 15px 20px 30px 20px;
  overflow: hidden;
}

#grid {
  margin-right: 15px;
  padding: 0px;
  border-radius: 5px;
}

.sx {
  float: right;
  margin-top: 15px;
}

.buttonbox button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
</style>
