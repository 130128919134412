<template>
  <div class="about" id="quotaReviewList">
    <div style="text-align: left">
      <el-form :inline="true" :model="queryForm" class="demo-form-inline">
        <el-form-item label="员工ID：">
          <el-input v-model="queryForm.uid" placeholder="请输入查询" :clearable="true"></el-input>
        </el-form-item>
        <el-form-item label="员工名字：">
          <el-input v-model="queryForm.name" placeholder="请输入查询" :clearable="true"></el-input>
        </el-form-item>
        <el-form-item label="员工身份证号码：">
          <el-input type="number" v-model="queryForm.idno" placeholder="请输入查询" :clearable="true"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onQuery" icon="el-icon-search">查询</el-button>
          <el-button type="primary" @click="onReset" icon="el-icon-refresh-right">重置</el-button>
        </el-form-item>
        <el-form-item>
          <el-button class="resu" type="primary" @click="back" icon="el-icon-arrow-left">返回上一页</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div style="text-align: left;margin-bottom: 20px;">
      <el-button type="primary" icon="el-icon-download" @click="exportTable">导出</el-button>
      <el-button type="primary"  @click="submitAudits">发薪</el-button>
    </div>
    <div style="display: flex;justify-content: space-between;">
      <span>汇总表编号：<b>{{$route.query.summaryNo}}</b></span>
      <span>上传时间：<b>{{$route.query.updateTime}}</b></span>
      <span>状态：<b>{{(isEntity(summaryStateList) || isEntity(headData) || isEntity(headData.state) || isEntity(summaryStateList[headData.state]) || 
          isEntity(summaryStateList[headData.state].name))?'':summaryStateList[headData.state].name}}</b></span>
      <span>总记录：<b>{{total}}条</b></span>
    </div>
    <div>
        <el-table
        :data="tableData"
        :header-cell-style="tableHeader"
        :cell-style="tableCell"
        border
        fit
        style="width: 100%">
        <el-table-column  type="index"  label="序号" />
        <el-table-column :show-overflow-tooltip="true" prop="uid"  label="员工ID" /> 
        <el-table-column prop="name" label="员工名字" />
        <el-table-column prop="idno" label="身份证号码" :show-overflow-tooltip="true" />
        <el-table-column label="本月应发工资" ><template slot-scope="scope">{{accDiv(scope.row.payrollPayable,100)}}</template></el-table-column> 
        <el-table-column label="个税" ><template slot-scope="scope">{{accDiv(scope.row.personaltax,100)}}</template></el-table-column> 
        <el-table-column label="本月实发工资" ><template slot-scope="scope">{{accDiv(scope.row.netSalary,100)}}</template></el-table-column> 
        <el-table-column prop="incrementTaxation" :show-overflow-tooltip="true" label="增值税费" ></el-table-column>
        <el-table-column prop="localtaxSurchargeTaxation" :show-overflow-tooltip="true" label="地税附加税费"></el-table-column>
        <el-table-column prop="servicecharge" :show-overflow-tooltip="true" label="服务费"></el-table-column>
        <!-- <el-table-column prop="actualDeductibleExpenses" :show-overflow-tooltip="true" label="本月实际抵扣费用"></el-table-column> -->
        <el-table-column prop="invoiceAmount" :show-overflow-tooltip="true" label="开票金额"></el-table-column>
        <el-table-column prop="totalExpenses" :show-overflow-tooltip="true" label="支付给我司费用总计"></el-table-column>
        <el-table-column prop="deductibleExpenses" :show-overflow-tooltip="true" label="次月可扣除费用"></el-table-column>
        <el-table-column prop="createTime" label="计薪时间" />
        <el-table-column prop="remarks" label="备注" />
        <el-table-column fixed="right" label="操作" >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="openUploadFrom(scope.row)" v-if="$route.query.status!=6" style="color: #09BF88">修改</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
      
      id="page"
        background
        @size-change="sizeChange"
        @current-change="currentChange"
        @prev-click="currentChange"
        @next-click="currentChange"
        :page-sizes="[5, 10, 20]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <div>
      <el-dialog title="调整薪资" :visible.sync="dialogFormVisible" width="50%"   style="text-align: initial;" @closed='closedDialog'>

        <el-form class="wageadjustment" :model="updateForm" :inline="true" :label-position="'right'"   ref="updateForm" label-width="150px">
           <el-form-item label="员工名字："  prop="hname" >
            <el-input  v-model="updateForm.hname" :precision="2" :controls='false' placeholder="请输入员工名字" />
          </el-form-item>
           <el-form-item label="身份证号码："  prop="hidno" >
            <el-input  v-model="updateForm.hidno" :precision="2" :controls='false' placeholder="请输入身份证号码"/>
          </el-form-item>
           <el-form-item label="本月应发工资："  prop="hsalary" >
            <el-input-number  v-model="updateForm.hsalary" :precision="2" :controls='false' placeholder="请输入本月应发工资" ref="inputNumber" />
          </el-form-item>
           <el-form-item label="个税："  prop="htax" >
            <el-input-number  v-model="updateForm.htax" :precision="2" :controls='false' :disabled="true" placeholder="请输入个税" ref="inputNumber" />
          </el-form-item>
          <el-form-item label="本月实发工资："  prop="newSalaryAmount" >
            <el-input-number  v-model="updateForm.newSalaryAmount" :precision="2" :controls='false' :disabled="true" placeholder="请输入本月实发工资" ref="inputNumber" />
          </el-form-item>
          <el-form-item label="增值税费："  prop="hincrementTaxation" >
            <el-input-number  v-model="updateForm.hincrementTaxation" :precision="2" :controls='false' :disabled="true" placeholder="请输入增值税费" ref="inputNumber" />
          </el-form-item>

          <el-form-item label="地税附加税费："  prop="hlocaltaxSurchargeTaxation" >
            <el-input-number  v-model="updateForm.hlocaltaxSurchargeTaxation" :precision="2" :controls='false' :disabled="true" placeholder="请输入地税附加税费" ref="inputNumber" />
          </el-form-item>
           <el-form-item label="服务费："  prop="hservicecharge" >
            <el-input-number  v-model="updateForm.hservicecharge" :precision="2" :controls='false' :disabled="true" placeholder="请输入服务费" ref="inputNumber" />
          </el-form-item>
           <el-form-item label="本月实际抵扣金额："  prop="hactualDeductibleExpenses" >
            <el-input-number  v-model="updateForm.hactualDeductibleExpenses" :precision="2" :controls='false' :disabled="true" placeholder="请输入本月实际抵扣金额" ref="inputNumber" />
          </el-form-item>
          <el-form-item label="开票金额："  prop="hinvoiceAmount" >
            <el-input-number  v-model="updateForm.hinvoiceAmount" :precision="2" :controls='false' :disabled="true" placeholder="请输入开票金额" ref="inputNumber" />
          </el-form-item>
           <el-form-item label="支付给我司费用："  prop="htotalExpenses" >
            <el-input-number  v-model="updateForm.htotalExpenses" :precision="2" :controls='false' :disabled="true" placeholder="请输入支付给我司费用" ref="inputNumber" />
          </el-form-item>
          <el-form-item label="次月可扣除费用："  prop="hdeductibleExpenses" >
            <el-input-number  v-model="updateForm.hdeductibleExpenses" :precision="2" :controls='false' :disabled="true" placeholder="请输入次月可扣除费用" ref="inputNumber" />
          </el-form-item>

          <el-form-item label="备注：" prop="remark" >
            <el-input type="textarea" v-model="updateForm.remark"></el-input>
          </el-form-item>
        </el-form>

         <el-divider></el-divider>
        <div class="wageadjustment">
          <div style="margin-bottom: 10px;">调整记录：</div>
          <div>
            <el-table class="quotaReviewList" height="250" :data="logTableData" border style="width: 100%">
              <el-table-column prop="createTime" label="操作时间" />

              <el-table-column prop="qname" label="修改前姓名" ></el-table-column>
              <el-table-column prop="hname" label="修改后姓名" ></el-table-column>
              <el-table-column prop="qidno" label="修改前身份证" ></el-table-column>
              <el-table-column prop="hidno" label="修改后身份证" ></el-table-column>
              <el-table-column prop="qsalary" label="修改前应发工资" ><template slot-scope="scope">{{scope.row.qsalary/100}}</template></el-table-column>
              <el-table-column prop="hsalary" label="修改后应发工资" ><template slot-scope="scope">{{scope.row.hsalary/100}}</template></el-table-column>
              <el-table-column prop="qtax" label="修改前个税" ><template slot-scope="scope">{{scope.row.qtax/100}}</template></el-table-column>
              <el-table-column prop="htax" label="修改后个税" ><template slot-scope="scope">{{scope.row.htax/100}}</template></el-table-column>
              <el-table-column prop="oldSalaryAmount" label="修改前实发工资" ><template slot-scope="scope">{{scope.row.oldSalaryAmount/100}}</template></el-table-column>
              <el-table-column prop="newSalaryAmount" label="修改后实发工资" ><template slot-scope="scope">{{scope.row.newSalaryAmount/100}}</template></el-table-column>
              <el-table-column prop="qincrementTaxation" label="修改前增值税费" ><template slot-scope="scope">{{scope.row.qincrementTaxation/100}}</template></el-table-column>
              <el-table-column prop="hincrementTaxation" label="修改后增值税费" ><template slot-scope="scope">{{scope.row.hincrementTaxation/100}}</template></el-table-column>
              <el-table-column prop="qlocaltaxSurchargeTaxation" label="修改前地税附加税费" ><template slot-scope="scope">{{scope.row.qlocaltaxSurchargeTaxation/100}}</template></el-table-column>
              <el-table-column prop="hlocaltaxSurchargeTaxation" label="修改后地税附加税费" ><template slot-scope="scope">{{scope.row.hlocaltaxSurchargeTaxation/100}}</template></el-table-column>
              <el-table-column prop="qservicecharge" label="修改前服务费" ><template slot-scope="scope">{{scope.row.qservicecharge/100}}</template></el-table-column>
              <el-table-column prop="hservicecharge" label="修改后服务费" ><template slot-scope="scope">{{scope.row.hservicecharge/100}}</template></el-table-column>
              <el-table-column prop="qactualDeductibleExpenses" label="修改前本月实际抵扣金额" ><template slot-scope="scope">{{scope.row.qactualDeductibleExpenses/100}}</template></el-table-column>
              <el-table-column prop="hactualDeductibleExpenses" label="修改后本月实际抵扣金额" ><template slot-scope="scope">{{scope.row.hactualDeductibleExpenses/100}}</template></el-table-column>
              <el-table-column prop="qinvoiceAmount" label="修改前开票金额" ><template slot-scope="scope">{{scope.row.qinvoiceAmount/100}}</template></el-table-column>
              <el-table-column prop="hinvoiceAmount" label="修改后开票金额" ><template slot-scope="scope">{{scope.row.hinvoiceAmount/100}}</template></el-table-column>
              <el-table-column prop="qtotalExpenses" label="修改前支付给我司费用" ><template slot-scope="scope">{{scope.row.qtotalExpenses/100}}</template></el-table-column>
              <el-table-column prop="htotalExpenses" label="修改后支付给我司费用" ><template slot-scope="scope">{{scope.row.htotalExpenses/100}}</template></el-table-column>
              <el-table-column prop="qdeductibleExpenses" label="修改前次月可扣除费用" ><template slot-scope="scope">{{scope.row.qdeductibleExpenses/100}}</template></el-table-column>
              <el-table-column prop="hdeductibleExpenses" label="修改后次月可扣除费用" ><template slot-scope="scope">{{scope.row.hdeductibleExpenses/100}}</template></el-table-column>
              <el-table-column prop="operator" label="操作人" />
              <el-table-column prop="remark" label="备注" />
            </el-table>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false;">取 消</el-button>
          <el-button type="primary" @click="submitUpdate">确 定</el-button>
        </div>
      </el-dialog>

      <el-dialog
        :visible.sync="auditMsgs.dialogVisible"
        width="30%"
        >
        <span :class="auditMsgs.msgClass">{{auditMsgs.msg}}</span>
        <el-form class="wageadjustments" :model="codes" :inline="true" :label-position="'right'"  :rules="rules" ref="updateForm" label-width="150px">
          <el-form-item label="手机号：" >
            <el-input class="inputVa" style="width:160px" v-model="codes.mobile" :disabled="true" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item label="验证码："  prop="code" >
            <el-input class="inputCode" style="width:160px" v-model="codes.code" placeholder="请输入验证码"></el-input>
            <el-button @click="getCode" type="success" :disabled="!show" >获取验证码
            <span v-show="!show" style="font-size:5px" class="count">({{countDown}} s)</span></el-button>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="auditMsgs.dialogVisible = false">取 消</el-button>
          <el-button :class="auditMsgs.buttonClass" type="primary" @click="submitForm('updateForm')" v-loading.fullscreen.lock="fullscreenLoading" element-loading-text="正在处理中，请不要重复操作！">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {accMul} from '../../util/calculateUtils';
export default {
    data() {
      return {
        countDown:'',
        show: true,
        codes:{
          code: "",
          show: true,
          countDown: 60,
          timer: null,
          isShow: false,
          mobile: '',
        },
        userData:'',
        headData: {
          createTime: '',
          groupId: '',
          groupName: '',
          id: '',
          latestApprovalTime: '',
          latestOperator: '',
          productId: '',
          productName: '',
          proofId: '',
          state: undefined,
          submitor: '',
          tenantId: '',
          uploadTime: ''
        },
        fullscreenLoading:false,
        summaryStateList: null,
        tableLoading: true,
        loadDate:'',
        tableData: [],
        logTableData: null,
        input: '',
        queryForm: {
          id: '',
          uid:"",
          idno: '',
          name: '',
          pageNo: 1,
          pageSize: 10,
          sessionId: this.$getCookie("sessionId"),
        },
        auditMsgs: {
          dialogVisible: false,
          msg: '确认撤销审核该汇总表吗？',
          msgClass: 'submitMsg',
          buttonClass: 'submitButton',
        },
        total:0,
        dialogFormVisible: false,
        changeStateParams:{
          id: '',
          mobile:'',
          code:''

        },
        updateForm: {
          id:'',
          uid: '',
          qname:undefined,
          hname:undefined,
          qidno:undefined,
          hidno:undefined,
          qsalary:undefined,
          hsalary:undefined,
          qtax:undefined,
          htax:undefined,
          oldSalaryAmount:undefined,
          newSalaryAmount: undefined,
          qincrementTaxation:undefined,
          hincrementTaxation:undefined,
          qlocaltaxSurchargeTaxation:undefined,
          hlocaltaxSurchargeTaxation:undefined,
          qservicecharge:undefined,
          hservicecharge:undefined,
          qactualDeductibleExpenses:undefined,
          hactualDeductibleExpenses:undefined,
          qinvoiceAmount:undefined,
          hinvoiceAmount:undefined,
          qtotalExpenses:undefined,
          htotalExpenses:undefined,
          qdeductibleExpenses:undefined,
          hdeductibleExpenses:undefined,
          remark: '',
          sessionId: '',
          

        },
        rules: {
          
          // code: [
          //   { required: true, message: '验证码不能为空！', trigger: 'blur' }
          // ],
        }
      }
    },
    async created(){
      this.queryForm.id = this.$route.query.id;

      await this.loadTable();
      this.loadMobile();
    },
    methods: {
      // 导出表格 exportSummaryDataLsit
      async exportTable(){
        console.log("导出下载");
        await this.api.salaryApi.downloadSalary({salarySummarizingId:this.$route.query.id}).then(res =>{
          console.log(res)
          let fileNameEncode = "实发工资单.xlsx";
          const link = document.createElement("a");
          let blob = new Blob([res.data], { type: "application/vnd.ms-excel"});
          console.log(blob);
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          link.setAttribute("download",  decodeURIComponent(fileNameEncode));
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
      },
      // 查询用户手机号
      async loadMobile(){
        var params = {
        
      };
        await this.api.salaryApi.finUserInfo(params).then(res =>{
          if(res.status = 200){
            this.userData = res.data;
            console.log("----------==",this.userData)
          }else{
            this.$message.error('查询手机号失败！');
          }
        })
      },
      // 加载当前表格头
      async loadInfo(){
        await this.api.summaryApi.getById({id: this.queryForm.id}).then(res =>{
          res = res.data;
          if(res.status = 200){
            this.headData = res.data;
          }else{
            this.$message.error('数据加载失败！');
          }
        })
      },
      submitForm(updateForm){
            this.$refs[updateForm].validate((valid) => {
            if (valid) {
              this.pays();
            } else {
              console.log('error submit!!');
              return false;
            }
          });
        },
      pays(){
          console.log("----------0000",this.changeStateParams)
          this.fullscreenLoading = true;
          this.changeStateParams.code=this.codes.code
          this.api.salaryApi.bb6bthhl(this.changeStateParams).then((res)=>{
            res = res.data
            if(res.code == 200){
              this.$message.success(res.msg)
              this.fullscreenLoading = false;
              this.auditMsgs.dialogVisible = false;
              this.loadTable()
            }else{
              this.$message.error(res.msg)
              this.fullscreenLoading = false;
              // this.auditMsgs.dialogVisible = false;
            }
          })
        },
      //发送验证码
      async getCode(){
        const TIME_COUNT = 60;
				if (!this.timer) {
					this.countDown = TIME_COUNT;
					this.show = false;
					this.timer = setInterval(() => {
						if (this.countDown > 0 && this.countDown <= TIME_COUNT) {
							this.countDown--;
						} else {
							this.show = true;
							clearInterval(this.timer);
							this.timer = null;
						}
					}, 1000)
				}
        await this.api.salaryApi.sendCode(this.codes).then((res) => {
          console.log("-------发送验证码",res);
          res = res.data
          if(res.code == 200){
            this.$message.success(res.msg);
          }else{
            this.$message.error(res.msg);
          }
        })
      },
      // 加载表格
      async loadTable(){
        // .bind(this);
        let flag = this;
        this.tableLoading = true;
        this.queryForm.groupId = this.headData.groupId;
        await this.api.salaryApi.summarizingDetail(this.queryForm).then(res =>{
          res = res.data;
          this.tableLoading = false;
          if(res.code == 200){
            this.tableData = res.data.records;
            this.total=res.data.total;
            this.tableData.forEach(item => {
              item.incrementTaxation = (item.incrementTaxation/100).toFixed(2),
              item.localtaxSurchargeTaxation = (item.localtaxSurchargeTaxation/100).toFixed(2),
              item.servicecharge = (item.servicecharge/100).toFixed(2),
              item.actualDeductibleExpenses = (item.actualDeductibleExpenses/100).toFixed(2),
              item.invoiceAmount = (item.invoiceAmount/100).toFixed(2),
              item.totalExpenses = (item.totalExpenses/100).toFixed(2),
              item.deductibleExpenses = (item.deductibleExpenses/100).toFixed(2)
            })
          }else{
            this.tableData =  null;
            this.total = 0;
            this.$message.error(res.msg);
          }
        })
    },

      async submitAudits(){
        await this.api.salaryApi.findSalaryState(this.queryForm).then((res) => {
            console.log("-------查询发薪状态",res);
            res = res.data
            if(res.code == 200){
              this.auditMsgs.dialogVisible = true;
              this.auditMsgs.msg='确认发薪吗？'
              this.auditMsgs.msgClass='submitMsg'
              this.auditMsgs.buttonClass='submitButton'
              this.changeStateParams.id=this.queryForm.id;
              this.codes.mobile=this.userData.mobile
              this.changeStateParams.mobile=this.userData.mobile;
              this.codes.code='';
            }else{
              this.$message.error(res.msg);
            }
        }) 
      },
      // 条件查询
      onQuery() {
        this.loadTable();
      },
      back(){
        this.$router.back();
      },
      // 修改
      openUploadFrom(row){
        // this.updateForm.oldSalaryAmount = row.payrollPayable
        this.updateForm.qname = row.name
        this.updateForm.qidno = row.idno
        this.updateForm.qsalary = row.payrollPayable
        this.updateForm.qtax = row.personaltax
        this.updateForm.oldSalaryAmount = row.netSalary
        this.updateForm.qincrementTaxation = row.incrementTaxation
        this.updateForm.qlocaltaxSurchargeTaxation = row.localtaxSurchargeTaxation
        this.updateForm.qservicecharge = row.servicecharge
        this.updateForm.qactualDeductibleExpenses = row.actualDeductibleExpenses
        this.updateForm.qinvoiceAmount = row.invoiceAmount
        this.updateForm.qtotalExpenses = row.totalExpenses
        this.updateForm.qdeductibleExpenses = row.deductibleExpenses

        this.updateForm.hname = row.name
        this.updateForm.hidno = row.idno
        this.updateForm.hsalary = row.payrollPayable/100
        this.updateForm.htax = row.personaltax/100
        this.updateForm.newSalaryAmount = row.netSalary/100
        this.updateForm.hincrementTaxation = row.incrementTaxation/100
        this.updateForm.hlocaltaxSurchargeTaxation = row.localtaxSurchargeTaxation/100
        this.updateForm.hservicecharge = row.servicecharge/100
        this.updateForm.hactualDeductibleExpenses = row.actualDeductibleExpenses/100
        this.updateForm.hinvoiceAmount = row.invoiceAmount/100
        this.updateForm.htotalExpenses = row.totalExpenses/100
        this.updateForm.hdeductibleExpenses = row.deductibleExpenses/100

        
        this.updateForm.uid =  row.uid;
        this.updateForm.id =  row.id;
        let flag = this;
        let pam  = {
          id : row.uid
        }
        this.api.salaryApi.salaryHistory(pam).then(res =>{
          res = res.data
          if(res.code==200){
            flag.logTableData = res.data.records;
          }
        })
        this.dialogFormVisible = true;
      },
      //submitUpdate
      async submitUpdate(){
        await this.$refs['updateForm'].validate((valid) => {
          if (valid) {
            let flag = this;
            this.updateForm.sessionId = this.$getCookie("sessionId");
            flag.updateForm.hname = flag.updateForm.hname
            flag.updateForm.hidno = flag.updateForm.hidno
            flag.updateForm.hsalary =  accMul(flag.updateForm.hsalary,100);
            flag.updateForm.htax = accMul(flag.updateForm.htax,100);
            flag.updateForm.newSalaryAmount = accMul(flag.updateForm.newSalaryAmount,100);
            flag.updateForm.hincrementTaxation = accMul(flag.updateForm.hincrementTaxation,100);
            flag.updateForm.hlocaltaxSurchargeTaxation = accMul(flag.updateForm.hlocaltaxSurchargeTaxation,100);
            flag.updateForm.hservicecharge = accMul(flag.updateForm.hservicecharge,100);
            flag.updateForm.hactualDeductibleExpenses = accMul(flag.updateForm.hactualDeductibleExpenses,100);
            flag.updateForm.hinvoiceAmount = accMul(flag.updateForm.hinvoiceAmount,100);
            flag.updateForm.htotalExpenses = accMul(flag.updateForm.htotalExpenses,100);
            flag.updateForm.hdeductibleExpenses = accMul(flag.updateForm.hdeductibleExpenses,100);
            this.api.salaryApi.updateSalaryById(this.updateForm).then(res =>{
              res = res.data;
              if(res.code == 200){
                flag.loadTable();
                flag.$message({
                  message: '修改成功！',
                  type: 'success'
                });
                flag.updateForm.uid='';
                flag.updateForm.remark='';
                flag.updateForm.newSalaryAmount='';
                flag.updateForm.htax='';
              }else{
                flag.$message.error(res.msg);
              }
            })
            this.dialogFormVisible = false;
          } else {
            return false;
          }
        });
      },
      // closedDialog
      closedDialog(){
        this.resetForm('updateForm');
        this.updateForm.newSalaryAmount = undefined;
        this.updateForm.oldSalaryAmount = undefined;
        this.updateForm.qtax = undefined;
        this.updateForm.htax = undefined;
      },
      // 重置表单
      resetForm(form){
        this.$refs[form].resetFields();
      },
      // 重置
      onReset(){
        console.log('onReset!');
        //this.queryForm.id='';
        this.queryForm.uid='';
        this.queryForm.idno='';
        this.queryForm.name='';
      },
      //current-change
      currentChange(page){
        this.queryForm.pageNo = page;
        this.loadTable();
      },

      // size-change
      sizeChange(size){
        this.queryForm.pageSize = size;
        this.loadTable();
      },
      tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
      },
      tableCell(column) {
        return "height:40px;background-color:#fafafa;color:#000000;padding:0";
      },
      isEntity(val){
        if(val == null || val == undefined || val === ''){
            return true;
        }else{
            return false;
        }
      },
      //除法函数
      accDiv(arg1, arg2){
         if(arg1!=null){
          var t1 = 0, t2 = 0, r1, r2;
          try {
            t1 = arg1.toString().split(".")[1].length;
          }
          catch (e) {
          }
          try {
            t2 = arg2.toString().split(".")[1].length;
          }
          catch (e) {
          }
          r1 = Number(arg1.toString().replace(".", ""));
          r2 = Number(arg2.toString().replace(".", ""));
          return (r1 / r2) * Math.pow(10, t2 - t1);
         }
         else{
          return null
         }
        }
    }
}
</script>

<style scoped>
.inputCode{
  margin-right: 5px;
}
.submitMsg{
      color: red;
      font-size: 1.5rem;
      
    }
    .submitButton{
      background-color: red;
      border: none;
    }

#page {
  float: right;
  margin-top: 15px;
}

#quotaReviewList {
  overflow: hidden;
  background-color: white;
  padding: 15px 20px 100px;
}

>>> .el-dialog__body {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgb(228, 228, 228, 1);
  padding-left: 0;
  padding-right: 0;
}

>>> .el-drawer.rtl {
  overflow: scroll;
}

.wageadjustment{
  margin: 0 50px;
}
.wageadjustments{
  margin: 30px 50px;
  text-align: left;
}

</style>

