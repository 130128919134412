export default {
	install(Vue){
		//定义全局指令
		Vue.directive('permissions',{
			//指令与元素成功绑定时（一上来）
			bind(element,binding,vnode){
				// element.style.display = 'none'
				permissions(element,binding,vnode);
			},
			//指令所在元素被插入页面时
			inserted(element,binding,vnode){
				permissions(element,binding,vnode);
			},
			//指令所在的模板被重新解析时
			update(element,binding,vnode){
				permissions(element,binding,vnode);
			}
		})
	}
}

function permissions(element,binding,vnode){

	let vue = vnode.context.$bus;
	let arr = vnode.context.$bus.permissionsButton;
	let value = binding.value;
	console.log(value);
	if(arr.indexOf(value) == -1){
		element.style.display = 'none';
	}else{
		element.style.display = 'initial';
	}
	vue.$forceUpdate();
}