<template>
  <div id="productManagementList" v-loading="loading">
    <div id="button">
      <el-row>
        <el-col :span="1">
          <el-button
            type="primary"
            align="left"
            icon="el-icon-plus"
            @click="addProductButton"
            class="button_size"
            v-if="funArr.indexOf('添加产品') !== -1"
            >添加产品</el-button
          >
        </el-col>
      </el-row>
    </div>

    <div id="addProduct">
      <el-dialog :visible.sync="addProductDialog" width="45%" title="添加产品">
        <!-- <h3>添加产品</h3> -->
        <div class="form">
          <el-form
            ref="addProductForm"
            :model="addProductForm"
            label-width="80px"
          >
            <el-form-item label="产品代码" align="left">
              <el-input v-model="addProductForm.code"></el-input>
            </el-form-item>
            <el-form-item label="产品名称" align="left">
              <el-input v-model="addProductForm.name"></el-input>
            </el-form-item>
            <el-form-item label="产品描述" align="left">
              <el-input
                v-model="addProductForm.detail"
                type="textarea"
                rows="2"
              ></el-input>
            </el-form-item>
            <el-form-item label="生效日期" align="left">
              <el-date-picker
                v-model="addProductForm.effectiveDate"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="失效日期" align="left">
              <el-date-picker
                v-model="addProductForm.invalidDate"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
        </div>
        <div id="anniu" slot="footer" class="dialog-footer">
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary" @click="onSubmit">添加</el-button>
        </div>
      </el-dialog>
    </div>

    <div id="list">
      <template>
        <el-table
          :data="productList"
          id="table"
          :header-cell-style="tableHeader"
          :cell-style="tableCell"
          border
        >
          <el-table-column prop="num" label="序号" align="center">
            <template slot-scope="scope">
              <span>{{
                scope.$index + 1 + (page.pageNo - 1) * page.pageSize
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="code"
            label="产品代码"
            :show-overflow-tooltip="true"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="产品名称"
            :show-overflow-tooltip="true"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="fundSide"
            label="产品资金方"
            :show-overflow-tooltip="true"
            align="center"
          >
            <template slot-scope="scope">
              <span
                v-for="(item, index) in fundSideTypeList"
                v-bind:key="index"
              >
                <span
                  v-for="(item2, index2) in scope.row.fundSide"
                  v-bind:key="index2"
                >
                  <span
                    v-if="
                      item2 == item.datavalue &&
                      scope.row.fundSide.length != index2 + 1
                    "
                    >{{ item.name }}、</span
                  >
                  <span v-else-if="item2 == item.datavalue"
                    >{{ item.name }}
                  </span>
                </span>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="detail"
            label="产品详述"
            :show-overflow-tooltip="true"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="effectiveDate"
            label="生效日期"
            :show-overflow-tooltip="true"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="invalidDate"
            label="失效日期"
            :show-overflow-tooltip="true"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="state"
            label="状态"
            :show-overflow-tooltip="true"
            align="center"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.isValid == 1">
                <div
                  v-for="(item, index) in productStateList"
                  v-bind:key="index"
                >
                  <div v-if="item.datavalue == scope.row.state">
                    {{ item.name }}
                  </div>
                </div>
              </div>
              <div v-if="scope.row.isValid == 0">
                <div
                  v-for="(item, index) in tenantProductIsValidList"
                  v-bind:key="index"
                >
                  <div v-if="item.datavalue == scope.row.isValid">
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="250" align="center">
            <template slot-scope="scope">
              <el-link
                type="primary"
                icon="el-icon-fetchByIdSysUserList"
                @click="whiteListManagement(scope.row)"
                :underline="false"
                size="medium"
                v-if="funArr.indexOf('白名单管理') !== -1"
                >白名单管理</el-link
              >
              <el-link
                type="primary"
                icon="el-icon-fetchByIdSysUserList"
                @click="productDetails(scope.row)"
                :underline="false"
                size="medium"
                class="margin_left_right"
                v-if="funArr.indexOf('产品详情') !== -1"
                >产品详情</el-link
              >
              <el-link
                v-if="scope.row.isValid == 1 && funArr.indexOf('手动下架') !== -1"
                type="primary"
                icon="el-icon-fetchByIdSysUserList"
                @click="manualDismounting(scope.row)"
                :underline="false"
                size="medium"
                class="orange"
                style="color: #ffac1d"
                >手动下架</el-link
              >
              <el-link
                v-if="scope.row.isValid == 0 && funArr.indexOf('恢复上架') !== -1"
                type="primary"
                icon="el-icon-fetchByIdSysUserList"
                @click="returnToShelves(scope.row)"
                :underline="false"
                size="medium"
                class="green"
                style="color: #09bf88"
                >恢复上架</el-link
              >
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          id="page"
          background
          @size-change="handleSizeChange"
          @current-change="currentChange"
          :page-sizes="[10, 15, 20]"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
          :current-page="page.pageNo"
        >
        </el-pagination>
      </template>
    </div>
  </div>
</template>
<script>
import { preLoanManagementApi } from "@/service/moudules/preLoanManagementApi";
export default {
  data() {
    return {
       funArr: [],
      loading: false,
      fundSideType: {
        code: "fundSideType",
      },
      fundSideTypeList: [],
      addProductForm: {
        code: "",
        name: "",
        detail: "",
        effectiveDate: "",
        invalidDate: "",
      },
      productList: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      productState: {
        code: "productState",
      },
      productStateList: [],
      tenantProductIsValid: {
        code: "tenantProductIsValid",
      },
      tenantProductIsValidList: [],
      addProductDialog: false,
    };
  },
  mounted: function () {
    this.products();
    this.productStates(this.productState);
    this.tenantProductIsValids(this.tenantProductIsValid);
    this.fundSideTypes(this.fundSideType);
  },
      created:function(){
	
	this.menuPermission()
},
  methods: {
async menuPermission() {
		let name = "产品管理";
		let params = {
			id: this.$getCookie("sessionId"),
			menuName: name
		}
		let response = await this.api.platformApi.menuPermission(params);

		console.log(name + "页面功能列表start---")
		console.log(response.data.data)
		console.log(name + "页面功能列表end---")
		this.funArr = response.data.data
		console.log(this.funArr)
	},
    addProductButton(){
      this.addProductForm.code = "";
      this.addProductForm.name = "";
      this.addProductForm.detail = "";
      this.addProductForm.effectiveDate = "";
      this.addProductForm.invalidDate = "";
      this.addProductDialog = true;
    },
    whiteListManagement(row) {
      console.log("row", row);
      localStorage.setItem(
        "whiteList",
        JSON.stringify({
          product: row.id,
          creditSettingId: row.creditSettingId,
        })
      );
      this.$router.push({ name: "whiteList" });
    },
    cancel() {
      this.addProductDialog = false;
    },
    notEmpty() {
      let flag = true;
      console.log("addProductForm", JSON.stringify(this.addProductForm));
      for (let key in this.addProductForm) {
        if (!this.addProductForm[key]) {
          if (key == "detail") {
            console.log("详情空" + key);
            continue;
          }
          console.log("addProductForm空" + key);
          return false;
        }
      }
      return flag;
    },
    async fundSideTypes(param) {
      let response = await this.api.platformApi.findDictionariesItemList(param);
      console.log("产品资金方数据字典返回结果", response);
      if (response.data.data != null) {
        this.fundSideTypeList = response.data.data;
      }
      console.log("产品资金方数据字典集合", this.fundSideTypeList);
    },
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0";
    },
    async manualDismounting(row) {
      var params = {
        productId: row.id,
      };
      let response = await this.api.preLoanManagementApi.updateTenanProductOfftheshelf(
        params
      );
      console.log("====response.data.data======", response.data.data);
      this.products();
    },
    async returnToShelves(row) {
      var params = {
        productId: row.id,
      };
      let response = await this.api.preLoanManagementApi.updateTenanProductPutontheshelves(
        params
      );
      console.log("====response.data.data======", response.data.data);
      this.products();
    },
    async products() {
      var params = {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
      };
      let response = await this.api.preLoanManagementApi.findProductLinkTenantProduct(
        params
      );
      console.log("==========", JSON.stringify(response.data));
      if (null != response.data.data.records) {
        this.productList = response.data.data.records;
      }
      console.log("total" + response.data.data.total);
      this.page.total = response.data.data.total;
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.products();
    },
    currentChange(pageNo) {
      this.page.pageNo = pageNo;
      this.products();
    },
    async productStates(param) {
      let response = await this.api.platformApi.findDictionariesItemList(param);
      console.log("产品数据字典返回结果", response);
      if (response.data.data != null) {
        this.productStateList = response.data.data;
      }
      console.log("产品数据字典集合", this.productStateList);
    },
    async tenantProductIsValids(param) {
      let response = await this.api.platformApi.findDictionariesItemList(param);
      console.log("产品租户数据字典返回结果", response);
      if (response.data.data != null) {
        this.tenantProductIsValidList = response.data.data;
      }
      console.log("产品租户数据字典集合", this.tenantProductIsValidList);
    },
    productDetails(row) {
      localStorage.setItem(
        "id",
        JSON.stringify({
          id: row.id,
          creditSettingId:row.creditSettingId,
        })
      );
      this.$router.push({ name: "productManagementDetails" });
    },
    compareTime(date1, date2) {
      var flag = true;
      if (date1.getTime() > date2.getTime()) {
        return false;
      }
      return flag;
    },
    async onSubmit() {
      var notEmpty = this.notEmpty();
      console.log("notEmpty", notEmpty);
      if (!notEmpty) {
        this.$message.error("必填项为空");
        return;
      }
      var flag = this.compareTime(
        this.addProductForm.effectiveDate,
        this.addProductForm.invalidDate
      );
      if (!flag) {
        this.$message.error("生效时间不可大于失效时间");
        return;
      }
      this.addProductDialog = false;
      this.loading = true;
      let response = await this.api.preLoanManagementApi.createProduct(
        this.addProductForm
      );
      console.log("==========", JSON.stringify(response.data));
      if (null != response.data.data) {
        console.log("productId" + response.data.data);
        localStorage.setItem(
          "id",
          JSON.stringify({
            id: response.data.data,
            productId: response.data.data,
            state: 1,
          })
        );
        setTimeout(() => {
          this.loading = false;
          this.$message.success("产品创建成功");
          this.$router.push({ name: "addProduct" });
        }, 3000);
      } else {
        this.loading = false;
        this.$message.error("产品创建失败");
      }
    },
  },
};
</script>
<style scoped>
#productManagementList {
  background-color: white;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 100px;
}
#table {
  margin-top: 10px;
  width: 100%;
  background-color: rgba(29, 144, 255, 0);
}
#page {
  float: right;
  margin-top: 15px;
}
.orange {
  color: #ffac1d;
}
.green {
  color: #09bf88;
}
.form {
  padding: 0 25%;
}
>>> .el-dialog__body {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgb(228, 228, 228, 1);
  padding-left: 0;
  padding-right: 0;
}
>>> .el-dialog__header {
  text-align: left;
}
#anniu {
  border: 0;
}
.button_size {
  margin-right: 15px;
  padding: 0px;
  width: 120px;
  height: 32px;
  border-radius: 5px;
}
.margin_left_right {
  margin: 0 10px;
}
</style>