<template>
  <div id="sysuserDetails">
    <div>
      <el-form label-width="150px" ref="form">
        <div>
          <el-table :data="payOBjectList1" style="width: 100%">
            <el-table-column
              width="55"
              align="center"
              :reserve-selection="true"
            >
              <!-- :selectable="selectInit" -->
            </el-table-column>
            <el-table-column prop="序号" type="index" width="50">
            </el-table-column>
            <el-table-column
              prop="proofId"
              label="proofId"
              :show-overflow-tooltip="true"
              align="center"
              v-if="show"
            ></el-table-column>
            <el-table-column
              prop="stateInvoiceNo"
              label="发票代码"
              :show-overflow-tooltip="true"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="stateInvoiceSerialNo"
              label="发票号码"
              :show-overflow-tooltip="true"
              align="center"
            ></el-table-column>

            <el-table-column
              prop="amountWithTax"
              label="发票含税金额"
              :show-overflow-tooltip="true"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.amountWithTax / 100 }}
              </template>
            </el-table-column>
            <el-table-column
              label="发票待支付金额"
              :show-overflow-tooltip="true"
              align="center"
            >
              <template slot-scope="scope">
                {{ (scope.row.amountWithTax - scope.row.paidAmount) / 100 }}
              </template>
            </el-table-column>
            <el-table-column
              prop="paidAmount"
              label="已支付金额"
              :show-overflow-tooltip="true"
              align="center"
              v-if="show"
            >
              <template slot-scope="scope">
                {{ scope.row.paidAmount / 100 }}
              </template>
            </el-table-column>
            <el-table-column
              label="支付金额"
              :show-overflow-tooltip="true"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.amount"
                  @change="textChange"
                ></el-input>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="padding-top: 40px"></div>
        <div>
          <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
                <el-form-item label="户名:">
                  <el-input disabled v-model="name"></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"><div class="main_class"></div></el-col>
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
                <el-form-item label="电子账户:">
                  <el-input disabled v-model="bankNum"></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"><div class="main_class"></div></el-col>
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
                <el-form-item label="开户行:">
                  <el-input disabled v-model="branchName"></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"><div class="main_class"></div></el-col>
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
                <el-form-item label="可用余额:">
                  <el-input disabled v-model="useamt"></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"><div class="main_class"></div></el-col>
          </el-row>

          <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
                <el-form-item label="本次支付金额:">
                  <el-input disabled :value="invoiceSurplusAmount"></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"
              ><div class="main_class">（币种：CNY；单位：元）</div></el-col
            >
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="16" :md="12"
              ><div>
                <el-form-item label="支付通道:">
                  <el-select placeholder="请选择" v-model="channelType">
                    <el-option v-for="channelType in channelTypes"  :label="channelType.name" :value="channelType.datavalue" :key="channelType.datavaluex"/>
                  </el-select>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"/>
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :span="12"
              ><div>
                <el-form-item label="手机号:">
                  <el-input v-model="mobile"></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"></el-col>
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :span="12"
              ><div>
                <el-form-item label="验证码:">
                  <el-input v-model="verificationCode"></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="5"
              ><div>
                <div class="main_buttom_class">
                  <div>
                    <el-button type="primary" @click="sendSms">{{
                      timeStr
                    }}</el-button>
                  </div>
                </div>
              </div></el-col
            >
          </el-row>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import platformConstant from "@/util/PlatformConstant.js";
import * as math from 'mathjs';
export default {
  props: ["bulkPayment"],
  mounted: function () {
    this.findDictionariesItemList(this.diction);
    this.findDictionariesEnableItemList(this.channelDiction).then(result=>this.channelTypes = result)
    this.proofId = [];
    // this.totalNotPaidAmount=this.bulkPayment
    //发票不含税金额总和
    // this.amountExcludingTax = this.bulkPayment.amountExcludingTax / 100;
    //发票未支付金额总额
    // this.invoiceSurplusAmount = this.bulkPayment.invoiceSurplusAmount / 100;
    // this.invoiceSurplusAmount1 = this.bulkPayment.invoiceSurplusAmount / 100;
    // this.proofId = this.bulkPayment.proofId;
    console.log("zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz");
    console.log(this.bulkPayment);
    for (var i = 0; i < this.bulkPayment.length; i++) {
      // this.payOBjectList1.push({ amount: "" });
      let obj = new Object();
      obj.amount =
        (this.bulkPayment[i].amountWithTax - this.bulkPayment[i].paidAmount) /
        100;
      obj.stateInvoiceNo = this.bulkPayment[i].stateInvoiceNo;
      obj.stateInvoiceSerialNo = this.bulkPayment[i].stateInvoiceSerialNo;
      obj.amountWithTax = this.bulkPayment[i].amountWithTax;
      obj.paidAmount = this.bulkPayment[i].paidAmount;
      obj.proofId = this.bulkPayment[i].proofId;
      obj.invoiceId = this.bulkPayment[i].proofId;
      
      this.payOBjectList1.push(obj);
    }
    //this.payOBjectList1 = this.bulkPayment;
    console.log("gggggggggggggggggggggg");
    console.log(this.payOBjectList1);
    //获取验证码参数
    this.findAccountBalance("", "");
    // this.name = "广东一站网络科技有限公司";
    // this.bankNum = "999156009900006835000852";
    // this.branchName = "郑州银行长椿路支行";
    // this.mobile = "15816121608";
    this.amuont = this.invoiceSurplusAmount1 / 100;

    this.textChange();
  },
  data() {
    return {
      amounting: 0,
      amount: [],
      diction: {
        code: "creditProofState",
      },
      channelDiction:{
        code:"channel"
      },
      dictionList: [],
      channelTypes:[],
      show: false,
      payOBjectList1: [],
      //proofId
      proofId: [],
      //应付时间
      payDate: "",
      //发票剩余金额总额
      invoiceSurplusAmount: "",
      invoiceSurplusAmount1: "",
      //发票不含税金额总和
      amountExcludingTax: "",
      mobile: "15816121608",
      verificationCode: "",
      name: "",
      bankNum: "",
      amuont: "",
      branchName: "",
      useamt: "",
      channelType:'',
      timeStr: "获取短信验证码",
      //验证码
      verificationCode: "",
      //
      verificationCodeFlag: false,
    };
  },

  methods: {
            	printFn(value) {
                const precision = 14
                return Number(math.format(value, precision))
            },
    async findDictionariesItemList(param) {
      this.loading = true;
      let response = await this.api.platformApi.findDictionariesItemList(param);
      console.log("数据字典返回结果", response);
      this.dictionList = response.data.data;
      console.log("数据字典集合", this.dictionList);
    },
    async findDictionariesEnableItemList(param){
      let response = await this.api.platformApi.findDictionariesEnableItemList(param);
      console.log("===数据字典返回结果", response);
      console.log("数据字典集合", response.data.data);
      return response.data.data;
    },
    async findAccountBalance(name, account) {
      console.log("查询可用余额参数", name, account);
       var param = {
        subNo: platformConstant.yzw_subNo,
        cltNm: platformConstant.yzw_cltNm,
        channel: platformConstant.channel
      };
      console.log("查询可用余额参数", this.param);
      let response = await this.api.platformApi.findYzwWallet(param);
      console.log("可用金额返回结果", response.data);
      if (response.data.data == null || response.data.data == undefined) {
        this.useamt = 0;
      } else {
        this.useamt = response.data.data.findAccountBalance.data.useamt / 100;
      }
      this.name = response.data.data.result.name;
      this.bankNum = JSON.parse(response.data.data.result.walletComment).rel_acct_no;
      this.branchName = response.data.data.bankName;
      this.mobile = platformConstant.mobile;
    },
    //短信取消
    cancel() {
      this.messageBox = false;
    },
    //短信确认放款
    confirm() {
      this.verifySMS();
    },
    //验证短信
    async verifySMS() {
      var smsParam = {
        mobile: this.mobile,
        verificationCode: this.verificationCode,
      };
      let smsResponse = await this.api.platformApi.authSendSms(smsParam);
      console.log("验证验证码结果", smsResponse.data);
      if (smsResponse.data.code != 200) {
        this.$message.error("验证码错误");
        this.fullscreenLoading = false;
        this.verificationCodeFlag = false;
      } else {
        this.verificationCodeFlag = true;
        this.$message.success("验证码成功");
      }
    },
    //发送验证码
    async sendSms() {
      if (this.timeStr !== "获取短信验证码" && this.timeStr !== "重新获取") {
        return;
      }
      var params = {
        mobile: this.mobile,
        smsType: 1,
      };
      console.log("sendsms====", params);
      let response = await this.api.platformApi.sendSms(params);
      console.log("验证码结果:", response);
      if (response.data.code != 200) {
        this.$message.error("验证码发送失败");
        return;
      }
      this.$message.success("验证码已发送");
      this.send_code();
    },
    send_code: function () {
      this.show = true;
      var _this = this;
      var i = 60;
      var timer = setInterval(function () {
        i--;
        _this.timeStr = i + "秒";
        if (i == 0) {
          clearInterval(timer);
          _this.timeStr = "重新获取";
        }
      }, 1000);
    },
    accMul(arg1, arg2) {
      var m = 0,
        s1 = arg1.toString(),
        s2 = arg2.toString();
      try {
        m += s1.split(".")[1].length;
      } catch (e) {}
      try {
        m += s2.split(".")[1].length;
      } catch (e) {}
      return (
        (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
        Math.pow(10, m)
      );
    },
    change(value, index) {
      console.log(value, index);
    },
    textChange() {
      this.invoiceSurplusAmount = 0;
      for (let index in this.payOBjectList1) {
        // this.invoiceSurplusAmount += Number(this.payOBjectList1[index].amount);
         this.invoiceSurplusAmount=this.printFn(this.invoiceSurplusAmount+Number(this.payOBjectList1[index].amount));
      }
    },
  },
};
</script>
<style scoped>
.main_buttom_class {
  padding-left: 15px;
}
.main_class {
  /* border: solid;
  margin: 0 auto; */
  padding-top: 5px;
}
#sysuserDetails .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
#sysuserDetails .avatar-uploader .el-upload:hover {
  border-color: #1d90ff;
}
#sysuserDetails .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
#sysuserDetails .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
#sysuserDetails .el-input > input {
  height: 36px;
  border-radius: 5px;
}
#sysuserDetails .el-select {
  width: 100%;
}

#anniu {
  border: 0;
}

#anniu button {
  margin-left: 15px;
  padding: 0px;
  width: 120px;
  height: 40px;
  border-radius: 5px;
}
</style>