<template>
  <div id="loanProcessingDetails">
    <el-row :gutter="12">
      <el-col :pull="11">
        <span>发票信息</span>
      </el-col>
    </el-row>
    <div class="table padding_class">
      <table>
    
        <tr>
          <td>发票含税金额</td>
          <td>{{ loaninformationRep.amountWithTax / 100 }}</td>
        </tr>
        <tr>
          <td>发票不含税金额</td>
          <td>{{ loaninformationRep.amountNotWithTax / 100 }}</td>
        </tr>
        <tr>
          <td>发票税率</td>
          <td>{{ loaninformationRep.taxRate / 1000000 }}</td>
        </tr>
        <tr>
          <td>发票税额</td>
          <td>{{ loaninformationRep.taxAmount / 100 }}</td>
        </tr>
        <tr>
          <td>发票代码</td>
          <td>{{ loaninformationRep.stateInvoiceNo }}</td>
        </tr>
        <tr>
          <td>发票号码</td>
          <td>{{ loaninformationRep.stateInvoiceSerialNo  }}</td>
        </tr>
      </table>

      <div class="marginCss">
        <el-row :gutter="1">
          <el-col :pull="11">
            <span class="leftCss">额度使用信息</span>
          </el-col>
        </el-row>
      </div>

      <el-table
        :data="list"
        id="personTable"
        :header-cell-style="tableHeader"
        :cell-style="tableCell"
        border
      >
        <el-table-column
          label="序号"
          type="index"
          align="center"
          width="50"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="assetsId"
          label="贷款订单号"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="useAmount"
          label="确权金额"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.useAmount / 100 }}
          </template>
        </el-table-column>
        <el-table-column
          prop="creditProofAssetsState"
          label="额度状态"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <div v-for="(item, index) in dictionList" v-bind:key="index">
              <div v-if="item.datavalue == scope.row.creditProofAssetsState">
                {{ item.name }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="lendingTime"
          label="借款时间"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="expectedEndDate"
          label="应还时间"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { findInvoiceInfoByProofId } from "@/service/moudules/postLoanManagementApi";
import { findCreditProofAssetsUseInfoByProofId } from "@/service/moudules/postLoanManagementApi";
import { findDictionariesItemList } from "@/service/moudules/platformApi";
export default {
  data() {
    return {
      proofId: "",
      loaninformationRep: {
        amountWithTax: "",
        amountNotWithTax: "",
        taxRate: "",
        taxAmount: "",
        stateInvoiceSerialNo: "",
        stateInvoiceNo:""
      },
      list: [],
      diction: {
        code: "creditProofAssetsState",
      },
      dictionList: [],
    };
  },
  mounted: function () {
    this.proofId = localStorage.getItem("proofId");
    this.findCreditProofAssetsUseInfoByProofId();
    this.findDictionariesItemList(this.diction);
    this.findInvoiceInfoByProofId()
  },
  methods: {
    async findDictionariesItemList(param) {
      let response = await this.api.platformApi.findDictionariesItemList(param);
      console.log("数据字典返回结果", response);
      this.dictionList = response.data.data;
      console.log("数据字典集合", this.dictionList);
    },
    async findInvoiceInfoByProofId() {
      console.log("formInline" + this.formInline);
      var params = {
        id: this.proofId,
      };
      let response = await this.api.postLoanManagementApi.findInvoiceInfoByProofId(
        params
      );
      // console.log("==========", JSON.stringify(response.data));
      this.loaninformationRep = response.data.data;
    },
    async findCreditProofAssetsUseInfoByProofId() {
      console.log("formInline" + this.formInline);
      var params = {
        id: this.proofId,
      };
      let response = await this.api.postLoanManagementApi.findCreditProofAssetsUseInfoByProofId(
        params
      );
      // console.log("==========", JSON.stringify(response.data));
      this.list = response.data.data;
    },
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;color:#000000;padding:0";
    },

    close() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
#loanProcessingDetails {
  background-color: white;
  padding: 15px 0 100px;
}
#loanProcessingDetails table,
td {
  border: 1px solid #e8e8e8;
  border-collapse: collapse;
  line-height: 35px;
  margin-left: 30px;
  margin-top: 3px;
}
table {
  width: 90%;
}
td:nth-child(odd) {
  background: #fafafa;
  width: 13%;
  text-align: center;
  font-size: 13px;
}
td:nth-child(even) {
  text-align: left;
  color: #7c7c7c;
  font-size: 13px;
}
#personTable {
  width: 90%;
  margin-top: 15px;
  margin-left: 30px;
  background-color: rgba(29, 144, 255, 0);
}
#loanProcessingDetails span {
  font-size: 15px;
}
#loanProcessingDetails .marginCss {
  margin-top: 35px;
}
#loanProcessingDetails .leftCss {
  padding-left: 20px;
}
#loanProcessingDetails .buttonCss {
  border-radius: 5px;
}
.buttonCss button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
#loanProcessingDetails .fuInfo {
  margin-top: 5px;
}
.padding_class {
  margin-top: 15px;
}
</style>