<template>
	<div class="search">
		<div class="quanju">
		<template>
			<el-form :inline="true" :model="transferline" class="demo-form-inline">
				<el-row :gutter="24">
					<el-col :span="8" align="left">
						<div class="grid-content bg-purple">
							<el-form-item label="付款用户:">
								<el-input v-model="transferline.pnm" placeholder="请输入付款用户查询"></el-input>
							</el-form-item>
						</div>
					</el-col>
					
					<el-col :span="12" align="left">
						<div class="grid-content bg-purple">
							<el-form-item label="交易时间:">
								<el-date-picker v-model="transferline.startDate" type="date" placeholder="起" editable pattern="yyyy-MM-dd"
								 value-format="yyyy-MM-dd"></el-date-picker> - 
								<el-date-picker v-model="transferline.endDate" type="date" placeholder="止" editable pattern="yyyy-MM-dd"
								 value-format="yyyy-MM-dd"></el-date-picker>
							</el-form-item>
						</div>
					</el-col>
					
				</el-row>
				<el-row :gutter="24">
					<el-col :span="8"  align="left">
						<div class="grid-content bg-purple">
							<el-form-item label="收款用户:">
								<el-input v-model="transferline.rcltNm" placeholder="请输入收款用户查询"></el-input>
							</el-form-item>
						</div>
					</el-col>
					

					<!-- <el-col :span="10" align="left">
						<div class="grid-content bg-purple">
							<el-form-item label="交易类型:">
								<el-select v-model="transferline.transferType" clearable placeholder="请选择">
										<el-option
											v-for="item in itemList"
											:key="item.id"
											:label="item.name"
											:value="item.datavalue">
											</el-option>
								</el-select>
							</el-form-item>
						</div>
					</el-col> -->
					<el-col :span="6">
					<div>
						<el-form-item class="buttonbox">
							<el-button type="primary" @click="transferList" icon="el-icon-search">查询</el-button>
							<el-button type="primary" @click="rest" icon="el-icon-refresh-right">重置</el-button>
						</el-form-item>
					</div>
					</el-col>
				</el-row>
			</el-form>
		</template>
		</div>

		<el-table id="table" :data="list" :header-cell-style="tableHeader" :cell-style="tableCell" 
		:default-sort="{prop: 'timestamp', order: 'descending'}" border>
			<!-- <el-table-column type="index" prop="id" label="编号" :show-overflow-tooltip="true" align="center" width="50px">
				<template slot-scope="scope">
					<span>{{(scope.$index+1) + ((queryParams.pageNo-1) * queryParams.pageSize)}}</span>
				</template>
			</el-table-column> -->
			<el-table-column prop="id" label="序号" align="center">
            <template slot-scope="scope">
              <span>{{
                scope.$index +
                1 +
                (page.pageNo - 1) * page.pageSize
              }}</span>
            </template>
          	</el-table-column>
			<el-table-column prop="threeId" label="交易流水号" :show-overflow-tooltip="true" align="center"></el-table-column>
			<el-table-column prop="amount" label="交易金额" :show-overflow-tooltip="true" align="center" >
				<template slot-scope="scope">
					{{scope.row.amount/100}}
				</template>
			</el-table-column>
			<el-table-column prop="pnm" label="付款用户" :show-overflow-tooltip="true" align="center"></el-table-column>
			<el-table-column prop="rcltNm" label="收款用户" :show-overflow-tooltip="true" align="center"></el-table-column>
			
			<el-table-column prop="transferType" label="交易类型" :show-overflow-tooltip="true" align="center">
			<template slot-scope="scope">
				<div v-for="(item, index) in itemList" v-bind:key="index" >
					<div v-if="item.datavalue == scope.row.transferType">
						{{  item.name }}
					</div>
				</div>
			</template>
			</el-table-column>
			<el-table-column prop="transferStatus" label="交易状态" :show-overflow-tooltip="true" align="center">
				<template slot-scope="scope">
				<div v-for="(item, index) in itemLists" v-bind:key="index" >
					<div v-if="item.datavalue == scope.row.transferStatus">
						{{  item.name }}
					</div>
				</div>
			</template>
			</el-table-column>
			<el-table-column prop="" label="交易说明" :show-overflow-tooltip="true" align="center"></el-table-column>
            <el-table-column prop="createTime" label="交易时间" :show-overflow-tooltip="true" align="center"></el-table-column>
		</el-table>

		<el-pagination
          id="page"
          background
          @size-change="handleSizeChange"
          @current-change="currentChange"
          :page-sizes="[5, 10, 20]"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>

	</div>
</template>
<script>
import { findDictionariesItemList } from "@/service/moudules/platformApi";

	export default {
		data() {
			return {
				transferline:{
				pnm:null,
				rcltNm:null,
				startDate:"",
				endDate:"", 
				transferType:null
			},
			page:{
				pageNo:1,
				pageSize:10,
			},
			diction: {
				code: "transferType",
			},
			dictions: {
				code: "transferStatus",
			},
			total:0,
			list:[],
			itemList: [],
			itemLists:[],
			show: false,
				
			};
		},

		mounted: function () {
			let param = JSON.parse(localStorage.getItem("code"));
			this.transferList();
			this.findDictionariesItemList(this.diction);
			this.findDictionaries(this.dictions)
		},
		
		methods: {


			async transferList(){
				var params = {
					pnm: this.transferline.pnm,
					rcltNm: this.transferline.rcltNm,
					startDate: this.transferline.startDate,
					endDate: this.transferline.endDate,
					transferType: this.transferline.transferType,
					pageSize: this.page.pageSize,
					pageNo: this.page.pageNo,
      			};
			  let response = await this.api.platformApi.transferList(params);
				console.log("----------------transferList",response.data)
				this.list = response.data.data.records;
				
				this.total = response.data.data.total;
			},

			 async findDictionariesItemList(param) {
				let response = await this.api.platformApi.findDictionariesItemList(param);
				console.log("数据字典", response);
				this.itemList = response.data.data;
			},

			async findDictionaries(param) {
				let response = await this.api.platformApi.findDictionariesItemList(param);
				console.log("数据字典2", response);
				this.itemLists = response.data.data;
			},

			rest(){
				this.transferline.pnm="",
				this.transferline.rcltNm="",
				this.transferline.startDate="",
				this.transferline.endDate="",
				this.transferline.transferType=""
    		},

            handleSizeChange(val) {
			this.page.pageSize = val;
			this.transferList();
			},
			currentChange(pageNo) {
			this.page.pageNo = pageNo;
			this.transferList();
			},

            tableHeader(column) {
				return 'height:36px;background-color:#fafafa;color:#333333;padding:0';
			},
			tableCell(column) {
				return 'height:40px;background-color:#fafafa;color:#000000;padding:0';
			},
			
		},
		
	};
</script>
<style scoped>
		.tabbox {
		text-align: left;
	}

	#table {
		background-color: rgba(29, 144, 255, 0);
	}

	.el-dialog {
		text-align: left;
	}

	>>>.el-dialog__body {
		border-top: 1px solid rgba(228, 228, 228, 1);
		border-bottom: 1px solid rgba(228, 228, 228, 1);
		padding-left: 0;
		padding-right: 0;
	}
	.quanju{
		width: 1200px;
		height: 100%;
	}

	.el-dialog button {
		margin-right: 15px;
		padding: 0px;
		width: 80px;
		height: 32px;
		border-radius: 5px;
	}

	#page{
		float: right;
		margin-top: 15px;
	}

	.search {
		background-color: white;
		padding: 15px 20px 100px 20px;
		overflow: hidden;
	}

	#grid {
		margin-right: 15px;
		padding: 0px;
		border-radius: 5px;
	}
	

	.sx {
		float: right;
		margin-right: 70px;
		margin-top: 15px
	}

	.buttonbox button {
		margin-right: 15px;
		padding: 0px;
		width: 80px;
		height: 32px;
		border-radius: 5px;
	}
	
</style>