
// 引入路由和storage工具函数
import storage from './storage.js'
import router from '../router'
 
let lastTime = new Date().getTime()
let currentTime = new Date().getTime()
let timeOut = 120 * 60 * 1000  //设置超时时间: 
 
window.onload = function () {
  window.document.body.onmouseover = function () {
    storage.setItem("lastTime", new Date().getTime())
  }
};
 
function checkTimeout() {
  currentTime = new Date().getTime()		//更新当前时间
  // console.log(currentTime - lastTime,timeOut,currentTime - lastTime > timeOut)
  // console.log(router.history.current.path)
  lastTime = storage.getItem("lastTime");
  if (currentTime - lastTime > timeOut) { //判断是否超时
 
    // 清除storage的数据(登陆信息和token)
    storage.clear()
    // 跳到登陆页
    if(router.history.current.path == '#/login') return // 当前已经是登陆页时不做跳转
    // router.push({ name: 'login' })
    window.location.href = '/#/login'
  }
}
 
export default function () {
  /* 定时器 间隔30秒检测是否长时间未操作页面 */
  window.setInterval(checkTimeout, 1000);
}