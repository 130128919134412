import '@/plugins/axios';
import Axios from 'axios';

/**
 * 发票申请-查询列表
 */
 export const invoiceList = params => axios.post("/invoice/invoiceList", params).then(data => data)

 /**
 * 发票申请-申请
 */
  export const apply = params => axios.post("/invoice/apply", params).then(data => data)

/**
 * 发票审核-查询列表
 */
 export const invoiceApproval = params => axios.post("/invoice/invoiceApproval", params).then(data => data)

/**
 * 发票申请-申请
 */
export const approval = params => axios.post("/invoice/approval", params).then(data => data)

/**
 * 发票申请-申请
 */
export const invoiceWritebackList = params => axios.post("/invoice/invoiceWritebackList", params).then(data => data)

/**
 * 发票信息保存
 */
export const saveInvoiceInfo = params => axios.post("/invoice/saveInvoiceInfo",params).then(data => data)

/**
 * 根据租户查询发票信息
 */
export const invoiceInfoList = params => axios.post("/invoice/invoiceInfoList",params).then(data => data)