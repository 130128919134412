<template>
  <!-- :model="ruleForm"
          ref="ruleForm" -->
  <div id="repaymentPage">
    <el-row type="flex" justify="center">
      <el-col :xs="24" :sm="16" :md="12">
        <el-form id="from" label-width="100px" class="demo-ruleForm">
          <input type="hidden" />
          <el-form-item label="户名:" prop="account">
            <el-input v-model="repaymentInfo.name" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="电子账户:" prop="name">
            <el-input
              v-model="repaymentInfo.bankNum"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="开户行:" prop="gender">
            <el-input
              v-model="repaymentInfo.branchName"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="可用余额:" prop="account">
            <el-input
              v-model="repaymentInfo.useamt"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="本次支付金额:" prop="account">
            <el-input
              v-model="repaymentInfo.amuont"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号码:" prop="name">
            <el-input v-model="repaymentInfo.mobile"></el-input>
          </el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item label="验证码:" prop="name">
                <el-input
                  v-model="verificationCode"
                  style="width: 150px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-button
                id="but"
                class="marginCss"
                type="primary"
                @click="sendSms"
                >{{ timeStr }}</el-button
              >
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
    <el-row id="anniu22" type="flex">
      <el-col>
        <el-button @click="close">关闭</el-button>
        <el-button
          v-loading.fullscreen.lock="fullscreenLoading"
          element-loading-text="还款中"
          type="primary"
          @click="confirmRepayment"
          >确认还款</el-button
        >
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  props: ["repaymentInfo"],

  data() {
    return {
      timeStr: "获取短信验证码",
      verificationCode: "",
      fullscreenLoading: false,
    };
  },
  mounted() {
    console.log("a====================>", this.repaymentInfo);
  },
  methods: {
    async sendSms() {
      if (this.timeStr !== "获取短信验证码" && this.timeStr !== "重新获取") {
        return;
      }
      var params = {
        mobile: this.repaymentInfo.mobile,
        smsType: 1,
      };
      let response = await this.api.platformApi.sendSms(params);
      console.log("验证码结果:", response);
      if (response.data.code != 200) {
        this.$message.error("验证码发送失败");
        return;
      }
      this.$message.success("验证码已发送");
      this.send_code();
    },
    async confirmRepayment() {
      this.fullscreenLoading = true;
      var smsParam = {
        mobile: this.repaymentInfo.mobile,
        verificationCode: this.verificationCode,
      };
      let smsResponse = await this.api.platformApi.authSendSms(smsParam);
      console.log("验证验证码结果", smsResponse.data);
      if (smsResponse.data.code != 200) {
        this.$message.error("验证码错误");
        this.fullscreenLoading = false;
        return;
      }
      var param = {
        assets: this.repaymentInfo.assetsId,
        reapymentWallet: this.repaymentInfo.compensator,
        repaymentAmount: this.repaymentInfo.amuont * 100,
        operator: this.repaymentInfo.operator,
        risk: this.repaymentInfo.risk,
      };
      let response = await this.api.platformApi.repayment(param);
      this.fullscreenLoading = false;
      console.log("确认还款返回结果", response.data);
      this.$message.success("还款已受理");
	  this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
    send_code: function () {
      this.show = true;
      var _this = this;
      var i = 60;
      var timer = setInterval(function () {
        i--;
        _this.timeStr = i + "秒";
        if (i == 0) {
          clearInterval(timer);
          _this.timeStr = "重新获取";
        }
      }, 1000);
    },
  },
};
</script>
<style scoped>
#repaymentPage .el-cascader {
  width: 100%;
}

#anniu22 {
  text-align: right;
  border-top: 1px solid rgba(228, 228, 228, 1);
  padding-top: 10px;
  padding-right: 30px;
  border-radius: 5px;
}

#repaymentPage .el-select {
  width: 100%;
}

#anniu22 button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}

#repaymentPage .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

#repaymentPage .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

#repaymentPage .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

#repaymentPage .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

#repaymentPage .marginCss {
  margin-left: 30px;
}

#but {
  width: 140px;
}
</style>
