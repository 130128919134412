<template>
  <div id="loanTrackingList">
    <div>
      <template>
        <div class="fixed">
          <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-row :gutter="0">
              <el-col :span="7" align="left">
                <el-form-item label="贷款编号:">
                  <el-input
                    placeholder="请输入查询"
                    v-model="formInline.assets"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10" align="left">
                <el-form-item label="创建时间:">
                  <el-date-picker
                    type="date"
                    placeholder="起"
                    editable
                    pattern="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    v-model="formInline.createTimeStart"
                  ></el-date-picker
                  >-
                  <el-date-picker
                    type="date"
                    placeholder="止"
                    editable
                    pattern="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    v-model="formInline.createTimeEnd"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6" align="left">
                <el-form-item label="资金方:">
                  <el-input
                    placeholder="请输入查询"
                    v-model="formInline.holderName"
                    class="input_width"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <div>
              <el-row>
                <el-col :span="7" align="left">
                  <el-form-item label="贷款企业:">
                    <el-input
                      placeholder="请输入查询"
                      v-model="formInline.applicantName"
                    ></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="10" align="left">
                  <el-form-item label="还款时间:">
                    <el-date-picker
                      type="date"
                      placeholder="起"
                      editable
                      pattern="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      v-model="formInline.timeStart"
                    ></el-date-picker
                    >-
                    <el-date-picker
                      type="date"
                      placeholder="止"
                      editable
                      pattern="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      v-model="formInline.timeEnd"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>

                <el-col :span="6" :pull="0" align="left">
                  <el-form-item id="anniu">
                    <el-button
                      type="primary"
                      icon="el-icon-search"
                      @click="submit"
                      >查询</el-button
                    >
                    <el-button
                      type="primary"
                      @click="reset"
                      icon="el-icon-refresh-right"
                      >重置</el-button
                    >
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </el-form>
        </div>
        <div id="table">
          <el-table
            :data="list"
            :header-cell-style="tableHeader"
            :cell-style="tableCell"
            border
          >
            <el-table-column prop="sss" label="序号" align="center">
              <template slot-scope="scope">
                <span>{{
                  scope.$index + 1 + (page.pageNo - 1) * page.pageSize
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="assets"
              label="贷款编号"
              :show-overflow-tooltip="true"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="applicantName"
              label="贷款企业"
              :show-overflow-tooltip="true"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="holderName"
              label="资金方"
              :show-overflow-tooltip="true"
              align="center"
            />
            <el-table-column
              prop="productName"
              label="贷款产品"
              :show-overflow-tooltip="true"
              align="center"
            >
            </el-table-column>

            <el-table-column
              prop="loanAmount"
              label="贷款金额"
              :show-overflow-tooltip="true"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.loanAmount / 100 }}
              </template>
            </el-table-column>
            <el-table-column
              prop="repaymentInterval"
              label="贷款期限"
              :show-overflow-tooltip="true"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="creditInterestRate"
              label="利率"
              :show-overflow-tooltip="true"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.creditInterestRate / 10000 }}%
              </template>
            </el-table-column>
            <el-table-column
              prop="loanInterest"
              label="贷款利息"
              :show-overflow-tooltip="true"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.loanInterest / 100 }}
              </template>
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="创建时间"
              :show-overflow-tooltip="true"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="lendingTime"
              label="放款时间"
              :show-overflow-tooltip="true"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="time"
              label="还款时间"
              :show-overflow-tooltip="true"
              align="center"
            >
            </el-table-column>

            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  icon="el-icon-fetchByIdSysUserList"
                  @click="details(scope.row)"
                  :underline="false"
                  size="medium"
                  >详情</el-link
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="page_padding_class">
            <el-pagination
              id="page"
              background
              @size-change="handleSizeChange"
              @current-change="currentChange"
              :page-sizes="[10, 15, 20]"
              :page-size="1"
              layout="total, sizes, prev, pager, next, jumper"
              :total="page.total"
              :current-page="page.pageNo"
            >
            </el-pagination>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      formInline: {
        assets: "",
        applicantName: "",
        holderName: "",
        createTimeStart: "",
        createTimeEnd: "",
        timeStart: "",
        timeEnd: "",
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      diction: {
        code: "repaymentState",
      },
      list: [],
    };
  },
  created: function () {
    this.findLoanTrackingList();
  },
  methods: {
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0";
    },
    submit() {
      this.page.pageNo = 1
      this.findLoanTrackingList();
    },
    reset() {
      this.formInline.assets = "";
      this.formInline.applicantName = "";
      this.formInline.holderName = "";
      this.formInline.createTimeStart = "";
      this.formInline.createTimeEnd = "";
      this.formInline.timeStart = "";
      this.formInline.timeEnd = "";
    },

    async findLoanTrackingList() {
      console.log("formInline" + this.formInline);
      var params = {
        assets: this.formInline.assets,
        applicantName: this.formInline.applicantName,
        holderName: this.formInline.holderName,
        createTimeStart: this.formInline.createTimeStart,
        createTimeEnd: this.formInline.createTimeEnd,
        timeStart: this.formInline.timeStart,
        timeEnd: this.formInline.timeEnd,
        pageSize: this.page.pageSize,
        pageNo: this.page.pageNo,
      };
      let response = await this.api.postLoanManagementApi.findLoanTrackingList(
        params
      );
      console.log("==========", JSON.stringify(response.data));
      this.list = response.data.data.records;
      // console.log(JSON.stringify(this.list));
      console.log("total" + response.data.data.total);
      this.page.total = response.data.data.total;
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.findLoanTrackingList();
    },
    currentChange(pageNo) {
      this.page.pageNo = pageNo;
      this.findLoanTrackingList();
    },

    async findDictionariesItemList(param) {
      let response = await this.api.platformApi.findDictionariesItemList(param);
      console.log("数据字典返回结果", response);
      this.dictionList = response.data.data;
      console.log("数据字典集合", this.dictionList);
    },
    async details(row) {
      console.log("=====row====" + JSON.stringify(row));
      var nameParam = {
        name: row.assets,
      };
      let applyInfo = await this.api.platformApi.findNameCorpAndRisk(
        nameParam
      );
		
		console.log("这是applyInfo返回结果", applyInfo.data);
	  var idParam = {
	    id:applyInfo.data.data.risk
	  };
	  let corpResponse = await this.api.platformApi.findCorpAndRisk(
	    idParam
	  );
	  
	  console.log("这是详情公司返回结果", corpResponse.data);
      console.log(
        "==localStorage===",
        localStorage,
        "======corpResponse:",
        corpResponse
      );
      localStorage.setItem(
        "id",
        JSON.stringify({
          id: row.assets,
          corpInfo: corpResponse.data.data,
        })
      );

      console.log("=====id=====", localStorage.getItem("id"));
      this.$router.push({ name: "loanTrackingDetails" });
    },
  },
};
</script>
<style scoped>
#loanTrackingList {
  background-color: white;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 100px;
}
#loanTrackingList .marginCss {
  margin-top: 20px;
}
#loanTrackingList .grid-anniu button {
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
  margin: 10px;
}
#anniu {
  /* padding-left: 50px; */
  border: 0;
}

#anniu button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
#table {
  width: 100%;
  background-color: rgba(29, 144, 255, 0);
}
#loanTrackingList .dialogBox {
  text-align: left;
}
#page {
  float: right;
  padding-top: 15px;
}
.fixed {
  /* border: solid red; */
  width: 1400px;
  height: 100%;
  overflow: auto;
  /* margin-right: 40px; */
}
.bottom_padding_right_class {
  padding-right: 125px;
}
.input_width{
  width: 50%;
}
</style>