export default function(data, fileName, type) {
  const blob = new Blob([data], { type })
  const filename = fileName
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    window.navigator.msSaveBlob(blob, filename)
  } else {
    var blobURL = window.URL.createObjectURL(blob)
    // 创建隐藏<a>标签进行下载
    var tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = blobURL
    tempLink.setAttribute('download', filename)
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank')
    }
    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink) // 移除dom元素
    window.URL.revokeObjectURL(blobURL) // 释放bolb内存
  }
}

