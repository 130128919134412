<template>
  <div class="about" id="quotaReviewList">
    <div style="text-align: left">
      <el-form :inline="true" :model="queryForm" class="demo-form-inline">
        <el-form-item label="公司名称：">
          <el-input v-model="queryForm.name" placeholder="请输入公司名称查询" :clearable="true"></el-input>
        </el-form-item>
        <el-form-item label="子账号：">
          <el-input v-model="queryForm.account" placeholder="请输入子账号查询" :clearable="true"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onQuery" icon="el-icon-search">查询</el-button>
          <el-button type="primary" @click="onReset" icon="el-icon-refresh-right">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div style="text-align: left;margin-bottom: 20px;">
      <el-button type="primary" icon="el-icon-download" @click="exportTable">导出</el-button>
    </div>
    <!-- <div style="display: flex;justify-content: space-between;">
      <span>汇总表编号：<b>{{queryForm.proofId}}</b></span>
      <span>上传时间：<b>{{headData.uploadTime}}</b></span>
      <span>状态：<b>{{(isEntity(summaryStateList) || isEntity(headData) || isEntity(headData.state) || isEntity(summaryStateList[headData.state]) || 
          isEntity(summaryStateList[headData.state].name))?'':summaryStateList[headData.state].name}}</b></span>
      <span>总记录：<b>{{total}}条</b></span>
    </div> -->
    <div>
        <el-table
        :data="tableData"
        :header-cell-style="tableHeader"
        :cell-style="tableCell"
        border
        fit
        style="width: 100%">
        <el-table-column  type="index"  label="序号" />
        <el-table-column :show-overflow-tooltip="true" prop="accountName"  label="公司名称" /> 
        <el-table-column prop="account" label="子账号号码" />
        <el-table-column prop="vatRate" label="增值税率" />
        <el-table-column prop="additionalRate" label="附加税率" />
        <el-table-column prop="serviceRate" label="服务费率" />
        <el-table-column prop="deductibleRate" label="可抵扣费率" />
        <el-table-column prop="personalIncomeRate" label="个税抵扣费率" />
        <el-table-column prop="createTime" label="创建时间" />
        <!-- <el-table-column fixed="right" label="操作" >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="openUploadFrom(scope.row)" style="color: #09BF88">修改</el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <el-pagination
      
      id="page"
        background
        @size-change="sizeChange"
        @current-change="currentChange"
        @prev-click="currentChange"
        @next-click="currentChange"
        :page-sizes="[5, 10, 20]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    
  </div>
</template>

<script>

export default {
    data() {
      return {
        headData: {
          createTime: '',
          groupId: '',
          groupName: '',
          id: '',
          latestApprovalTime: '',
          latestOperator: '',
          productId: '',
          productName: '',
          proofId: '',
          state: undefined,
          submitor: '',
          tenantId: '',
          uploadTime: ''
        },
        summaryStateList: null,
        tableLoading: true,
        loadDate:'',
        tableData: [],
        logTableData: null,
        input: '',
        queryForm: {
          id: '',
          idno: '',
          name: '',
          account:'',
          pageNo: 1,
          pageSize: 10,
          sessionId: this.$getCookie("sessionId"),
        },
        total:0,
        dialogFormVisible: false,
        updateForm: {
          id: '',
          newSalaryAmount: undefined,
          remark: '',
          sessionId: ''
        },
        rules: {
          newSalaryAmount: [
            { required: true, message: '新的金额不能为空！', trigger: 'blur' }
          ]
        }
      }
    },
    async created(){
      // 获取数组字典
     
      await this.loadInfo();
      // this.loadDate = this.$route.params.date;
      await this.loadTable();
    },
    methods: {
      // 加载当前表格头
      async loadInfo(){
        // await this.api.summaryApi.getById({proofId: this.queryForm.proofId}).then(res =>{
        //   res = res.data;
        //   if(res.status = 200){
        //     this.headData = res.data;
        //   }else{
        //     this.$message.error('数据加载失败！');
        //   }
        // })
      },
      // 加载表格
      async loadTable(){
        // .bind(this);
        let flag = this;
        await this.api.accountApi.subAccountList(this.queryForm).then((res) => {
				res = res.data
				console.log("----合同列表-----",res);
				// flag.loading = false;
				if(res.code == 200){
					flag.tableData = res.data.records;
          flag.tableData.forEach(item => {
            item.vatRate = this.accDiv(item.vatRate,10000)+'%';
            item.additionalRate = this.accDiv(item.additionalRate,10000)+'%';
            item.serviceRate = this.accDiv(item.serviceRate,10000)+'%';
            item.deductibleRate = this.accDiv(item.deductibleRate,10000)+'%';
            item.personalIncomeRate = this.accDiv(item.personalIncomeRate,10000)+'%';
          });
					flag.total = res.data.total
				}else{
            this.tableData =  null;
            this.total = 0;
            this.$message.error(res.msg);
          }
			})
    },
      async exportTable(){
        console.log("导出下载");
        await this.api.salaryApi.downloadSubaccountLog().then(res =>{
          console.log("导出下载----",res)
          let fileNameEncode = "子账号数据.xlsx";
          const link = document.createElement("a");
          let blob = new Blob([res.data], { type: "application/vnd.ms-excel"});
          console.log(blob);
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          link.setAttribute("download",  decodeURIComponent(fileNameEncode));
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
      },

      // 条件查询
      onQuery() {
        this.loadTable();
      },
      
      // 重置表单
      resetForm(form){
        this.$refs[form].resetFields();
      },
      // 重置
      onReset(){
        this.queryForm.name='';
        this.queryForm.account='';
      },
      //current-change
      currentChange(page){
        this.queryForm.pageNo = page;
        this.loadTable();
      },

      // size-change
      sizeChange(size){
        this.queryForm.pageSize = size;
        this.loadTable();
      },
      tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
      },
      tableCell(column) {
        return "height:40px;background-color:#fafafa;color:#000000;padding:0";
      },
      isEntity(val){
        if(val == null || val == undefined || val === ''){
            return true;
        }else{
            return false;
        }
      },
    }
}
</script>

<style scoped>



#page {
  float: right;
  margin-top: 15px;
}

#quotaReviewList {
  overflow: hidden;
  background-color: white;
  padding: 15px 20px 100px;
}

>>> .el-dialog__body {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgb(228, 228, 228, 1);
  padding-left: 0;
  padding-right: 0;
}

>>> .el-drawer.rtl {
  overflow: scroll;
}

.wageadjustment{
  margin: 0 50px;
}

</style>

