<template>
  <div class="search">
    <template>
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-row :gutter="24">
          <el-col :span="6" align="left">
            <div class="grid-content bg-purple">
              <el-form-item label="付款方户名:">
                <el-input
                  v-model="formInline.pnm"
                  placeholder="请输入付款方户名查询"
                ></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="14" align="left">
            <div class="grid-content bg-purple">
              <el-form-item label="交易时间段:">
                <el-date-picker
                  v-model="formInline.quyStDt"
                  type="date"
                  placeholder="起"
                  editable
                  pattern="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                ></el-date-picker
                >-
                <el-date-picker
                  v-model="formInline.quyEndDt"
                  type="date"
                  placeholder="止"
                  editable
                  pattern="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="10" align="left">
            <div class="grid-content bg-purple">
              <el-form-item label="收款方户名:">
                <el-input
                  v-model="formInline.rcltNm"
                  placeholder="请输入收款方户名查询"
                ></el-input>
              </el-form-item>
            </div>
          </el-col>
          <div>
            <el-form-item class="buttonbox">
              <el-button
                type="primary"
                @click="transaction"
                icon="el-icon-search"
                >查询</el-button
              >
              <el-button
                type="primary"
                @click="rest"
                icon="el-icon-refresh-right"
                >重置</el-button
              >
              <el-button
                icon="el-icon-upload2"
                type="primary"
                @click="downloadsExcelDown()"
                :loading="flag1"
                >导出</el-button
              >
              <el-button
                icon="el-icon-download"
                type="primary"
                @click="downloads()"
                :loading="flag2"
                >批量下载</el-button
              >
            </el-form-item>
          </div>
        </el-row>
      </el-form>
    </template>

    <el-table
      id="table"
      :data="list"
      :header-cell-style="tableHeader"
      :cell-style="tableCell"
      :default-sort="{ prop: 'timestamp', order: 'descending' }"
      border
      v-loading="loading"
      element-loading-text="数据拼命加载中..."
      style="width: 100%"
      row-key="ptnsrl"
      ref="multipleTable"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="55"
        align="center"
        :reserve-selection="true"
      >
        <!-- :selectable="selectInit" -->
      </el-table-column>
      <el-table-column prop="id" align="center" width="50" label="序号">
        <template slot-scope="scope">
          <span>{{
            scope.$index + 1 + (page.pageNo - 1) * page.pageSize
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="psubNo"
        label="付款方资金账号"
        :show-overflow-tooltip="true"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="pnm"
        label="付款方户名"
        :show-overflow-tooltip="true"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="rsubNo"
        label="收款方资金账号/银行卡号"
        :show-overflow-tooltip="true"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="rcltNm"
        label="收款方户名"
        :show-overflow-tooltip="true"
        align="center"
      />

      <el-table-column
        prop="billNo"
        label="支付单号"
        :show-overflow-tooltip="true"
        align="center"
      >
      </el-table-column>

      <el-table-column
        prop="aclAmt"
        label="支付金额"
        :show-overflow-tooltip="true"
        align="center"
        width="120"
      >
      <template slot-scope="scope">
					{{scope.row.aclAmt/100}}
				</template>
      </el-table-column>
      <el-table-column
        prop="usage"
        label="资金用途"
        :show-overflow-tooltip="true"
        align="center">
        <!--<template slot-scope="scope">
						{{scope.row.usage == 'failed' ? '失败' + (findWithdrawals[scope.$index] == undefined || findWithdrawals[scope.$index] == null ? '' : ':'+findWithdrawals[scope.$index].reason) : 
						scope.row.usage == 'succeeded'? '成功' : 
            scope.row.usage == 'processing'? '处理中' : ''}}
					</template>-->
        </el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        :show-overflow-tooltip="true"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="type"
        label="交易类型"
        :show-overflow-tooltip="true"
        align="center"
        width="100"
      >
      <template slot-scope="scope">
				<div v-for="(item, index) in itemList" v-bind:key="index" >
					<div v-if="item.datavalue == scope.row.type">
						{{  item.name }}
					</div>
				</div>
			</template>
      </el-table-column>

      <el-table-column label="电子回单" width="130" align="center">
        <template slot-scope="scope">
          <el-col :span="20">
            <div class="grid-content bg-purple">
              <el-link
                type="primary"
                icon="el-icon-fetchByIdSysUserList"
                @click="userdetails(scope.row)"
                :underline="false"
                size="medium"
                >下载</el-link>
            </div>
          </el-col>
         
					<el-col :span="20">
            <!-- <div class="grid-content bg-purple">
              <el-link
                type="primary"
                icon="el-icon-fetchByIdSysUserList"
                @click="WithdrawalCreateOrder(scope.$index)"
                :underline="false"
                size="medium"
                v-if="scope.row.type==3"
                >刷新提现状态</el-link>
            </div> -->
          </el-col>
							
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      id="page"
      background
      @size-change="handleSizeChange"
      @current-change="currentChange"
      :page-sizes="[5, 10, 20]"
      :page-size="page.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>
<script>
import { findDictionariesItemList } from "@/service/moudules/platformApi";
export default {
  props: ["ruleForm"],
  data() {
    return {
      flag2: false,
      flag1: false,
      loading: false,
      formInline: {
        pnm: null,
        rcltnm: null,
        quyStDt: null,
        quyEndDt: null,
        billNo: null,

      },
      page: {
        pageNo: 1,
        pageSize: 10,
      },
      diction: {
        code: "type",
      },
      total: 0,
      list: [],
      findWithdrawals: [],
      itemList: [],
      showDatas: [],
      show: false,
      downloadList: [],
    };
  },

  mounted: function () {
    this.transaction();
    let param = JSON.parse(localStorage.getItem("code"));
				this.findDictionariesItemList(this.diction);
  },
  watch:{

  },

  methods: {
    async downloads() {
      this.flag2 = true;
      this.downloadList = [];
      if (this.$refs.multipleTable.selection.length == 0) {
        this.$message({
          message: "请勾选再进行批量下载",
          type: "error",
        });
        this.flag2 = false;
        return;
      }
      
      for (var i = 0; i < this.$refs.multipleTable.selection.length; i++) {
       
        var params = {
          channel: this.$route.params.channel,
          outRequestNo: this.$refs.multipleTable.selection[i].billNo,
          tradeType: this.$refs.multipleTable.selection[i].type,
          tradeId: this.$refs.multipleTable.selection[i].platSrl,
        };
        console.log("================", params);
       let response = await this.api.platformApi.printTransferVoucher(params);
          if(response.data.code != 200 ){
            this.$message({
              message: response.data.msg,
              type: "error",
            });
          }else if(response.data.data.fileUrl == null || response.data.data.fileUrl == undefined && response.data.data.receiptStatus == "processing"){
            this.$message({
              message: "电子回单处理中!",
              type: "warning",
            });
          }
        var download = {
                 fileUrl: response.data.data.fileUrl,
                 tradeId: response.data.data.tradeId,
        }
        this.downloadList.push(download);
        console.log("=============已获取的download:" + download);
      }
      console.log("==================downloadList数组:", this.downloadList);
      var params = {
        urls: this.downloadList,
      };
      let response = this.api.platformApi.batchDownloads(params);
      
      this.flag2 = false;
    },
    //多选框
    handleSelectionChange(val) {
      //这个val是所有已选中的json的数组
      this.multipleSelection = val.ptnsrl;
    },

    
    async downloadsExcelDown() {
      this.flag1 = true;
      let _this = this;

      let param = {
        channel: this.$route.params.channelId,
        name: this.$route.params.oname,
        balanceAcctId: this.$route.params.balanceAcctId,
        pnm: this.formInline.pnm,
        rcltNm: this.formInline.rcltNm,
        quyStDt: this.formInline.quyStDt,
        quyEndDt: this.formInline.quyEndDt,
        pageSize: this.page.pageSize,
        pageNo: this.page.pageNo,
      };

      console.log(param);
      let response =
        await this.api.postLoanManagementApi.downloadsExcelDown(param);
      console.log(response);
      this.flag1 = false;
      // window.location = response;
    },

    //查询明细列表
    async transaction() {
      var params = {
        channel: this.$route.params.channel,
        name: this.$route.params.oname,
        balanceAcctId: this.$route.params.balanceAcctId,
        pnm: this.formInline.pnm,
        rcltNm: this.formInline.rcltNm,
        quyStDt: this.formInline.quyStDt,
        quyEndDt: this.formInline.quyEndDt,
        pageSize: this.page.pageSize,
        pageNo: this.page.pageNo,
      };
      console.log("================", params);
      let response = await this.api.platformApi.transaction(params);
      this.list = response.data.data.records;
      this.total = response.data.data.total;
    },

    //查询提现状态
    async findWithdrawalCreateOrder(index) {
      let currentRowData = this.list[index]
      console.log(currentRowData)

      var params = {
        outRequestNo: currentRowData.billNo,
      };
      console.log("================", params);
      let response = await this.api.platformApi.findWithdrawalCreateOrder(params);
      // this.findWithdrawal = response.data.data;
      this.findWithdrawals[index] = response.data.data;
      this.$set(this.findWithdrawals,index,this.findWithdrawals[index])
      console.log("====提现状态====",this.findWithdrawals[index])
      await this.updateOrderPaymentStatus(currentRowData.id,currentRowData.createTime,this.findWithdrawals[index].status);
    },

    //修改提现状态
    async updateOrderPaymentStatus(id,createTime,status) {
      console.log(id,this.findWithdrawal)
      var params = {
        id: id,
        usage: status,
        createTime: createTime
      };
      console.log("========修改提现状态========", params);
      let response = await this.api.platformApi.updateOrderPaymentStatus(params);
      this.updateWithdrawal = response.data.data;
      await this.transaction();
    },

    //下载电子回单
    async printTransferVoucher(type,billNo,platSrl) {
      // this.loading = true;
      var params = {
        channel: this.$route.params.channel,
        outRequestNo: billNo,
        tradeType: type,
        tradeId: platSrl,
      };
      console.log("================", params);
      let response = await this.api.platformApi.printTransferVoucher(params);
      console.log("=============0000", response);
      
      if(response.data.code != 200 ){
        this.$message({
          message: response.data.msg,
          type: "error",
        });
      }else if(response.data.data.fileUrl == null || response.data.data.fileUrl == undefined && response.data.data.receiptStatus == "processing"){
          this.$message({
            message: "电子回单处理中!",
            type: "warning",
          });
      }else{
        window.open(response.data.data.fileUrl);
      }
    },

    async findDictionariesItemList(param) {
				let response = await this.api.platformApi.findDictionariesItemList(param);
				console.log("数据字典", response);
				this.itemList = response.data.data;
			},

    rest() {
      this.formInline.pnm = "";
      this.formInline.rcltNm = "";
      this.formInline.quyStDt = "";
      this.formInline.quyEndDt = "";
      this.transaction();
    },

    userdetails(row) {
      this.printTransferVoucher(row.type, row.billNo,row.platSrl);
    },
    WithdrawalCreateOrder(index){
      console.log('xxghe======',index)
      this.findWithdrawalCreateOrder(index);
    },

    handleSizeChange(val) {
      this.page.pageSize = val;
      this.transaction();
      this.findWithdrawals =  [];
    },
    currentChange(pageNo) {
      this.page.pageNo = pageNo;
      this.transaction();
      this.findWithdrawals =  [];
    },

    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0";
    },
  },
};
</script>
<style scoped>
a:link {
  text-decoration: none;
}

.tabbox {
  text-align: left;
}

#table {
  background-color: rgba(29, 144, 255, 0);
}

.el-dialog {
  text-align: left;
}

>>> .el-dialog__body {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgba(228, 228, 228, 1);
  padding-left: 0;
  padding-right: 0;
}

.el-dialog button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}

.search {
  background-color: white;
  padding: 15px 20px 100px 20px;
  overflow: hidden;
}

#grid {
  margin-right: 15px;
  padding: 0px;
  border-radius: 5px;
}

#page {
  float: right;
  margin-top: 15px;
}

.sx {
  float: right;
  margin-right: 70px;
}

.buttonbox button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
</style>