<template>
  <div id="loanProcessingList">
    <template>
      <el-table
        :data="list"
        id="table"
        :header-cell-style="tableHeader"
        :cell-style="tableCell"
        border
      >
        <el-table-column
          label="序号"
          type="index"
          align="center"
          width="50"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="assetsId"
          label="贷款订单号"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="notPaidAmount"
          label="占用额度"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.notPaidAmount / 100 }}
          </template>
        </el-table-column>
        <el-table-column
          prop="assetsState"
          label="还款状态"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <div v-for="(item, index) in dictionList" v-bind:key="index">
              <div v-if="item.datavalue == scope.row.assetsState">
                {{ item.name }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="lendingTime"
          label="借款时间"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="paymentDate"
          label="应还时间"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
      </el-table>
    </template>
  </div>
</template>

<script>
import { findCreditProofAssetsUseInfoByProofId } from "@/service/moudules/postLoanManagementApi";
import { findDictionariesItemList } from "@/service/moudules/platformApi";
export default {
  props: ["quo"],
  data() {
    return {
      id: "",
      list: [],
      diction: {
        code: "assetsState",
      },
      dictionList: [],
    };
  },
  mounted: function () {
    this.proofId = this.quo;
    console.log("获取到的proofId：" + this.proofId);
    this.findCreditProofAssetsUseInfoByProofId();
    this.findDictionariesItemList(this.diction);
  },
  methods: {
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0";
    },
    async findDictionariesItemList(param) {
      let response = await this.api.platformApi.findDictionariesItemList(param);
      console.log("数据字典返回结果", response);
      this.dictionList = response.data.data;
      console.log("数据字典集合", this.dictionList);
    },
    async findCreditProofAssetsUseInfoByProofId() {
      console.log("formInline" + this.formInline);
      var params = {
        id: this.proofId,
      };
      let response = await this.api.postLoanManagementApi.findCreditProofAssetsUseInfoByProofId(
        params
      );
      // console.log("==========", JSON.stringify(response.data));
      this.list = response.data.data;
    },
  },
};
</script>

<style scoped>
/* /deep/ .el-input>.el-input__inner{
    height: 32px;
} */

.right {
  float: right;
}
#table {
  width: 100%;
  background-color: rgba(29, 144, 255, 0);
}
#loanProcessingList .marginCss {
  margin-top: 20px;
}
#loanProcessingList .grid-anniu button {
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
  margin: 10px;
}
#anniu {
  border: 0;
}

#anniu button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
#page {
  float: right;
  margin-top: 15px;
}
#loanProcessingList {
  overflow: hidden;
  background-color: white;
  padding: 15px 20px 100px;
}
.input {
  height: 40px;
  width: 210px;
}
.select {
  height: 40px;
  width: 252px;
}
.left {
  text-align: left;
}
.fixed {
  width: 1600px;
  height: 100%;
}
.a_class {
  color: dodgerblue;
}
</style>