import '@/plugins/axios';

/**
 * 查询项目列表
 */
export const listPageItem = params => axios.post("/billing/listPageItem", params).then(data => data)
/**
 * 查询口径列表
 */
export const listPageCaliberList = params => axios.post("/billing/listPageCaliberList", params).then(data => data)
/**
 * 添加项目
 */
export const saveItem = params => axios.post("/billing/saveItem", params).then(data => data)
/**
 * 修改项目
 */
export const updateItem = params => axios.post("/billing/updateItem", params).then(data => data)
/**
 * 添加口径
 */
export const addCaliber = params => axios.post("/billing/addCaliber", params).then(data => data)
/**
 * 修改口径
 */
export const updateCaliber = params => axios.post("/billing/updateCaliber", params).then(data => data)
/**
 * 添加条件
 */
export const addCondition = params => axios.post("/billing/addCondition", params).then(data => data)
/**
 * 查询计费
 */
export const getBilling = params => axios.post("/billing/getBilling", params).then(data => data)
/**
 * 查询计费
 */
export const listRules = params => axios.post("/billing/listRules", params).then(data => data)

















