<template>
  <div id="preliminaryReviewOfLoansList">
    <div class="fixed">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-row>
          <el-col :span="7" align="left">
            <el-form-item label="贷款编号:">
              <el-input
                v-model="formInline.assets"
                placeholder="贷款编号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" align="left">
            <el-form-item label="贷款状态:">
              <el-select
                v-model="formInline.applyState"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in dictionList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.datavalue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="7" align="left">
            <el-form-item label="贷款企业:">
              <el-input
                v-model="formInline.name"
                placeholder="贷款企业"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10" align="left">
            <el-form-item label="申请时间:">
              <el-date-picker
                v-model="formInline.applicationTimeStart"
                type="date"
                placeholder="起"
                editable
                pattern="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
              ></el-date-picker
              >-
              <el-date-picker
                type="date"
                v-model="formInline.applicationTimeEnd"
                placeholder="止"
                editable
                pattern="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item id="anniu">
              <el-button icon="el-icon-search" type="primary" @click="onSubmit"
                >查询</el-button
              >
              <el-button
                icon="el-icon-refresh-right"
                type="primary"
                @click="reset"
                >重置</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <template>
      <el-table
        :data="list"
        id="table"
        :header-cell-style="tableHeader"
        :cell-style="tableCell"
        border
      >
        <el-table-column prop="sss" label="序号" align="center">
          <template slot-scope="scope">
            <span>{{
              scope.$index + 1 + (page.pageNo - 1) * page.pageSize
            }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="id" label="编号" :show-overflow-tooltip="true" align="center"></el-table-column> -->
        <el-table-column
          prop="assets"
          label="贷款编号"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="贷款企业"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="productName"
          label="贷款产品"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="loanAmount"
          label="贷款金额"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.loanAmount / 100 }}
          </template>
        </el-table-column>
        <el-table-column
          prop="repaymentInterval"
          label="贷款期限"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="creditInterestRate"
          label="利率"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.creditInterestRate / 10000 }}%
          </template>
        </el-table-column>

        <el-table-column
          prop="applyState"
          label="审批状态"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <div v-for="(item, index) in dictionList" v-bind:key="index">
              <div v-if="item.datavalue == scope.row.applyState">
                {{ item.name }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="applicationTime"
          label="申请时间"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" width="150" align="center">
          <template slot-scope="scope">
            <el-link
              type="primary"
              icon="el-icon-fetchByIdSysUserList"
              @click="detail(scope.row)"
              :underline="false"
              size="medium"
              v-if="funArr.indexOf('审批') !== -1"
              >审批</el-link
            >
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        id="page"
        background
        @size-change="handleSizeChange"
        @current-change="currentChange"
        :page-sizes="[10, 15, 20]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total"
        :current-page="page.pageNo"
      >
      </el-pagination>
    </template>
  </div>
</template>

<script>
import { findLoanTrackingList } from "@/service/moudules/postLoanManagementApi";
import { findDictionariesItemList } from "@/service/moudules/platformApi";
export default {
  data() {
    return {
      funArr: [],
      formInline: {
        assets: "",
        name: "",
        applyState: "",
        applicationTimeStart: "",
        applicationTimeEnd: "",
      },
      list: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      diction: {
        code: "applyState",
      },
      dictionList: [],
    };
  },
  mounted: function () {
    this.applyList();
    this.findDictionariesItemList(this.diction);
  },
      created:function(){
	
	this.menuPermission()
},
  methods: {

  async menuPermission() {
		let name = "贷款初审";
		let params = {
			id: this.$getCookie("sessionId"),
			menuName: name
		}
		let response = await this.api.platformApi.menuPermission(params);

		console.log(name + "页面功能列表start---")
		console.log(response.data.data)
		console.log(name + "页面功能列表end---")
		this.funArr = response.data.data
		console.log(this.funArr)
	},
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0";
    },
    reset() {
      this.formInline.assets = "";
      this.formInline.name = "";
      this.formInline.applyState = "";
      this.formInline.applicationTimeStart = "";
      this.formInline.applicationTimeEnd = "";
    },
    onSubmit() {
      this.page.pageNo = 1
      this.applyList();
      console.log("submit!");
    },
    async findDictionariesItemList(param) {
      let response = await this.api.platformApi.findDictionariesItemList(param);
      console.log("数据字典返回结果", response);
      this.dictionList = response.data.data;
      console.log("数据字典集合", this.dictionList);
    },
    async applyList() {
      console.log("formInline" + this.formInline);
      var params = {
        assets: this.formInline.assets,
        name: this.formInline.name,
        applyState: this.formInline.applyState,
        applicationTimeStart: this.formInline.applicationTimeStart,
        applicationTimeEnd: this.formInline.applicationTimeEnd,
        applyStateList: [0],
        pageSize: this.page.pageSize,
        pageNo: this.page.pageNo,
      };
      let response = await this.api.postLoanManagementApi.findApplyList(params);
      console.log("==========", JSON.stringify(response.data));
      this.list = response.data.data.records;
      // console.log(JSON.stringify(this.list));
      console.log("total" + response.data.data.total);
      this.page.total = response.data.data.total;
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.applyList();
    },
    currentChange(pageNo) {
      this.page.pageNo = pageNo;
      this.applyList();
    },
    async detail(row) {
      console.log("=====row====" + JSON.stringify(row));
      var param = {
        id: row.risk,
      };
      let corpResponse = await this.api.postLoanManagementApi.findCorpAndRisk(
        param
      );
      console.log(
        "==localStorage===",
        localStorage,
        "======corpResponse:",
        corpResponse
      );
      localStorage.setItem(
        "id",
        JSON.stringify({
          id: row.assets,
          corpInfo: corpResponse.data.data,
          applyId:row.applyId,
        })
      );

      console.log("=====id=====", localStorage.getItem("id"));
      this.$router.push({ name: "preliminaryReviewOfLoansDetails" });
    },
  },
};
</script>

<style scoped>
/* /deep/ .el-input>.el-input__inner{
    height: 32px;
} */

.right {
  float: right;
}
#preliminaryReviewOfLoansList {
  background-color: white;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 100px;
}
#preliminaryReviewOfLoansList .marginCss {
  margin-top: 20px;
}
#preliminaryReviewOfLoansList .grid-anniu button {
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
  margin: 10px;
}
#anniu {
  border: 0;
}

#anniu button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
#table {
  width: 100%;
  background-color: rgba(29, 144, 255, 0);
}
#preliminaryReviewOfLoansList .dialogBox {
  text-align: left;
}
#page {
  float: right;
  margin-top: 15px;
}
.fixed {
  /* width: 1200px; */
  height: 100%;
}
</style>