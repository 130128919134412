
let adminHost = '' //平台host platform

let yzw_subNo = '2113818000522689'
let yzw_cltNm = '广东一站网络科技有限公司'
let channel = 'sn'

let mobile = "15816121608";



if (process.env.NODE_ENV === "development") {
	adminHost = '' //平台host platform
	yzw_subNo = '2113818000522689'
}else {
	adminHost = ''
	yzw_subNo = '1915020000139867'
}

export const host = adminHost //平台host platform
//租户id
export const tenant = '' //


export default{
	host,
	tenant,
	yzw_subNo,
	yzw_cltNm,
	channel,

	mobile
}