<template>
	<div id="back">
		<el-table id="table" :data="menulist" :header-cell-style="tableHeader" :cell-style="tableCell" border>
			<el-table-column type="index" prop="id" label="编号" width="50" :show-overflow-tooltip="true" align="center">
				<template slot-scope="scope">
					<span>{{(scope.$index+1) + ((queryParams.pageNo-1) * queryParams.pageSize)}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="module" label="模块" :show-overflow-tooltip="true" align="center"></el-table-column>
			<el-table-column prop="name" label="菜单名称" :show-overflow-tooltip="true" align="center"></el-table-column>
			<el-table-column prop="path" label="路径" :show-overflow-tooltip="true" align="center"></el-table-column>
			<el-table-column prop="sequence" label="排序" :show-overflow-tooltip="true" align="center" />
			<el-table-column fixed="right" label="操作" width="100" align="center">
				<template slot-scope="scope">
					<el-link type="primary" @click="showLog(scope.row)" :underline="false" size="small"
						style="color:#1D90FF" v-permissions="'排序'">排序</el-link>
				</template>
			</el-table-column>
		</el-table>

		<div>
			<template>
				<el-pagination id="page" background @size-change="handleSizeChange" @current-change="pageChange"
					:page-sizes="[10, 15, 20]" :page-size="queryParams.pageSize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</template>
		</div>
		<el-dialog class="tablebox" :close-on-click-modal="false" title="排序" :show-close="true"
			:visible.sync="dialogVisible">
			<edit-menu :sort="sigle" ref="order" @closeDialog="cancel"></edit-menu>
			<span slot="footer" class="dialog-footer">
				<el-button type="success" @click="cancel()">取消</el-button>
				<el-button type="primary" @click="confirm">确认</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import editMenu from './editMenu'

	export default {
		data() {
			return {
				dialogVisible: false,
				total: 0,

				queryParams: {
					pageNo: 1,
					pageSize: 10,
					accountNo: "",
					role: "",
				},
				list: [],
				sigle: '',
				menulist: [],
				funArr:[],
			};
		},

		mounted: function() {

			this.loadList();
		},
		created:function(){
		
			console.log("菜单管理")
			
			this.menuPermission()
		
		},
		methods: {
			async menuPermission() {
				let name = "菜单管理";
				let params = {
					id: this.$getCookie("sessionId"),
					menuName: name
				}
				let response = await this.api.platformApi.menuPermission(params);
			
				console.log(name + "页面功能列表start---")
				console.log(response.data.data)
				console.log(name + "页面功能列表end---")
				this.funArr = response.data.data
				console.log(this.funArr)
			},
			async loadList() {
				var me = this;
				var params = {
					pageSize: this.queryParams.pageSize,
					pageNo: this.queryParams.pageNo
				}

				await me.api.platformApi
					.MenuList(params)
					.then(function(response) {
						me.menulist = response.data.data.records;
						me.total = response.data.data.total;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function() {});
			},
			tableHeader(column) {
				return 'height:36px;background-color:#fafafa;color:#333333;padding:0';
			},
			tableCell(column) {
				return 'height:40px;background-color:#fafafa;color:#000000;padding:0';
			},
			onSubmit() {
				this.loadList();
			},
			pageChange(val) {
				this.queryParams.pageNo = val;
				this.loadList();
			},
			showLog(row) {
				this.dialogVisible = true;
				this.sigle = {...row}
			},
			handleSizeChange(val) {
				this.queryParams.pageSize = val
				this.queryParams.pageNo = 1
				this.loadList();
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
			},
			cancel() {
				this.loadList()
				this.dialogVisible = false;

			},
			confirm() {
				this.$refs.order.submitForm('ruleForm');
			}
		},
		components: {
			editMenu
		}
	};
</script>
<style scoped>
	#back {
		background-color: white;
		padding: 15px 20px 100px 20px;
	}

	#table {
		background-color: rgba(29, 144, 255, 0);

	}

	#page {
		float: right;
		margin-top: 15px
	}

	>>>.el-dialog__body {
		border-top: 1px solid rgba(228, 228, 228, 1);
		border-bottom: 1px solid rgba(228, 228, 228, 1);
		padding-left: 0;
		padding-right: 0;
	}

	.tablebox {
		text-align: left;
	}

	.el-dialog button {
		margin-right: 15px;
		padding: 0px;
		width: 80px;
		height: 32px;
		border-radius: 5px;
	}
</style>
