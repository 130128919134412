<template>
  <el-dialog
    title="签署截止时间"
    :visible.sync="dialogFormVisible"
    width="500px"
  >
    <el-date-picker
      v-model="form.expiresTime"
      type="date"
      placeholder="选择日期"
      style="width: 100%"
      :picker-options="pickerOptions"
    >
    </el-date-picker>

    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import dayjs from 'dayjs'
export default {
  data() {
    return {
      dialogFormVisible: false,
      form: {
        extensionTime: '',
        id: '',
      },
      formLabelWidth: '120px',
      record: null,
      pickerOptions: {
        disabledDate(time) {
          // 禁用今天的日期之前的所有日期
          return time.getTime() < Date.now() - 3600 * 1000 * 24
        },
      },
    }
  },
  methods: {
    open(row) {
      this.record = row
      this.form.id = row.id
      this.dialogFormVisible = true
    },
    async submit() {
      if (!this.form.expiresTime) {
        return this.$message.error('请选择截止时间')
      }
      const expiresTime = dayjs(this.form.expiresTime).endOf('day').valueOf()
      if (
        this.record.endTime &&
        dayjs(this.record.endTime).isAfter(dayjs(expiresTime))
      ) {
        return this.$message.error('延期时间不能在原有时间之前')
      }

      let extensionTime;
        if (this.record.endTime) {
          extensionTime = expiresTime - dayjs(this.record.endTime).valueOf();
        } else {
          extensionTime = 0; // Or some other default value.
      }
      this.api.contractApi.extension({
        id: this.record.id,
        extensionTime,
      }).then(res=>{
        if(res.data.code===200){
          this.$message.success('延期成功')
          this.dialogFormVisible = false
          this.$emit('success')
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
  },
}
</script>

