/*
 * 接口统一集成模块
 */
import * as platformApi from './moudules/platformApi'

import * as postLoanManagementApi from './moudules/postLoanManagementApi'

import * as preLoanManagementApi from './moudules/preLoanManagementApi'

import * as billingApi from './moudules/billingApi'

import * as salaryApi from './moudules/salaryApi'

import * as invoiceApi from './moudules/invoiceApi'

import * as paidPlanApi from './moudules/paidPlanApi'

import * as payrollSummary from './moudules/payrollSummary'

import * as personalInfoApi from './moudules/personalInfoApi'

import * as accountApi from './moudules/accountApi'

import * as contractApi from './moudules/contractApi'


// 默认全部导出
export default {
	billingApi,
	platformApi,
	postLoanManagementApi,
	preLoanManagementApi,
	salaryApi,
	invoiceApi,
	paidPlanApi,
	payrollSummary,
	personalInfoApi,
	accountApi,
	contractApi
}
