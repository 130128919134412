<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" id="quotaReviewList">
    <el-row :gutter="20">
      <el-col :span="1">
        <el-button class="but" type="primary" icon="el-icon-timer">填写签署资料</el-button>
      </el-col
      >
    </el-row>
    <div id="form">
      <el-form
        :model="addForm.legalInfo"
        :rules="legalInfoRules"
        ref="legalInfo"
        :inline="true">
        <el-row>
          <el-col :span="20">
            <el-descriptions
              class="margin-top"
              :column="2"
              size=""
              border>
              <el-descriptions-item
                ><template slot="label">甲方(劳务派遣单位)：</template>
                <el-form-item prop="name">
                  <el-input
                    placeholder="请输入甲方(劳务派遣单位)"
                    v-model="addForm.legalInfo.name"
                  ></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                ><template slot="label">居民身份证号码：</template>
                <el-form-item prop="idno">
                  <el-input
                    placeholder="请输入居民身份证号码"
                    v-model="addForm.legalInfo.idno"
                  ></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                ><template slot="label">乙方(劳 动 者)：</template>
                <el-form-item prop="signatory">
                  <el-input
                    placeholder="请输入乙方(劳 动 者)"
                    v-model="addForm.legalInfo.signatory"
                  ></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                ><template slot="label">联系电话：</template>
                <el-form-item prop="mobile">
                  <el-input
                    placeholder="请输入联系电话"
                    v-model="addForm.legalInfo.mobile"
                    maxlength="11"
                    show-word-limit
                  ></el-input>
                  </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                ><template slot="label">签订日期：</template>
                <el-form-item prop="signDate">
                  <el-date-picker
                    value-format='yyyy/MM/dd'
                    v-model="addForm.legalInfo.signDate"
                    type="date"
                    placeholder="请选择签订日期">
                  </el-date-picker>
                  </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                ><template slot="label">户籍地址：</template>
                <el-form-item prop="address">
                  <el-input
                    placeholder="请输入户籍地址"
                    v-model="addForm.legalInfo.address"
                    show-word-limit
                  ></el-input>
                  </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                ><template slot="label">固定期限合同：</template>
                <el-form-item prop="mobile">
                  <el-date-picker
                    v-model="value1"
                    value-format='yyyy/MM/dd'
                    @change="contractTime"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                  </el-date-picker>
                  </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                ><template slot="label">试用期从用工之日起至：</template>
                <el-form-item prop="probationPeriodEndDate" >
                  <el-date-picker
                    value-format='yyyy/MM/dd'
                    v-model="addForm.legalInfo.probationPeriodEndDate"
                    type="date"
                    placeholder="选择日期">
                  </el-date-picker>
                  </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                ><template slot="label">派遣期限为：</template>
                <el-form-item prop="durationAssignment">
                  <el-input
                    placeholder="请输入派遣期限"
                    v-model="addForm.legalInfo.durationAssignment"
                    show-word-limit
                  ></el-input>
                  </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                ><template slot="label">从：</template>
                <el-form-item prop="mobile">
                  <el-date-picker
                    v-model="value2"
                    @change="pqTime"
                    value-format='yyyy/MM/dd'
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                  </el-date-picker>
                  </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                ><template slot="label">月工资：</template>
                <el-form-item prop="monthlySalary">
                  <el-input
                    placeholder="请输入月工资"
                    v-model="addForm.legalInfo.monthlySalary"
                  ></el-input>元
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                ><template slot="label">计件工资。计件单价为：</template>
                <el-form-item prop="pieceRate">
                  <el-input
                    placeholder="请输入计件单价"
                    v-model="addForm.legalInfo.pieceRate"
                  ></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                ><template slot="label">乙方月基本工资：</template>
                <el-form-item prop="basePay">
                  <el-input
                    placeholder="请输入乙方月基本工资"
                    v-model="addForm.legalInfo.basePay"
                  ></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                ><template slot="label">绩效工资计发办法为：</template>
                <el-form-item prop="meritPay">
                  <el-input
                    placeholder="请输入绩效工资计发办法"
                    v-model="addForm.legalInfo.meritPay"
                  ></el-input>元
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                ><template slot="label">约定的其他方式：</template>
                <el-form-item prop="agreeOtherWays">
                  <el-input
                    placeholder="请输入约定的其他方式"
                    v-model="addForm.legalInfo.agreeOtherWays"
                  ></el-input>元
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                ><template slot="label">乙方在试用期期间的工资计发标准为：</template>
                <el-form-item prop="probationSalaryStandard1">
                  <el-input
                    placeholder="请输入试用期期间的工资"
                    v-model="addForm.legalInfo.probationSalaryStandard1"
                  ></el-input>元
                </el-form-item>
              </el-descriptions-item>

            </el-descriptions>
          </el-col>
        </el-row>
      </el-form>
      
    </div>
    <div style="height: 30px;"></div>
    <el-row :gutter="20">
        <el-col :span="6" :offset="3"><div><el-button @click="$router.push(`/templateList`)">取消</el-button></div></el-col>
        <el-col :span="6"><div><el-button type="primary" @click="signContract">确认</el-button></div></el-col>
    </el-row>
  </div>
</template>
<script>

import validateUtils from "@/util/validateUtils";

export default {
  data() {
    return {
      value1: '',
      value2: '',
      fullscreenLoading: false,
      
      
      // 法人信息验证
      legalInfoRules: {
        // name: [{ required: true, message: "请输入法人姓名", trigger: "blur" }],
        // idno: [
        //   { required: true, message: "请输入法人身份证号码", trigger: "blur" },
        //   { validator: this.isIdno, trigger: "blur" },
        // ],
        // bankcard: [
        //   { required: true, message: "请输入法人银行卡号", trigger: "blur" },
        // ],
        // bankName: [
        //   { required: true, message: "请选择开户行", trigger: "change" },
        // ],
        // mobile: [
        //   { required: true, message: "请输入手机号", trigger: "blur" },
        //   { validator: this.isMobile, trigger: "blur" },
        // ],
      },
     
      addForm: {
        // 合同信息
        legalInfo: {
          signatory: "",
          idno: "",
          mobile: "",
          address: "",
          startDate: "",
          endDate: "",
          signDate:"",
          probationPeriodEndDate: "",
          durationAssignment: "",
          durationAssignmentStartDate:"",
          monthlySalary:"",
          pieceRate:"",
          basePay:"",
          meritPay:"",
          agreeOtherWays:"",
          probationSalaryStandard1:"",
          probationSalaryStandard2:"",
        },
        templateId: '',
      },
      
    };
  },
  // 实例创建完成后调用
  async created() {
      console.log("-----111---",this.$route.query.templateId);
      this.addForm.templateId = this.$route.query.templateId
  },

  
  methods: {
    
    contractTime(value){
      this.addForm.legalInfo.startDate = value[0];
      this.addForm.legalInfo.endDate = value[1];
    },

    pqTime(value){
      this.addForm.legalInfo.durationAssignmentStartDate = value[0];
      this.addForm.legalInfo.durationAssignmentEndDate = value[1];
      console.log(value)
    },
    // 提交表单
    async signContract() {
      // 法人验证
      this.$refs["legalInfo"].validate((valid) => {
        if (valid) {
          // 企业验证
          console.log("-----",this.addForm.legalInfo)
          this.addForm.simpleFormFieldsReq = this.addForm.legalInfo;
          this.api.contranctApi.sign(this.addForm).then((res) => {
              res = res.data;
              if (res.code == 200) {
                // 添加成功！
                this.$router.push(`/templateList`);
              } else {
                // 添加失败！
                this.$message.error(res.msg);
              }
              this.fullscreenLoading = false;
            });
        } 
      });
    },
    // 重置form 表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    isIdno(rule, value, callback) {
      if (validateUtils.isIdno(value)) {
        callback();
      } else {
        callback(new Error("身份证不合法！"));
      }
    },
    isEmail(rule, value, callback) {
      if (validateUtils.isEmail(value)) {
        callback();
      } else {
        callback(new Error("邮箱不合法！"));
      }
    },
    isMobile(rule, value, callback) {
      if (validateUtils.isMobile(value)) {
        callback();
      } else {
        callback(new Error("手机号不合法！"));
      }
    },
  },
  components: {},
};
</script>

<style scoped>
>>> .el-descriptions-item__cell {
  padding: 2px 5px !important;
}
>>> .el-descriptions-item__label {
  width: 250px;
  text-align: end !important;
}

/*  */
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
>>> .avatar-uploader {
  display: inline-block;
  margin-left: 10px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 18px;
  color: #8c939d;
  width: 130px;
  height: 30px;
  line-height: 30px;
  text-align: center;
}
.avatar {
  width: 130px;
  height: 30px;
  display: block;
}

#quotaReviewList {
  overflow: hidden;
  background-color: white;
  padding: 15px 20px 100px;
}

>>> .el-input-number {
  padding: 0px;
  /* text-align: end !important; */
}
>>> .el-input__inner {
  text-align: initial !important;
}
/* ------------------------------- */
>>> .el-descriptions__header {
  margin: 20px 0px 0px 0px;
  color: #1d90ff;
}

>>> #form .el-form-item {
  margin-bottom: 0px;
  display: inline !important;
  margin-right: 0px !important;
  vertical-align: top !important;
}
>>> #form .el-form-item__content {
  /* display: inline !important; */
  display: block;
}
>>> #form .el-input {
  width: 50%;
}
>>> #form .el-form-item__error {
  top: 0%;
  /* left: 100%;
    width: 100%; */
  left: 51%;
  width: 50%;
  line-height: inherit;
  padding-top: initial;
}
>>> #form .el-input--suffix {
  width: 100%;
}
>>> #password .el-input--suffix {
  width: 50%;
}
>>> #form .el-select {
  /* display: initial; */
  width: 50%;
}
.upload-demo {
  width: 50%;
}
>>> #form .el-upload-list {
  float: right;
  width: calc(100% - 85px);
}
>>> #form .el-descriptions-item__content {
  width: 35%;
}

>>> #form .el-cascader {
  width: 50%;
}
.but{
  margin-bottom: 15px;
}

>>>.el-input--suffix {
  width: 50% !important;
  
}
</style>


